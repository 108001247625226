import {
  ADD_VENDORS_RFQ_SUCCESS,
  CHANGE_STATUS,
  CHANGE_STATUS_FAILURE,
  CHANGE_STATUS_SUCCESS,
  CLOSE_RFQ,
  CLOSE_RFQ_FAILURE,
  CLOSE_RFQ_SUCCESS,
  EDIT_RFQ,
  EDIT_RFQ_FAILURE,
  EDIT_RFQ_SUCCESS,
  FETCH_RFQ,
  FETCH_RFQ_FAILURE,
  FETCH_RFQ_SUCCESS,
  INVITE_VENDORS_RFQ,
  INVITE_VENDORS_RFQ_FAILURE,
  INVITE_VENDORS_RFQ_SUCCESS,
  QUOTATION_RECEIVED_DETAILS,
  QUOTATION_RECEIVED_DETAILS_FAILURE,
  QUOTATION_RECEIVED_DETAILS_SUCCESS,
  REMOVE_VENDORS_FROM_RFQ,
  REMOVE_VENDORS_FROM_RFQ_FAILURE,
  REMOVE_VENDORS_FROM_RFQ_SUCCESS,
  RESEND_BOUNCE_EMAIL,
  RESEND_BOUNCE_EMAIL_FAILURE,
  RESEND_BOUNCE_EMAIL_SUCCESS,
  RESEND_RFQ,
  RESEND_RFQ_FAILURE,
  RESEND_RFQ_SUCCESS,
  SAVE_RFQ,
  SAVE_RFQ_FAILURE,
  SAVE_RFQ_SUCCESS,
  SINGLE_RQ_DELETE,
  SINGLE_RQ_DELETE_FAILURE,
  SINGLE_RQ_DELETE_SUCCESS,
} from "actions/rfqActions";
import produce from "immer";
const initialState = {
  loading: false,
  error: false,
  errorMassage: "",
  rfq: {},
};

const rfq = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_RFQ:
    case FETCH_RFQ:
    case SINGLE_RQ_DELETE:
    case CLOSE_RFQ:
    case INVITE_VENDORS_RFQ:
    case REMOVE_VENDORS_FROM_RFQ:
    case RESEND_RFQ:
    case RESEND_BOUNCE_EMAIL:
    case CHANGE_STATUS:
    case EDIT_RFQ: {
      return produce(state, (draft) => {
        draft.loading = true;
        draft.error = false;
        draft.errorMassage = "";
      });
    }
    case SAVE_RFQ_SUCCESS:
    case FETCH_RFQ_SUCCESS:
    case SINGLE_RQ_DELETE_SUCCESS:
    case CLOSE_RFQ_SUCCESS:
    case CHANGE_STATUS_SUCCESS:
    case ADD_VENDORS_RFQ_SUCCESS:
    case INVITE_VENDORS_RFQ_SUCCESS:
    case REMOVE_VENDORS_FROM_RFQ_SUCCESS:
    case RESEND_BOUNCE_EMAIL_SUCCESS:
    case RESEND_RFQ_SUCCESS:
    case QUOTATION_RECEIVED_DETAILS_SUCCESS:
    case EDIT_RFQ_SUCCESS: {
      const payload = action?.payload;
      // console.log("this 87777788887777", payload?.rfq);/
      return produce(state, (draft) => {
        draft.loading = false;
        draft.rfq = payload?.rfq;
      });
    }
    case SAVE_RFQ_FAILURE:
    case FETCH_RFQ_FAILURE: {
      return produce(state, (draft) => {
        draft.loading = false;
        draft.rfq = {};
      });
    }

    case CLOSE_RFQ_FAILURE:
    case SINGLE_RQ_DELETE_FAILURE:
    case INVITE_VENDORS_RFQ_FAILURE:
    case REMOVE_VENDORS_FROM_RFQ_FAILURE:
    case RESEND_RFQ_FAILURE:
    case RESEND_BOUNCE_EMAIL_FAILURE:
    case CHANGE_STATUS_FAILURE:
    case QUOTATION_RECEIVED_DETAILS_FAILURE:
    case EDIT_RFQ_FAILURE: {
      console.log("error", action);
      return produce(state, (draft) => {
        draft.loading = false;
        draft.error = true;
        draft.errorMassage =
          action?.message ?? "Something went wrong please try again";
      });
    }
    default: {
      return state;
    }
  }
};
export default rfq;
