import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import {
    Grid,
    AppBar,
    CircularProgress, // Import CircularProgress for the loader
    Typography, // Import Typography for the message
} from "@mui/material";
import { Helmet } from 'react-helmet';
import LeadsDataView from './LeadsDataView';
import LeadNotFound from './LeadNotFound';
import LogInOtpVerifyPopUp from 'views/Auth/SignInOTPVerify/LogInOtpVerifyPopUp';
import { getUrl, sendwhatsAppOTP } from 'actions/businessActions';
import FooterNew from 'views/Home/components/FooterNew';
import { FloatingButton, GlobalNavbar } from 'views/Home 3.0/components';
import { registerUserWithMobile } from 'actions/authActions';
import LeadsMobileVerification from 'views/Auth/SignInOTPVerify/LeadsMobileVerification';
import Footer from 'views/NewHomePage/BuyerComponents/Footer';

const LeadsListView = () => {
    const [openOtpVerifyModal, setOpenOtpVerifyModal] = useState(false);
    const [loading, setLoading] = useState(false); // Add loading state
    const auth = useSelector((state) => state.auth);
    const userProfile = auth.user;
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { requirmentsId } = useParams();
    const searchParams = new URLSearchParams(location.search);
    const mobileNumber = searchParams.get('getotp');

    useEffect(() => {
        if (!userProfile && mobileNumber) {
            dispatch(getUrl(location.pathname));
            setLoading(true); // Set loading to true when starting to send OTP
            dispatch(registerUserWithMobile("", mobileNumber, () => {
                setLoading(false); // Set loading to false after OTP is sent
                setOpenOtpVerifyModal(true);
            }));
        }

        window.scrollTo(0, 0);
    }, [userProfile, dispatch, location.pathname, mobileNumber]);

    const handleCloseOtpVerifyModal = () => {
        window.location.reload();
        setOpenOtpVerifyModal(false);
    };

    const lead = useSelector((state) => state.lead);
    const data = lead.leads;
    const rfq = data.find(obj => obj.rfqId == requirmentsId);

    useEffect(() => {
        if (userProfile && mobileNumber) {
            const newUrl = location.pathname;
            navigate(newUrl, { replace: true });
        }
    }, [userProfile, mobileNumber, navigate, location.pathname]);

    const pages = {
        landings: [
            {
                title: "Sign Up",
                href: "/sign-up",
            },
            {
                title: "Sign In",
                href: "/sign-in",
            },
        ],

        profile: [
            {
                title: "My Profile",
                href: "/my-profile",
            },
        ],
        jobs: [
            {
                title: "Full Time",
                href: "/jobs-fulltime",
            },
            {
                title: "Part Time",
                href: "/jobs-partime",
            },
        ],
        account: [
            {
                title: "General Settings",
                href: "/account-general",
            },
            {
                title: "Security Settings",
                href: "/account-security",
            },
        ],
        blog: [
            {
                title: "Blog 1",
                href: "/blog-1",
            },
            {
                title: "Blog 2",
                href: "/blog-2",
            },
            {
                title: "Newsroom",
                href: "/blog-newsroom",
            },
            {
                title: "Article",
                href: "/blog-article",
            },
        ],
    };

    return (
        <>
            <GlobalNavbar/>
            <Helmet>
                <title>Lead ID</title>
            </Helmet>

            {loading ? ( 
                <Grid 
                    container 
                    alignItems="center" 
                    justifyContent="center" 
                    minHeight="calc(100vh - 66px)"
                    direction="column"
                    sx={{
                        textAlign: 'center',
                        '@media (max-width: 600px)': {
                            // margin: '0px 20px',
                            padding: '10px'
                        },
                    }}
                >
                    <CircularProgress style={{ marginBottom: '10px' }} />
                    <Typography variant="h6">
                        We are sending OTP to verify your mobile number. Please wait...
                    </Typography>
                </Grid>
            ) : (
                userProfile && <Grid container alignItems={"center"} justifyContent={"center"} minHeight={"calc(100vh - 66px)"}>
                    {data.length !== 0 ? rfq ? <LeadsDataView rfq={rfq} userProfile={userProfile} /> : <LeadNotFound data={0} /> : <LeadNotFound data={1} />}
                </Grid>
            )}

            {openOtpVerifyModal && (
                <LeadsMobileVerification
                    isWhatsappNo={false}
                    setOtpPopUp={setOpenOtpVerifyModal}
                    setOpenLogInModal={setOpenOtpVerifyModal}
                    mobile={mobileNumber}
                />
            )}
            {/* <FooterNew /> */}
            <Footer/>
            <FloatingButton/>
        </>
    );
}

export default LeadsListView;
