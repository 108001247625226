import React, { useEffect, useState } from "react";
import FilterSection from "./components/FilterPanel";
import Table from "./components/Table";
import { useDispatch, useSelector } from "react-redux";
import { paginatedSearch } from "actions/searchActions";
import SidePanel from "layouts/SidePanel";
import NeevayNetworkHeader from "./components/NeevayNetworkHeader";

const NeevayNetwork = () => {
  return (
    <SidePanel>
      <div className="w-full min-h-svh bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF]">
        <NeevayNetworkHeader />
        <div className="flex py-3 mt-2 pl-5 gap-x-5">
          <div className="w-[260px]">
            <FilterSection />
          </div>

          <div
            // className="flex flex-col gap-y-4"
            style={{ height: "calc(100vh - 120px)" }}
            className="flex-grow overflow-x-auto pr-2"
          >
            {/* <h3>{totalItems} Vendors Found</h3> */}
            <Table />
          </div>
        </div>
      </div>
    </SidePanel>
  );
};

export default NeevayNetwork;
