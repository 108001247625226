import React, { useEffect, useState } from "react";
import tableData from "../DemoTableData";
import { useDispatch, useSelector } from "react-redux";
import {
  filterCompanyDirectory,
  removeCompanyTags,
  searchCompanyDirectoryVendor,
} from "actions/companyDirectoryActions";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import PaginationSection from "../PaginationSection";
import { ContactsPopUp, TagsPopUp } from "./components";
import { Tooltip } from "@mui/material";
import ServiceOffered from "views/NeevayNetwork/components/tableComponenets/ServiceOffered";
import StaticPopup from "components/StaticPopup";
import BusinessInfoDrawer from "views/NeevayNetwork/components/tableComponenets/BusinessInfoDrawer";
import businessService from "services/businessService";
import { hideContactDetails } from "actions/businessActions";
import tagsService from "services/tagsService";
import Loading from "components/Loading";
import AddVendorForm from "../AddVendorForm";

const TableComponent = ({ selectedRows, setSelectedRows, searchValue }) => {
  const dispatch = useDispatch();
  const vendorsPayload = useSelector((state) => state.companyDirectory);
  const companyDirectory = useSelector((state) => state.companyDirectory);
  const auth = useSelector((state) => state.auth);
  const data = tableData;
  const row = data.row;
  const [column, setColumn] = useState([]);
  const [openAddVendorPopup, setOpenAddVendorPopup] = useState(false);
  // const column = data.column;
  const [currentPage, setCurrentPage] = useState(1);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [tagsPopUp, setTagsPopUp] = useState(false);
  const [tagsData, setTagsData] = useState([]);
  const [myTagsData, setMyTagsData] = useState([]);
  const [viewMoreHeader, setViewMoreHeader] = useState("");
  const [businessInfo, setBusinessInfo] = useState({});
  const [openBusinessInfoDrawer, setOpenBusinessInfoDrawer] = useState(false);
  const [showMyTagsLoading, setShowMyTagsLoading] = useState(false);
  const [vendorContactDetails, setVendorContactDetails] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagSelectedId, setTagsSelectedId] = useState("");
  const [editVendorDetails, setEditVendorDetails] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const userProfile = auth.user;
  const userId = userProfile?.userId;
  const entityId = userProfile?.userEntity?.entityId;
  const vendors = vendorsPayload?.vendors;
  const loading = vendorsPayload?.loading;
  const appliedFilters = companyDirectory?.appliedFilters;
  const isAllSelected = selectedRows?.length === vendors?.length;
  // const vendors = column;
  const totalPages = vendorsPayload?.totalPages;

  const isIntegerHeader = (header) => {
    return (
      header.toUpperCase() === "CONTRACTS AWARDED" ||
      header.toUpperCase() === "ANNUAL TURNOVER" ||
      header.toUpperCase() === "GST NO."
    );
  };

  const handleRemoveTag = async (tag) => {
    const payload = {
      entityId: entityId,
      vendorIds: [tagSelectedId],
      userId: userId,
      tagsToRemove: tag,
    };
    const result = await dispatch(removeCompanyTags(payload));
  };

  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      const allIds = column;
      setSelectedRows(allIds);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSelectRow = (e, rowData) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, rowData]);
    } else {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow.id !== rowData.id)
      );
    }
  };

  const handlePageChange = (pageNum) => {
    setCurrentPage(pageNum);
    dispatch(
      filterCompanyDirectory({
        filterData: appliedFilters,
        searchKey: searchValue,
        pageNum,
        userId,
        entityId,
      })
    );
    // dispatch(searchCompanyDirectoryVendor({ searchKey: searchValue, pageNum }));
  };
  const handleOpenDropDown = (data) => {
    setVendorContactDetails(data);
    setMenuOpen(true);
  };

  const handleClosedTagsPopup = () => {
    setTagsPopUp(false);
    setMyTagsData([]);
    setShowMyTagsLoading(false);
  };

  const handleTagsPopUp = async (values, header, id) => {
    setTagsData(values);
    setTagsPopUp(true);
    setTagsSelectedId(id);
    if (header === "Tags") {
      const payload = await businessService?.fetchMyTagsByVendor({
        userId,
        entityId,
        vendorId: id,
      });
      // const filterTags = values?.filter((item) => tags?.includes(item));
      setMyTagsData(payload?.tags);
      setShowMyTagsLoading(false);
    }
    setViewMoreHeader(header);
  };

  const handleClickOpenBusinessView = async (value) => {
    // console.log("this is a business view", value);
    const payload = await businessService.fetchBusinessById(value?.supplierId);
    // console.log("this is a payload )))", payload);
    const profile = payload?.profile;
    setBusinessInfo({
      regions: profile.regions,
      selectedcities: profile?.regions?.Selectedcities ?? [],
      country: profile?.regions?.country ?? [],
      state: profile?.regions?.state ?? [],
      businessName: profile?.businessName,
      hightlights: profile?.businessProfile?.businessHeadline,
      description: profile?.businessProfile?.description,
      url: profile?.url,
      establishYear: profile?.businessProfile?.establishYear,
      annualTurnOver: profile?.annualTurnOver,
      serviceOffered: profile.services?.map((service) => service?.serviceName),
      isoCertificates: profile?.businessProfile?.certificates,
      projectNames: payload?.projects?.projects?.map(
        (project) => project?.projectName
      ),
      owners: profile.owners,
      contact:
        profile?.businessProfile?.contact ??
        profile?.businessProfile?.businessMobile,
      supplierId: profile?.supplierId,
      businessMobileNumber: profile?.businessProfile?.businessMobile,
      businessEmail: profile?.businessProfile?.businessEmail,
    });
    setOpenBusinessInfoDrawer(true);
  };

  const handleOpenBusinessInfoDrawerClose = () => {
    setOpenBusinessInfoDrawer(false);
    setBusinessInfo({});
  };

  const handleOpenVendorEditForm = (rowData) => {
    setOpenAddVendorPopup(true);
    setEditVendorDetails(rowData?.vendorData);
  };

  const getTagsByUserId = async () => {
    const tagsData = await tagsService.fetchTagByUserId({ userId, entityId });
    const tags = tagsData.tags?.filter((tag) => tag !== "");
    setTags(tags);
  };
  useEffect(() => {
    getTagsByUserId();
    setCurrentPage(vendorsPayload?.currentPage);
    
    if (vendors?.length >= 1) {
      const columnData = vendors.map((vendor) => {
        // Function to format date
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        };
  
        return {
          id: vendor?.vendorId,
          supplierId: vendor?.neevaySupplierId || null,
          Company: vendor?.vendorName || "",
          contactDetails: vendor?.contactDetails,
          "Default Contact": vendor?.contactDetails[0]?.name || "User",
          Tags:
            vendor?.tags?.length >= 1
              ? [...new Set(vendor?.tags?.flatMap((item) => item.tags))]
              : ["--"],
          Location:
            vendor?.location?.length >= 1
              ? vendor?.location[0]?.state
                ? `${vendor?.location[0]?.city},${vendor?.location[0]?.state}`
                : `${vendor?.location[0]?.city}`
              : "--",
          "Service Offered":
            vendor?.servicesOffering?.length >= 1
              ? vendor?.servicesOffering?.map((entry) => entry?.serviceName)
              : ["--"],
          Sector: vendor?.sectors?.length >= 1 ? vendor?.sectors : ["--"],
          "Contracts Awarded": vendor?.contractsAwarded || "--",
          "Internal Rating":
            vendor?.ratings?.length >= 1
              ? vendor?.ratings[vendor?.ratings?.length - 1]?.rating
              : "--",
          "Internal Notes":
            vendor?.notes?.length >= 1
              ? vendor?.notes[vendor?.notes?.length - 1]?.note
              : "--",
          "Annual Turnover": vendor?.gstTurnOverRange || "--",
          "GST Status": vendor?.gstStatus || "--",
          "GST No.": vendor?.gstNumber || "--",
          "Created Date": vendor?.createdAt ? formatDate(vendor?.createdAt) : "--",
          "Added By": vendor?.addedBy || "--",
          onNeevay: vendor?.isNeevayVendor || false,
          isPremium: vendor?.isPremium,
          vendorData: vendor,
        };
      });
      setColumn(columnData);
    } else {
      setColumn([]);
    }
  }, [vendorsPayload]);

  useEffect(() => {
    getTagsByUserId();
  }, []);
  return (
    <section>
      <div className="max-w-full relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
        {loading && (
          <div className="flex justify-center items-center h-[50vh]">
            <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10"></div>
          </div>
        )}
        {!loading && !vendors?.length >= 1 && (
          <div className="w-full h-[50vh] flex justify-center items-center">
            <h6>No result found</h6>
          </div>
        )}
        {vendors?.length >= 1 && (
          <>
            {" "}
            <div id="scrollableDiv" className="overflow-x-auto rounded-lg">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                <thead className="rounded-lg sticky top-0 text-xs max-w-full text-gray-700  bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="sticky left-0 top-0 z-10">
                      {/* <div className="w-4"></div> */}
                      <div
                        className="flex items-center px-4 py-3 gap-4 bg-gray-50 text-gray-700 font-medium"
                        style={{
                          boxShadow: "5px -2px 13.1px 0px rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        <div>
                          <input
                            id="checkbox-all"
                            type="checkbox"
                            checked={isAllSelected}
                            onChange={handleSelectAllChange}
                            className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label htmlFor="checkbox-all" className="sr-only">
                            Checkbox for all
                          </label>
                        </div>

                        <p>COMPANY</p>
                      </div>
                      {/* <span>
                        {isAllSelected ? (
                          // Render a tick mark if all checkboxes are selected
                          <span>&#10003;</span>
                        ) : (
                          // Render a minus sign if one or more checkboxes are selected
                          <span>&#8722;</span>
                        )}
                      </span> */}
                    </th>
                    {/* <th
                      scope="col"
                      className="font-medium sticky left-10 top-0 z-10 bg-gray-50 text-gray-700"
                    >
                      <div
                        style={{
                          boxShadow: "5px -2px 13.1px 0px rgba(0, 0, 0, 0.05)",
                        }}
                      >
                        <p className="px-4 py-3">COMPANY</p>
                      </div>
                    </th> */}

                    {row.slice(1).map((header, index) => (
                      <th
                        key={index}
                        scope="col"
                        className={`px-4 py-3 text-gray-700 font-medium w-[100px] ${isIntegerHeader(header) ? "text-right" : "text-left"
                          }`}
                      >
                        <p
                          className={`truncate ${isIntegerHeader(header) ? "text-right" : "text-left"
                            }`}
                        >
                          {header.toUpperCase()}
                        </p>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {column?.map((rowData, rowIndex) => (
                    <tr
                      key={rowIndex}
                      className="border-b group transition  dark:border-gray-600 hover:bg-blue-50 dark:hover:bg-gray-700"
                    >
                      <td className="sticky left-0 w-4 bg-white group-hover:bg-blue-50 transition ">
                        <div
                          className="flex items-center  px-4 py-3 gap-4"
                          style={{
                            boxShadow:
                              "5px 10px 13.1px 0px rgba(0, 0, 0, 0.05)",
                          }}
                        >
                          <div className="flex items-center">
                            <input
                              id={`checkbox-table-search-${rowIndex}`}
                              type="checkbox"
                              onChange={(e) => handleSelectRow(e, rowData)}
                              checked={selectedRows.some(
                                (selectedRow) => selectedRow.id === rowData.id
                              )}
                              onClick={(e) => e.stopPropagation()}
                              className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              htmlFor={`checkbox-table-search-${rowIndex}`}
                              className="sr-only"
                            >
                              checkbox
                            </label>
                          </div>

                          <div className="flex items-center">
                            <div className="w-52 mr-3">
                              {rowData.onNeevay ? (
                                <div className="flex gap-2">
                                  <Tooltip
                                    title="Edit Vendor"
                                    placement="top"
                                    arrow
                                  >
                                    <button
                                      onClick={() => {
                                        handleOpenVendorEditForm(rowData);
                                      }}
                                    >
                                      <svg
                                        className="w-4 h-4 text-blue-400"
                                        focusable="false"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        viewBox="0 0 24 24"
                                        data-testid="ModeEditOutlineIcon"
                                        tabindex="-1"
                                        title="ModeEditOutline"
                                      >
                                        <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 5.63l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41"></path>
                                      </svg>
                                    </button>
                                  </Tooltip>
                                  <button
                                    className="w-52 text-left"
                                    style={{ color: "#1A56DB" }}
                                    onClick={() =>
                                      handleClickOpenBusinessView(rowData)
                                    }
                                  >
                                    <p
                                      className="truncate font-medium "
                                      title={rowData.Company}
                                    >
                                      {rowData.Company}
                                    </p>
                                  </button>
                                </div>
                              ) : (
                                <div className="flex gap-2">
                                  <Tooltip
                                    title="Edit Vendor"
                                    placement="top"
                                    arrow
                                  >
                                    <button
                                      onClick={() => {
                                        handleOpenVendorEditForm(rowData);
                                      }}
                                    >
                                      <svg
                                        className="w-4 h-4 text-blue-400"
                                        focusable="false"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        viewBox="0 0 24 24"
                                        data-testid="ModeEditOutlineIcon"
                                        tabindex="-1"
                                        title="ModeEditOutline"
                                      >
                                        <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 5.63l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41"></path>
                                      </svg>
                                    </button>
                                  </Tooltip>
                                  <p
                                    className="truncate font-medium "
                                    title={rowData.Company}
                                  >
                                    {rowData.Company}
                                  </p>
                                </div>
                              )}
                            </div>
                            {rowData.onNeevay ? (
                              <Tooltip
                                title="Vendor is part of Neevay Marketplace & hence has up to date Information"
                                placement="top"
                                arrow
                              >
                                <div
                                  style={{
                                    borderRadius: "3px",
                                    border: "0.5px solid #039400",
                                    background: "#E1FFDE",
                                    padding: "0 4px", // You can adjust the padding as needed
                                  }}
                                  className="flex items-center mr-1"
                                >
                                  <span className="text-black font-inter font-semibold text-xs ">
                                    N
                                  </span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="5"
                                    height="5"
                                    viewBox="0 0 5 5"
                                    fill="none"
                                  >
                                    <circle
                                      cx="2.5"
                                      cy="2.5"
                                      r="2.5"
                                      fill="#039400"
                                    />
                                  </svg>
                                </div>
                              </Tooltip>
                            ) : (
                              <></>
                            )}
                            {rowData?.isPremium ? (
                              <Tooltip
                                title="Vendor has been Verified for their Services & Contact Details"
                                placement="top"
                                arrow
                              >
                                <img
                                  src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Verified_image_nn.png"
                                  alt="verified"
                                  className={`h-[18px] w-[18px] ml-1 mr-2`}
                                />
                              </Tooltip>
                            ) : (
                              <div className="w-[18px]"></div>
                            )}
                          </div>
                        </div>
                      </td>
                      {row.slice(1).map((header, colIndex) => (
                        <td key={colIndex} className="w-4">
                          {header === "Location" && (
                            <div className="flex items-center max-w-[150px] px-4 py-2">
                              <p
                                className="truncate text-sm text-gray-500 font-medium"
                                title={rowData[header]}
                              >
                                {rowData[header]}
                              </p>
                            </div>
                          )}
                          {header === "Default Contact" && (
                            <div
                              className=" group  flex items-center justify-start gap-2 w-[180px] cursor-pointer hover:bg-blue-200 px-4 py-2"
                              onClick={() =>
                                handleOpenDropDown(rowData["contactDetails"])
                              }
                            >
                              {rowData["contactDetails"]?.length > 1 ? (
                                <svg
                                  aria-hidden="true"
                                  className="hidden w-4 h-4 text-gray-500 dark:text-gray-400 md:block shrink-0"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                                </svg>
                              ) : (
                                <svg
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 448 512"
                                  className="hidden w-3 h-3 text-gray-500 dark:text-gray-400 md:block shrink-0"
                                >
                                  <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                                </svg>
                              )}
                              <p
                                className="truncate text-sm text-gray-500 font-medium "
                                title={rowData[header]}
                              >
                                {rowData[header]}
                              </p>
                              <div className="hidden transition-opacity duration-1000 ease-in-out">
                                {/* <div> */}
                                <div className=" text-gray-400 text-sm px-2  py-1 flex items-center justify-between cursor-pointer">
                                  Vendor details
                                </div>
                              </div>
                            </div>
                          )}

                          {(header === "GST Status" ||
                            header === "Added By") && (
                              <div className="flex items-center max-w-[120px] px-4 py-2">
                                <p
                                  className="truncate text-sm text-gray-500 font-medium"
                                  title={rowData[header]}
                                >
                                  {rowData[header]}
                                </p>
                              </div>
                            )}

                          {header === "Created Date" && (
                            <div className="flex items-center max-w-[150px] px-4 py-2">
                              <p
                                className="truncate text-sm text-gray-500 font-medium"
                                title={rowData[header]}
                              >
                                {rowData[header]}
                              </p>
                            </div>
                          )}
                          {(header === "Annual Turnover" ||
                            header === "GST No." ||
                            header === "Contracts Awarded") && (
                              <div className="flex items-center justify-end ml-auto max-w-[120px] px-4 py-2">
                                <p
                                  className="truncate text-sm text-gray-500 font-medium"
                                  title={rowData[header]}
                                >
                                  {rowData[header]}
                                </p>
                              </div>
                            )}

                          {header === "Internal Rating" && (
                            <div className="flex items-center px-4 py-2">
                              {[1, 2, 3, 4, 5].map((num, index) => (
                                <svg
                                  key={index}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 15 15"
                                  fill={
                                    index < rowData[header]
                                      ? "#FFDD2C"
                                      : "#626D81"
                                  }
                                >
                                  <path d="M7.5 0.421828C7.37955 0.164044 7.12503 0 6.84551 0C6.56599 0 6.31375 0.164044 6.19103 0.421828L4.72983 3.52226L1.46654 4.01908C1.19385 4.06126 0.966602 4.25812 0.882518 4.52762C0.798434 4.79712 0.866625 5.09474 1.06207 5.29394L3.42999 7.71007L2.87096 11.1245C2.8255 11.4058 2.93913 11.6917 3.1641 11.858C3.38908 12.0244 3.68678 12.0455 3.93221 11.9119L7.00064 10.3067L10.0692 11.9119C10.3146 12.0455 10.6123 12.0268 10.8373 11.858C11.0623 11.6893 11.1759 11.4058 11.1305 11.1245L10.5692 7.71007L12.9371 5.29394C13.1425 5.09474 13.213 4.79712 13.1266 4.52762C13.0403 4.25812 12.8153 4.06126 12.5416 4.01908L9.27605 3.52226L7.81484 0.421828Z" />
                                </svg>
                              ))}
                            </div>
                          )}
                          {/* we already create tags show more  pop up so we add service offered and sector   */}
                          {(header === "Tags" || header === "Sector") &&
                            rowData[header]?.length >= 1 && (
                              <p className="truncate  font-medium text-gray-500 text-[12px] px-4 py-2">
                                {header === "Sector" ? (
                                  <span class="text-sm font-medium pr-2.5 py-0.5">
                                    {rowData[header][0] === ""
                                      ? "--"
                                      : rowData[header][0]}
                                  </span>
                                ) : rowData[header][0] !== "--" ? (
                                  <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                                    {rowData[header][0]}
                                  </span>
                                ) : (
                                  <span class="text-sm font-medium pr-2.5 py-0.5">
                                    --
                                  </span>
                                )}
                                {rowData[header]?.length > 1 && (
                                  <span
                                    onClick={() => {
                                      setShowMyTagsLoading(true);
                                      handleTagsPopUp(
                                        rowData[header],
                                        header,
                                        rowData?.id
                                      );
                                    }}
                                    className="underline cursor-pointer  "
                                  >{`+${rowData[header]?.length - 1
                                    } more`}</span>
                                )}
                              </p>
                            )}

                          {header === "Service Offered" && (
                            <p className="truncate  font-medium text-black text-[12px] px-4 py-2">
                              <ServiceOffered values={rowData[header]} />
                            </p>
                          )}

                          {header === "Internal Notes" && (
                            <div className="flex items-center w-[120px] px-4 py-2">
                              <p
                                className="truncate text-gray-500 font-medium text-[12px]"
                                title={rowData[header]}
                              >
                                {rowData[header]}
                              </p>
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <style>
                {`
          .overflow-x-auto::-webkit-scrollbar {
            height: 6px;
          }

          .overflow-x-auto::-webkit-scrollbar-thumb {
            background-color: #949494; 
          }
        `}
              </style>
            </div>
            <PaginationSection
              totalPages={totalPages}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          </>
        )}
      </div>

      {/* this is pop  up code here */}
      {isMenuOpen && (
        <ContactsPopUp
          vendorContactDetails={vendorContactDetails}
          setMenuOpen={setMenuOpen}
        />
      )}
      {/* {tagsPopUp && (
        <TagsPopUp
          header={viewMoreHeader}
          tags={tagsData}
          setTagsPopUp={setTagsPopUp}
        />
      )} */}
      <BusinessInfoDrawer
        handleOpenBusinessInfoDrawerClose={handleOpenBusinessInfoDrawerClose}
        values={businessInfo}
        openBusinessInfoDrawer={openBusinessInfoDrawer}
      />
      <StaticPopup
        openModal={tagsPopUp}
        handleClose={() => handleClosedTagsPopup()}
        header={viewMoreHeader}
      >
        {viewMoreHeader === "Tags" ? (
          <div>
            {showMyTagsLoading ? (
              <div className=" w-full h-10  flex justify-center items-center">
                <svg
                  aria-hidden="true"
                  class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              myTagsData?.length >= 1 && (
                <div className="mb-12">
                  <p className="text-[#353522] font-bold mb-4">My Tags</p>
                  <div className="flex flex-wrap gap-3">
                    {myTagsData?.map((tag, index) => (
                      <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-100">
                        {tag}
                        <button
                          className="ml-1 rounded-[14px] p-1 hover:bg-green-200"
                          onClick={() => {
                            setMyTagsData((preVal) =>
                              preVal.filter((tags) => tags !== tag)
                            );
                            handleRemoveTag(tag);
                          }}
                        >
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_4643_3749)">
                              <path
                                d="M4.72632 3.9999L7.44366 1.28256C7.49269 1.2352 7.5318 1.17855 7.55871 1.11592C7.58562 1.05328 7.59978 0.985915 7.60037 0.917748C7.60096 0.849581 7.58797 0.781979 7.56216 0.718886C7.53635 0.655793 7.49823 0.598472 7.45002 0.550269C7.40182 0.502066 7.3445 0.463946 7.28141 0.438132C7.21831 0.412319 7.15071 0.399329 7.08254 0.399922C7.01438 0.400514 6.94701 0.414676 6.88438 0.441582C6.82174 0.468488 6.76509 0.507599 6.71773 0.556633L4.00039 3.27398L1.28305 0.556633C1.18622 0.463116 1.05654 0.411369 0.921931 0.412539C0.787323 0.413708 0.65856 0.467701 0.563375 0.562886C0.468189 0.658072 0.414197 0.786835 0.413027 0.921443C0.411857 1.05605 0.463604 1.18573 0.557121 1.28256L3.27447 3.9999L0.557121 6.71725C0.508087 6.7646 0.468977 6.82125 0.442071 6.88389C0.415165 6.94652 0.401002 7.01389 0.40041 7.08206C0.399818 7.15022 0.412807 7.21782 0.43862 7.28092C0.464434 7.34401 0.502554 7.40133 0.550757 7.44953C0.59896 7.49774 0.656281 7.53586 0.719374 7.56167C0.782467 7.58749 0.850069 7.60048 0.918236 7.59988C0.986403 7.59929 1.05377 7.58513 1.1164 7.55822C1.17904 7.53132 1.23569 7.49221 1.28305 7.44317L4.00039 4.72583L6.71773 7.44317C6.81456 7.53669 6.94424 7.58844 7.07885 7.58727C7.21346 7.5861 7.34222 7.5321 7.43741 7.43692C7.53259 7.34173 7.58658 7.21297 7.58775 7.07836C7.58892 6.94375 7.53718 6.81407 7.44366 6.71725L4.72632 3.9999Z"
                                fill="#0E9F6E"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4643_3749">
                                <rect width="8" height="8" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>
                      </span>
                    ))}
                  </div>
                </div>
              )
            )}
            <div>
              <p className="text-[#353522] font-bold mb-4">Company Tags</p>
              <div className="flex flex-wrap gap-3">
                {tagsData?.map((tag, index) => (
                  <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-100">
                    {tag}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-wrap gap-3">
            {tagsData?.map((tag, index) => (
              <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-100">
                {tag}
              </span>
            ))}
          </div>
        )}
      </StaticPopup>
      <StaticPopup
        openModal={openAddVendorPopup}
        handleClose={() => setOpenAddVendorPopup(false)}
        header={"Edit Vendor"}
        size={"sm"}
        width={"3xl"}
      >
        <AddVendorForm
          setOpenAddVendorPopup={setOpenAddVendorPopup}
          user={userProfile}
          setSnackbarOpen={setSnackbarOpen}
          editVendorDetails={editVendorDetails}
          isEdit={true}
        />
      </StaticPopup>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="outlined"
          icon={false}
          onClose={() => setSnackbarOpen(false)}
          sx={{ bgcolor: "gray", color: "white" }}
        >
          Vendor edit successfully
        </MuiAlert>
      </Snackbar>
    </section>
  );
};

export default TableComponent;
