import React from "react";

const BreadCrumbs = ({ paths }) => {
  return (
    <div className="flex">
      {paths.map((path, index) => (
        <div key={index} className="flex items-center">
          <button
            className="text-gray-700 font-medium text-sm"
            onClick={path.onClick}
          >
            {path.title}
          </button>
          {index < paths.length - 1 && (
            <svg
              className="rtl:rotate-180 w-3 h-3 text-gray-400 mr-1 ml-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
          )}
        </div>
      ))}
    </div>
  );
};

export default BreadCrumbs;
