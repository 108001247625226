import React, { useEffect, useState } from "react";
import BreadCrumbs from "../BreadCrumbs";
import Header from "../../../../components/Header";
import Chip from "../../../../components/Chip";
import RfqDetailsSection from "../RfqDetailsSection";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "../../../../components/Button";
import Popup from "../../../../components/Popup";
import SidePanel from "layouts/SidePanel";

import { useDispatch, useSelector } from "react-redux";
import { CcMemberList, resendRfq } from "actions/rfqActions";
import Loading from "components/Loading";

const validationSchema = Yup.object({
  mailBody: Yup.string().required("Mail Body is required"),
});

const validateEmail = (data) => {
  const email = data.trim();
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const ResendRfq = ({
  toggleTab,
  vendorName,
  mailTo,
  ccMails,
  conversations,
  selectedSupplier,
  rfqId,
  loading,
}) => {
  const auth = useSelector((state) => state.auth);
  const ccMembers = useSelector((state) => state.ccMemberList);
  const dispatch = useDispatch();

  const [selectedFilesBase, setSelectedFilesBase] = useState([]);
  const [resendFormValues, setResendFormValues] = useState({
    mailBody: "<p>This is a gentle reminder</p>",
    mailTo,
    ccMails,
  });

  const [addToMail, setAddToMail] = useState(false);
  const [addCcMail, setAddCcMail] = useState(false);
  const [newMailTo, setNewMailTo] = useState("");
  const [newCcMail, setNewCcMail] = useState("");
  const [mailToError, setMailToError] = useState("");
  const [ccMailError, setCcMailError] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const ccMemberList = ccMembers?.ccEmails;

  const user = auth?.user;
  const entityId = user?.userEntity?.entityId;

  const handleInputChange = (e) => {
    const value = e.target.value;
    setNewCcMail(value);
    // form.setFieldValue(field.name, value);

    if (value) {
      const matchedSuggestions = ccMemberList.filter((email) =>
        email.includes(value)
      );
      setSuggestions(matchedSuggestions?.slice(0, 5));
      setShowSuggestions(matchedSuggestions.length > 0);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (email) => {
    setNewCcMail(email);
    setShowSuggestions(false);
    // form.setFieldValue(field.name, email);
  };

  const getHighlightedText = (text, highlight) => {
    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === highlight.toLowerCase() ? (
            <span key={index} className="font-bold">
              {part}
            </span>
          ) : (
            part
          )
        )}
      </span>
    );
  };

  const updateResendFormValues = (updatedValues) => {
    setResendFormValues(updatedValues);
  };

  const onCloseTag = (mail, type) => {
    const updatedMail = resendFormValues[type].filter(
      (email) => email !== mail
    );
    setResendFormValues({ ...resendFormValues, [type]: updatedMail });
  };

  const handleAddToMail = () => {
    if (!validateEmail(newMailTo)) {
      setMailToError("Invalid email");
      return;
    }
    if (resendFormValues.mailTo.includes(newMailTo)) {
      setMailToError("Email already exists");
      return;
    }
    setResendFormValues((prevVal) => ({
      ...prevVal,
      mailTo: [...prevVal.mailTo, newMailTo],
    }));
    setNewMailTo("");
    setAddToMail(false);
  };

  const handleAddCcMail = () => {
    if (!validateEmail(newCcMail)) {
      setCcMailError("Invalid email");
      return;
    }
    if (resendFormValues.ccMails.includes(newCcMail.trim())) {
      setCcMailError("Email already exists");
      return;
    }
    setResendFormValues((prevVal) => ({
      ...prevVal,
      ccMails: [...prevVal.ccMails, newCcMail],
    }));
    setNewCcMail("");
    setCcMailError("");
    setAddCcMail(false);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    // handle form submission here
    const payload = {
      rfqId,
      supplierId: selectedSupplier?.supplierId,
      userId: user?.userId,
      additionalNote: {
        ccEmails: values?.ccMails?.length ? values?.ccMails : [],
        emails: values?.mailTo?.length ? values?.mailTo : [],
        createdAt: Date(Date.now()),
        desc: values?.mailBody,
        sentByEmail: user?.email || "",
        senderName: user?.name || "",
        senderUserId: user?.userId,
        file: selectedFilesBase,
      },
    };
    const response = await dispatch(resendRfq({ payload }));
    toggleTab("Vendors");
    setSubmitting(false);
  };
  useEffect(() => {
    dispatch(CcMemberList(entityId));
  }, []);
  return (
    <SidePanel>
      <div className="w-full">
        <Header title="RFQs" />
        <div className="pt-20 p-6 flex flex-col gap-y-6 w-full bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF]  min-h-svh">
          <BreadCrumbs
            paths={[
              { title: "RFQs", onClick: () => toggleTab("Vendors") },
              { title: vendorName, onClick: () => toggleTab("Overview") },
              { title: "Reinvite Enquiry", onClick: () => {} },
            ]}
          />
          <Formik
            enableReinitialize
            initialValues={resendFormValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form className="flex flex-col gap-y-4">
              <div className="rounded-lg bg-white shadow-md p-6 pb-0 mb-20">
                <h3 className="text-gray-900 font-bold text-xl mb-4">
                  Reinvite Enquiry
                </h3>
                <div className="mb-4">
                  <span>To: </span>
                  {resendFormValues.mailTo.map((mail) => (
                    <Chip
                      key={mail}
                      label={mail}
                      onRemove={
                        resendFormValues.mailTo.length > 1
                          ? () => onCloseTag(mail, "mailTo")
                          : null
                      }
                      className="mr-2 mb-2"
                    />
                  ))}
                  <Button
                    type="button"
                    variant="text"
                    onClick={() => setAddToMail(true)}
                  >
                    + Click to add Mail
                  </Button>
                </div>
                <div className="mb-4">
                  <span>Cc: </span>
                  {resendFormValues.ccMails.map((mail) => (
                    <Chip
                      key={mail}
                      label={mail}
                      onRemove={() => onCloseTag(mail, "ccMails")}
                    />
                  ))}
                  <Button
                    type="button"
                    variant="text"
                    onClick={() => setAddCcMail(true)}
                  >
                    + Click to add Mail
                  </Button>
                </div>
                <h3 className="text-gray-900 font-bold text-xl mb-4">
                  Subject - Updated RFQ
                </h3>
                <div className="border-2">
                  <RfqDetailsSection
                    selectedFilesBase={selectedFilesBase}
                    setSelectedFilesBase={setSelectedFilesBase}
                    fieldName="mailBody"
                    updateFormValues={updateResendFormValues}
                  />
                </div>
                <div className="mt-6">
                  {conversations.map((conversation, index) => (
                    <div key={index} className="mb-4">
                      <span>
                        {conversation.createdAt} |{" "}
                        {conversation.senderName +
                          " <" +
                          conversation.sentByEmail +
                          "> "}{" "}
                        wrote:{" "}
                      </span>
                      <div
                        className="p-4 border border-gray-300 rounded-md"
                        dangerouslySetInnerHTML={{ __html: conversation.desc }}
                      />
                      {conversation?.file.length > 0 && (
                        <div className="flex items-center gap-x-1 mt-2">
                          <b>Attachments: </b>{" "}
                          {conversation.file?.map((file) => {
                            return (
                              <a
                                href={file.Content}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={file.Name}
                                className="border border-gray-300 px-3 py-1 rounded-full flex"
                              >
                                <p
                                  title={file.name}
                                  className="text-sm overflow-hidden whitespace-nowrap w-36 truncate"
                                >
                                  {file.Name}
                                </p>
                              </a>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="p-6 flex justify-end items-center gap-4 rounded-lg shadow-md w-full bg-white fixed bottom-0 left-0"
                style={{ boxShadow: "0px -1px 14px 0px rgba(0, 0, 0, 0.1)" }}
              >
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => toggleTab("Vendors")}
                >
                  Cancel
                </Button>
                <Button type="submit">
                  {loading ? <Loading /> : "Reinvite"}
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
        <Popup
          heading="Add To mail"
          open={addToMail}
          primaryButton={{
            text: "Add",
          }}
          handleSubmit={handleAddToMail}
          secondaryButton={{
            text: "Cancel",
          }}
          onClose={() => {
            setNewMailTo("");
            setAddToMail(false);
          }}
        >
          <input
            type="text"
            placeholder="Enter mail"
            className="border-2 border-gray-300 rounded-lg p-2 w-full"
            value={newMailTo}
            onChange={(e) => setNewMailTo(e.target.value)}
          />
          {mailToError && <p className="text-red-500 m-0">{mailToError}</p>}
        </Popup>
        <Popup
          heading="Add CC mail"
          open={addCcMail}
          primaryButton={{
            text: "Add",
          }}
          handleSubmit={handleAddCcMail}
          secondaryButton={{
            text: "Cancel",
          }}
          onClose={() => {
            setNewCcMail("");
            setCcMailError("");
            setAddCcMail(false);
            setShowSuggestions(false);
          }}
        >
          <input
            type="text"
            placeholder="Enter mail"
            className="border-2 relative border-gray-300 rounded-lg p-2 w-full"
            value={newCcMail}
            onChange={handleInputChange}
          />
          {showSuggestions && (
            <div className="fixed bg-white border border-gray-300 mt-1 rounded-lg pt-2 z-10 max-h-60 overflow-y-auto">
              {suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="p-2 cursor-pointer hover:bg-gray-200"
                  onMouseDown={() => handleSuggestionClick(suggestion)}
                >
                  {getHighlightedText(suggestion, newCcMail)}
                </div>
              ))}
            </div>
          )}
          {ccMailError && <p className="text-red-500 m-0">{ccMailError}</p>}
        </Popup>
      </div>
    </SidePanel>
  );
};

export default ResendRfq;
