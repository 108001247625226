// or get from process.env.REACT_APP_{var} to handle PROD and DEV environments
export const REACT_APP_NAME = "Neevay";
export const APP_VERSION = "1.0.0";
export const API_BASE_URL = "/api";
export const ENABLE_REDUX_LOGGER = false;

export const SIGN_UP_COUNTER = 30;
export const SIGN_IN_COUNTER = 30;
export const VERIFIED_DISPLAY_COUNTER = 30;

export const IMAGE_TYPE = {
  LOGO: "logo",
  BANNER: "banner",
  SERVICE: "service",
  PROJECT: "project",
  PROFILE: "profile",
  CERTIFICATE: "certificate",
  CLIENT: "client",
};

export const TYPES = [
  "Material Only (Trader)",
  "Material Only (Manufacturer)",
  "Labour Only (Labour Contractor)",
  "Labour with Material (Providing and Fixing)",
  "Consultant",
];

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const RZP_KEY = process.env.REACT_APP_RZP_KEY;
export const RZP_NAME = process.env.REACT_APP_RZP_NAME;
export const RZP_DESC = process.env.REACT_APP_RZP_DESC;
export const RZP_LOGO = process.env.REACT_APP_RZP_LOGO;
export const RZP_COLOR = process.env.REACT_APP_RZP_COLOR;
export const RZP_CURRENCY = process.env.REACT_APP_RZP_CURRENCY;

// export const RZP_KEY = 'rzp_test_uNX8C3RWrK3DwA';
// export const RZP_NAME = 'Neevay';
// export const RZP_DESC = 'Subsciption for Pro Plans';
// export const RZP_LOGO =
//   'http://35.192.128.219:5600/logos/logo.svg';
// export const RZP_COLOR = '#3399cc';
// export const RZP_CURRENCY = 'INR';

export const DEFAULT_IMAGES = {
  SEARCH: "/assets/default/search_card.png",
  LOGO: "/assets/default/logo_default.png",
  PROFILE: "/assets/default/profile.png",
  SERVICE: "/assets/default/service.png",
  PROJECT: "/assets/default/project.png",
  BANNER: "/assets/home/hero/slide_img_d_4.png",
  AWARD: "/assets/default/search_card.png", //"/assets/default/project.png",
  CERTIFICATE: "/assets/default/search_card.png", // "/assets/default/certificate.png",
  CLIENT: "/assets/default/logo_default.png",
  // BANNER: "/assets/default/company_banner.png",
  PREMIUM_BADGE: "/assets/icons/crown.png",
};

//export const SEARCH_DEFAULT_IMAGE = "/assets/default/search_card.png";

export const SEARCH_FIELDS = {
  BUSINESS_NAME: "businessName",
  BUSINESS_TYPE: "businessProfile.businessType",
  DOMAINS: "businessProfile.domains_str",
  PROJECT_COUNT: "businessProfile.projectCount",
  IS_ISO_CERTIFIED: "businessProfile.isISOCertified",
  ESTABLISH_YEAR: "businessProfile.establishYear",
  BUSINESS_HEADLINE: "businessProfile.businessHeadline",
  ADDRESSES_CITY: "businessProfile.addresses.city",
  ADDRESSES_DISTRICT: "businessProfile.addresses.district",
  ADDRESSES_STATE: "businessProfile.addresses.state",
  ADDRESSES_COUNTRY: "businessProfile.addresses.country",
  LOCATIONS: "services.locations",
  SUPPLIER_ID: "supplierId",
  SUPPLIER_URL: "url",
  SERVICES: "services.serviceDetails",
  HEADOFFICE: "businessProfile.addresses.city",
  SUBSCRIPTION_TYPE: "subscriptionType",
  LOGO_URL: "businessProfile.logoUrl",
  GSTIN: "isGstInAvailable",
  SUPPLIER_TYPE: "businessProfile.businessType_str",
  BUSINESS_EMAIL: "businessProfile.businessEmail",
  BUSINESS_CONTACT: "businessProfile.contact",
  BUSINESS_MOBILE_NUMBER: "businessProfile.businessMobile",
  ANNUAL_TURNOVER: "annualTurnOver",
  SERVICE_NAME: "services.serviceName",
  DOMAINS: "businessProfile.domains",
  GST_NUMBER: "statutory.gstin",
  ISO_CERTIFICATES: "businessProfile.certificates",
  DESCRIPTION: "businessProfile.description",
  PROJECT_NAME: "supplierprojects.projects.projectName",
  REGIONS_COUNTRY: "regions.country",
  REGIONS_STATE: "regions.state",
  REGIONS_SELECTED_CITIES: "regions.Selectedcities",
  REGIONS_ALL_CITIES: "regions.Allcities",
  // cardRegistered: "Registered As Private Limted",
  // carProject: " 10 - 20 Projects Completed",
  // cardRegistered: "Registered As Private Limted",
  // carProject: " 10 - 20 Projects Completed",
  // cardCartificate: "ISO Certified",
  // establishment: "Establishment : 1998",
};

export const SEARCH_LABELS = {
  CATEGORY: "Category",
  BUSINESS_TYPE: "Company Type",
  DOMAINS: "Domains",
  IS_ISO_CERTIFIED: "ISO Certified",
  IS_GST_CERTIFIED: "GST Certified",
  ESTABLISH_YEAR: "Established Year",
  LOCATIONS: "Locations Serviced",
  CITY: "Head Office",
  ANNUAL_TURNOVER: "Annual Turnover",
  CERTIFICATES: "Certificates",
  PROJECT_COUNT: "Projects Delivered",
  SERVICES: "Services",
  SUPPLIER_TYPE: "Supplier Type",
  SUBSCRIPTION_TYPE: "Subscription Type",
  IS_PROJECT_AVAILABLE: "Is Projects",
  IS_CLIENTS_AVAILABLE: "Is Clients",
};

export const SEARCH_FACETS = {
  SERVICE_DETAILS: "services.serviceDetails_str",
  BUSINESS_TYPE: "businessProfile.businessType_str",
  CATEGORY: "businessProfile.businessCategory_str",
  DOMAINS: "businessProfile.domains_str",
  IS_ISO_CERTIFIED: "businessProfile.isISOCertified",
  IS_GST_CERTIFIED: "isGstInAvailable",
  ESTABLISH_YEAR: "businessProfile.establishYear",
  PROJECT_COUNT: "businessProfile.projectCount_str",
  LOCATIONS: "services.locations_str",
  CITY: "businessProfile.addresses.city_str",
  ANNUAL_TURNOVER: "annualTurnOver_str",
  CERTIFICATES: "businessProfile.certificates_str",
  SUPPLIER_TYPE: "businessProfile.supplierType_str",
  SUBSCRIPTION_TYPE: "subscriptionType",
  IS_PROJECT_AVAILABLE: "hasProjects",
  IS_CLIENTS_AVAILABLE: "hasClients",
};

export const UOMS = [
  "Sq. Feet",
  "Running Mtrs",
  "Sq. Mtrs",
  "Numbers",
  "Cubic Mtrs",
  "Kilograms",
  "Metric Ton",
  "Liters",
  "Bags",
  "Cubic Feet",
];

export const BUSINESS_TYPE = [
  // { id: "", label: "Select " },
  { id: "Proprietorship", label: "Proprietorship" },
  { id: "Partnership", label: "Partnership" },
  {
    id: "Limited Liability Partnership",
    label: "Limited Liability Partnership",
  },
  { id: "Private Limited", label: "Private Limited" },
  { id: "Public Limited", label: "Public Limited" },
  // { id: "Others", label: "Others" },
];

export const BUSINESS_DOMAIN = [
  "AIRPORTS",
  "BRIDGES",
  "COMMERCIAL",
  "DATA CENTERS",
  "HOSPITALS",
  "HOTEL",
  "INDUSTRIAL",
  "PHARMACEUTICAL",
  "INFRASTRUCTURE",
  " THERMAL POWER PLANT",
  "INSTITUTIONAL",
  "METRO",
  "RAILWAY",
  "RESIDENTIAL",
  "ROADS",
  "OTHERS",
];

export const ISO_CERTIFICATES = [
  "ISO 9001:2008",
  "ISO 9001:2015",
  "ISO 14001:2015",
  "ISO 45001:2018",
  "ISO 27001:2013",
  "ISO 50001:2018",
  "ISO 26000:2010",
  "ISO 31000:2018",
];

export const PROJECT_COUNT = [
  { id: "", label: "Select " },
  { id: "0 - 5", label: "0 - 5" },
  { id: "5 - 10", label: "5 - 10" },
  { id: "10 - 20", label: "10 - 20" },
  { id: "20 - 50", label: "20 - 50" },
  { id: "50 - 100", label: "50 - 100" },
  { id: "100+", label: "100+" },
];

export const SERVICE_CATEGORIES = [
  "Supplier",
  "Consultants",
  "Contractors",
  "Labour Contractor",
  "Transporter",
  "Others",
];

export const LOCATIONS = [
  "Maharasthra",
  "Ahmadnagar",
  "Akola",
  "Amravati",
  "Aurangabad",
  "Bhandara",
  "Bhusawal",
  "Beed",
  "Buldhana",
  "Chandrapur",
  "Daulatabad",
  "Dhule",
  "Jalgaon",
  "Kalyan",
  "Karli",
  "Kolhapur",
  "Mahabaleshwar",
  "Malegaon",
  "Matheran",
  "Mumbai",
  "Nagpur",
  "Nanded",
  "Nashik",
  "Osmanabad",
  "Pandharpur",
  "Parbhani",
  "Pune",
  "Ratnagiri",
  "Sangli",
  "Satara",
  "Sevagram",
  "Solapur",
  "Thane",
  "Ulhasnagar",
  "Vasai-Virar",
  "Wardha",
  "Yavatmal",
];

export default {};

//
// {
//     "minimumOrder": [
//         0
//     ],
//     "isVerified": [
//         false
//     ],
//     "isActive": [
//         false
//     ],
//     "hasProjects": [
//         false
//     ],
//     "addedBy": [
//         "sakshibhujang03@gmail.com"
//     ],
//     "completion": [
//         42
//     ],
//     "_id": [
//         "62f63b2c2aa6a86d090f1f3b"
//     ],
//     "businessName": [
//         "Della Regency"
//     ],
//     "businessProfile.businessHeadline": [
//         "16 Awards, 350 Happy Clients, 570 Projects Handled. We are best in class"
//     ],
//     "businessProfile.description": [
//         "Our fully equipped design studio is in Pune and all our designs and drawings for all projects across the country are produced out of this studio. In all other locations we have coordination staff for site visits and attending meetings with clients and architects. We have already delivered designs for more than 50 million sq. feet. Currently ABC team is engaged in designing about 30 million sq. feet of buildings."
//     ],
//     "businessProfile.businessType": [
//         "PROPRIETARY"
//     ],
//     "businessProfile.addresses.addressLine1": [
//         "Suryakamal Niwas , Bhairav nagar , Dhanori , PUNE"
//     ],
//     "businessProfile.addresses.city": [
//         "Pune"
//     ],
//     "businessProfile.addresses.district": [
//         "Pune"
//     ],
//     "businessProfile.addresses.state": [
//         "Maharashtra"
//     ],
//     "businessProfile.addresses.country": [
//         "India"
//     ],
//     "businessProfile.addresses.pincode": [
//         411015
//     ],
//     "businessProfile.isISOCertified": [
//         false
//     ],
//     "businessProfile.domains": [
//         "COMMERCIAL"
//     ],
//     "businessProfile.projectCount": [
//         "100+"
//     ],
//     "businessProfile._id": [
//         "62f63b2c2aa6a896c80f1f3c"
//     ],
//     "businessProfile.businessEmail": [
//         "sakshibhujang03@gmail.com"
//     ],
//     "businessProfile.businessMobile": [
//         9420977575
//     ],
//     "businessProfile.contact": [
//         22245656565
//     ],
//     "businessProfile.establishYear": [
//         2013
//     ],
//     "businessProfile.createdAt": [
//         "2022-08-12T11:36:12.077Z"
//     ],
//     "businessProfile.updatedAt": [
//         "2022-08-12T11:36:12.077Z"
//     ],
//     "services.capacity.qty": [
//         50000
//     ],
//     "services.capacity.uom": [
//         "Sq. Mtrs"
//     ],
//     "services.serviceDetails": [
//         "Shuttering labour"
//     ],
//     "services.locations": [
//         "Ahmadnagar (MH)",
//         "Satara (MH)",
//         "Pune (MH)"
//     ],
//     "services.isDeleted": [
//         false
//     ],
//     "services._id": [
//         "62f63b8e2aa6a8d0f20f1f44"
//     ],
//     "services.category": [
//         "Labour"
//     ],
//     "services.serviceName": [
//         "constructing services"
//     ],
//     "services.description": [
//         "Our fully equipped design studio is in Pune and all our designs and drawings for all projects across the country are produced out of this studio. In all other locations we have coordination staff for site visits and attending meetings with clients and architects. We have already delivered designs for more than 50 million sq. feet. Currently ABC team is engaged in designing about 30 million sq. feet of buildings."
//     ],
//     "services.createdAt": [
//         "2022-08-12T11:37:50.346Z"
//     ],
//     "services.updatedAt": [
//         "2022-08-12T11:37:50.346Z"
//     ],
//     "createdAt": [
//         "2022-08-12T11:36:12.077Z"
//     ],
//     "updatedAt": [
//         "2022-08-12T11:37:50.346Z"
//     ],
//     "supplierId": [
//         53
//     ],
//     "__v": [
//         1
//     ],
//     "url": [
//         "Della-Regency"
//     ],
//     "id": "c3d23325-aede-40fc-904a-be566f9d1479",
//     "_version_": 1752446068324302800
// }
