import React, { useEffect, useState } from "react";
import Popup from "./tableComponenets/Popup";
import ViewMore from "./tableComponenets/ViewMore";
import { PaginationSection } from "views/CompanyDirectory/components";
import fields from "./TableData";
import { addVendorsToRfq } from "actions/rfqActions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Drawer from "./tableComponenets/Drawer";
import BusinessInfoDrawer from "./tableComponenets/BusinessInfoDrawer";
import rfqService from "services/rfqService";
import Loading from "components/Loading";
import { searchByPage } from "actions/searchActions";
import { getSelectedFacetString } from "utils/searchUtils";
// import { withStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";
import { withStyles } from "@material-ui/core";
import ServiceOffered from "./tableComponenets/ServiceOffered";
import StaticPopup from "components/StaticPopup";
import { addVendorInCompanyDirectory } from "actions/companyDirectoryActions";

const CustomTooltip = withStyles({
  tooltip: {
    // backgroundColor: "#fff", // Change the background color here
    // color: "#000",
    // fontSize: "20px", // Change the text size here
  },
})(Tooltip);

const componentRegistry = (
  component,
  handleOpenBusinessInfoDrawerOpen,
  props
) => {
  console.log("this is a component 999999999", props);
  switch (component) {
    case "Popup":
      return <Popup {...props} />;
    case "ServicePopup":
      return <ServiceOffered values={props.values} title={props.title} />;
    case "ViewMore":
      return <ViewMore values={props.values} title={props.title} />;
    case "Drawer":
      return (
        <Drawer
          values={props.vendor}
          handleOpenBusinessInfoDrawerOpen={handleOpenBusinessInfoDrawerOpen}
        />
      );
    default:
      return (
        <span className="text-gray-500 font-medium">
          {props.title?.toUpperCase() === "GST STATUS"
            ? props.values == "true"
              ? "Active"
              : "Inactive"
            : props.values || "--"}
        </span>
      );
  }
};

const getClassName = (index, fieldsLength) => {
  let className = "px-6 py-3 font-medium text-gray-700";
  if (index === fieldsLength - 1) {
    className += " rounded-tr-md ";
  }
  return className;
};

const Table = ({ isSliderView = false, rfqId, handleSliderClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const searchResult = useSelector((state) => state.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openBusinessInfoDrawer, setOpenBusinessInfoDrawer] = useState(false);
  const [addVendorsLoader, setAddVendorsLoader] = useState(false);
  const [businessInfo, setBusinessInfo] = useState({});
  const [showAddVendorPopup, setShowAddVendorPopup] = useState(false);
  const [addNewVendorError, setAddNewVendorError] = useState(false);
  const [addVendorWithTagsLoading, setAddVendorWithTagsLoading] =
    useState(false);
  const [tagVendor, setTagVendor] = useState("");

  // const totalPages = Math.ceil(totalItems / itemsPerPage);

  const user = auth?.user;
  const userId = user?.userId;
  const userName = user?.name;
  const userEmail = user?.email;
  const entityId = user?.userEntity?.entityId;
  const directoryName = user?.userEntity?.directoryName;
  const searchKeyWord = searchResult?.searchKeyWord ?? "";
  const regionString = searchResult?.regionString ?? "pan india";
  const selectedFacets = searchResult?.selectedFacets;
  const start = searchResult?.start ?? 0;
  const data = searchResult.results ?? [];
  const loading = searchResult?.isSearching;
  const totalItems = searchResult?.numFound;
  const itemsPerPage = 0;
  const totalPages = totalItems > 60 ? Math.ceil(totalItems / 60) : 1;
  const handleInputChange = (e) => {
    setTagVendor(e.target.value);
  };

  const handlePageChange = (pageNum) => {
    setCurrentPage(pageNum);
    const filterStr = getSelectedFacetString(selectedFacets);
    dispatch(
      searchByPage(searchKeyWord, regionString, filterStr, "all", pageNum)
    );
    // dispatch(searchCompanyDirectoryVendor({ searchKey: searchValue, pageNum }));
  };
  const handleSelectRow = (e, rowData) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, rowData]);
    } else {
      setSelectedRows(
        selectedRows.filter(
          (selectedRow) => selectedRow.supplierId !== rowData.supplierId
        )
      );
    }
  };

  const handleClickAddToCompanyDirectory = () => {
    if (!isSliderView) setShowAddVendorPopup(true);
  };

  const handleAddVendorToCD = async () => {
    const supplierIds = selectedRows.map((supplier) => supplier?.supplierId);
    const vendorTags = tagVendor.split(/[ ,]+/);
    const payload = {
      entityId: entityId,
      neevaySupplierIds: supplierIds,
      userId: userId,
      userName: userName,
      tags: vendorTags ?? [],
    };
    const response = await dispatch(addVendorInCompanyDirectory(payload));
    setShowAddVendorPopup(false);
    setSelectedRows([]);
    setAddVendorWithTagsLoading(false);
    setTagVendor("");
  };

  const handleOpenBusinessInfoDrawerOpen = (values) => {
    setOpenBusinessInfoDrawer(true);
    setBusinessInfo(values);
  };

  const handleOpenBusinessInfoDrawerClose = () => {
    setOpenBusinessInfoDrawer(false);
    setBusinessInfo({});
  };

  const handleAddVendorsToRfq = async () => {
    setAddVendorsLoader(true);
    if (isSliderView) {
      try {
        const suppliers = await Promise.all(
          selectedRows?.map(async (row) => {
            const usersContactDataPromises = row?.owners?.map((userId) =>
              rfqService.getUserContacts({ userId }).catch((error) => {
                // Handle error for a specific user
                console.error("Error fetching user details:", error);
                // Return a placeholder object for the user with an error flag
                return { error: true, message: error.message };
              })
            );

            // Wait for all user data promises to resolve or reject
            const usersContactData = await Promise.all(
              usersContactDataPromises
            );

            // Extract user details from each usersContactData object
            const contacts = usersContactData.map((contact) => {
              if (contact.error) {
                // Handle error for a specific user
                console.error("Error fetching user details:", contact.message);
                return {}; // Return null or handle the error as needed
              }
              // Extract user details
              return {
                mobile: contact.user?.mobile,
                email: contact.user?.email,
                name: contact.user?.name,
              };
            });
            const contactDetails = [
              ...contacts,
              {
                mobile: row?.businessMobileNumber,
                email: row?.businessEmail,
                name: "user",
              },
            ];
            const uniqueArrayOfObjects = contactDetails?.reduce((acc, obj) => {
              // Check if the email already exists in the accumulator array
              const existingEmail = acc.find(
                (item) => item.email === obj.email
              );

              // If not found, push the object to the accumulator array
              if (!existingEmail) {
                acc.push(obj);
              }

              return acc;
            }, []);
            const supplierObj = {
              supplierId: row?.supplierId.toString(),
              businessName: row?.businessName?.join(),
              isNeevayVendor: true,
              isPremium: row?.subscriptionType === "PREMIUM",
              contactDetails: uniqueArrayOfObjects.filter(
                (item) => Object.keys(item).length !== 0
              ),
              addedBy: { name: userName, userId: userId },
            };

            return supplierObj;
          })
        );
        dispatch(
          addVendorsToRfq({
            rfqId,
            suppliers,
            requesterName: userName,
            requestedBy: userEmail,
          })
        );
        setAddVendorsLoader(false);
        handleSliderClose();
        navigate(`/rfqs/${rfqId}/Vendors`);
      } catch (error) {
        console.error("Error fetching user contacts:", error);
        setAddVendorsLoader(false);
        setAddNewVendorError(true);
        setTimeout(() => {
          setAddNewVendorError(false);
        }, 3000);
        // Handle error as needed
      }
    }
  };

  useEffect(() => {
    //when user clr filter or any type of changes with vendors data then selected goes blank
    setSelectedRows([]);
    const pageNum = start > 0 ? start / 60 + 1 : 1;
    setCurrentPage(pageNum);
    handleOpenBusinessInfoDrawerClose();
  }, [data]);

  if (!loading && totalItems == 0) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        {searchKeyWord !== "" ? (
          <div className="flex flex-col items-center">
            <img
              src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Search_image.png"
              alt="Search Image"
              className="h-[116px] w-[122px] mb-6 "
            />
            <p className="text-sm font-bold text-[#3C3C3C]">
              No results were found for the search performed.
            </p>
          </div>
        ) : (
          <div className="flex flex-col items-center">
            <img
              src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Search_image.png"
              alt="Search Image"
              className="h-[116px] w-[122px] mb-6 "
            />
            <p className="text-sm font-bold text-[#3C3C3C]">
              Please search for vendors in the Neevay Marketplace from the{" "}
              <span className="text-[#FD6600]">Search Bar</span> above.
            </p>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-between items-center m-2">
        <div>
          {totalItems >= 1 && (
            <h3>
              {`Showing ${(currentPage - 1) * 60 + 1} - ${
                (currentPage - 1) * 60 + searchResult?.results?.length
              } of ${searchResult?.numFound} ${
                searchResult?.results?.length > 1 ? "vendors" : "vendor"
              } found for `}
              <span className="text-[#fd6600] font-bold">
                "{searchResult?.searchKeyWord}"
              </span>
            </h3>
          )}
        </div>

        <div className="flex gap-4 items-center">
          {!isSliderView ? (
            <button
              disabled={!selectedRows?.length >= 1 || addVendorsLoader}
              onClick={handleClickAddToCompanyDirectory}
            >
              <div
                className={`flex gap-2 px-3 py-2 rounded items-center  ${
                  selectedRows?.length >= 1
                    ? "bg-primary-700 hover:bg-primary-800 text-white"
                    : "bg-gray-300 text-gray-100"
                }`}
              >
                <svg
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_4723_685)">
                    <path
                      d="M9.7 4.92105V5.42105H10.2H11.4C11.418 5.42105 11.4408 5.42834 11.4618 5.45041C11.4836 5.47335 11.5 5.50969 11.5 5.55263C11.5 5.59557 11.4836 5.63191 11.4618 5.65485C11.4408 5.67692 11.418 5.68421 11.4 5.68421H10.2H9.7V6.18421V7.44737C9.7 7.4903 9.68356 7.52665 9.66177 7.54959C9.6408 7.57165 9.61801 7.57895 9.6 7.57895C9.582 7.57895 9.5592 7.57165 9.53824 7.54959C9.51644 7.52665 9.5 7.4903 9.5 7.44737V6.18421V5.68421H9H7.8C7.782 5.68421 7.7592 5.67692 7.73824 5.65485C7.71644 5.63191 7.7 5.59557 7.7 5.55263C7.7 5.5097 7.71644 5.47335 7.73824 5.45041C7.7592 5.42834 7.782 5.42105 7.8 5.42105H9H9.5V4.92105V3.65789C9.5 3.61496 9.51644 3.57861 9.53823 3.55568C9.5592 3.53361 9.582 3.52632 9.6 3.52632C9.61801 3.52632 9.6408 3.53361 9.66177 3.55567C9.68356 3.57861 9.7 3.61496 9.7 3.65789V4.92105ZM6.1 3.34211C6.1 4.66009 5.09117 5.68421 3.9 5.68421C2.70883 5.68421 1.7 4.66009 1.7 3.34211C1.7 2.02412 2.70883 1 3.9 1C5.09117 1 6.1 2.02412 6.1 3.34211ZM3.00027 7.31579C3.00039 7.31579 3.00051 7.31579 3.00063 7.31579H4.79937C4.79949 7.31579 4.79961 7.31579 4.79973 7.31579C5.45406 7.31671 6.08686 7.59051 6.55777 8.0862C7.02949 8.58275 7.29909 9.26116 7.3 9.97386C7.3 9.97399 7.3 9.97412 7.3 9.97425L7.3 11.8684C7.3 11.9114 7.28356 11.9477 7.26177 11.9706C7.2408 11.9927 7.218 12 7.2 12H0.6C0.581996 12 0.5592 11.9927 0.538235 11.9706C0.516444 11.9477 0.5 11.9114 0.5 11.8684V9.97398C0.500881 9.26124 0.770479 8.58278 1.24223 8.0862C1.71314 7.59051 2.34594 7.31671 3.00027 7.31579Z"
                      fill="white"
                      stroke="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_4723_685">
                      <rect
                        width="12"
                        height="12"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <p className="font-medium text-sm text-white">
                  {/* {`Add to ${directoryName} Directory`} */}
                  {`Add to Internal Vendors`}
                </p>
              </div>
            </button>
          ) : (
            <div className="flex flex-col items-center">
              <button
                disabled={!selectedRows?.length >= 1 || addVendorsLoader}
                onClick={handleAddVendorsToRfq}
                className={`rounded-lg p-2 m-auto 
            ${
              selectedRows?.length >= 1
                ? "bg-primary-700 hover:bg-primary-800 text-white"
                : "bg-gray-300 text-gray-100"
            }
            ${addVendorsLoader ? "opacity-50 cursor-not-allowed" : ""}
            `}
              >
                {addVendorsLoader ? <Loading /> : "Invite Vendors"}
              </button>
              {addNewVendorError && (
                <p className="text-red-500 text-xs">
                  something Wrong in selected data
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      {loading && (
        <div className="flex justify-center items-center h-[50vh]">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10"></div>
        </div>
      )}
      {!loading && totalItems >= 1 && (
        <div className="rounded-md shadow relative bg-white w-full">
          <div className="overflow-x-auto rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 min-w-max ">
              <thead
                className="text-xs text-gray-700 uppercase dark:bg-gray-700 rounded-lg h-12"
                style={{
                  // border: "1px solid #D2D2D2",
                  background: "#F9FAFB",
                }}
              >
                <tr>
                  {/* {isSliderView && (
                    <th
                      scope="col"
                      className="sticky left-0 top-0 z-10 p-4 bg-gray-50"
                    >
                      <div className="w-4"></div>
                    </th>
                  )} */}
                  <th
                    scope="col"
                    className={`font-medium text-gray-700 rounded-tl-md sticky left-0 ml-4 z-10`}
                    style={{ background: "#F9FAFB" }}
                  >
                    <div
                      className="flex items-center  px-4 py-3 gap-4"
                      style={{
                        boxShadow: "5px -2px 13.1px 0px rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      <p className="px-6 py-3">BUSINESS NAME</p>
                    </div>
                  </th>
                  {fields.slice(1).map((field, index) => (
                    <th
                      scope="col"
                      key={field.key}
                      className={getClassName(index, fields.length)}
                      style={{ background: "#F9FAFB" }}
                    >
                      {field.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((vendor) => (
                  <tr
                    key={vendor.supplierId}
                    className="border-b bg-white hover:bg-blue-50 group"
                  >
                    {console.log("this  is a data of vendor", vendor)}
                    <td className="sticky left-0 py-3 w-[280px]">
                      <div
                        className="flex items-center px-4 gap-4 absolute top-0 left-0 bottom-0 w-[280px] bg-white group-hover:bg-blue-50 truncate"
                        style={{
                          boxShadow: "5px 0 13.1px 0px rgba(0, 0, 0, 0.05)",
                          zIndex: 1,
                        }}
                      >
                        {/* {isSliderView && ( */}
                        <div className="flex items-center">
                          <input
                            id="checkbox-table-search-1"
                            type="checkbox"
                            onChange={(e) => handleSelectRow(e, vendor)}
                            checked={selectedRows.some(
                              (selectedRow) =>
                                selectedRow?.supplierId === vendor?.supplierId
                            )}
                            onClick={(e) => e.stopPropagation()}
                            className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="checkbox-table-search-1"
                            className="sr-only"
                          >
                            checkbox
                          </label>
                        </div>
                        {/* )} */}
                        <Drawer
                          values={vendor}
                          handleOpenBusinessInfoDrawerOpen={
                            handleOpenBusinessInfoDrawerOpen
                          }
                        />
                      </div>
                    </td>
                    {fields.slice(1).map((field, index) => {
                      const Component = componentRegistry(
                        field.component,
                        handleOpenBusinessInfoDrawerOpen,
                        {
                          ...field.componentProps,
                          values: vendor[field.mappedField],
                          title: field.title,
                          vendor: vendor,
                        }
                      );
                      return (
                        <td
                          key={field.key}
                          className={`hover:bg-blue-50 text-black px-6 py-4 `}
                          // className={`hover:bg-blue-50 ${
                          //   !index
                          //     ? `bg-inherit sticky ${
                          //         isSliderView ? "left-10" : "left-0"
                          //       } z-10`
                          //     : "text-black px-6 py-4"
                          // }`}
                        >
                          {Component}
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
            <style>
              {`
          .overflow-x-auto::-webkit-scrollbar {
            height: 6px;
          }

          .overflow-x-auto::-webkit-scrollbar-thumb {
            background-color: #949494; 
          }
        `}
            </style>
          </div>

          <PaginationSection
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
          <StaticPopup
            openModal={showAddVendorPopup}
            handleClose={() => setShowAddVendorPopup(false)}
            // header={`Add Vendor to ${directoryName} Directory`}
            header={`Add Vendor to Internal Vendors`}
          >
            <div className="flex flex-col gap-3 mt-8">
              <label className="font-bold text-sm text-[#222222]">
                Tag Vendor{" "}
                <span className="font-light italic text-sm text-black ml-2">
                  {"(Optional)"}
                </span>
              </label>
              <input
                id="tagVendor"
                type="text"
                value={tagVendor}
                onChange={handleInputChange}
                className="w-[244px] px-3 py-2 border border-[#9C9C9C] bg-[#EEEEEE] rounded-md "
              />
            </div>
            <button
              className="bg-[#1A56DB] px-6 py-3 max-w-32 mt-auto rounded hover:bg-blue-800"
              onClick={() => {
                setAddVendorWithTagsLoading(true);
                handleAddVendorToCD();
              }}
            >
              <p className="font-bold text-sm text-white">
                {addVendorWithTagsLoading ? <Loading /> : "Add Vendor"}
              </p>
            </button>
          </StaticPopup>
          <BusinessInfoDrawer
            handleOpenBusinessInfoDrawerClose={
              handleOpenBusinessInfoDrawerClose
            }
            values={businessInfo}
            openBusinessInfoDrawer={openBusinessInfoDrawer}
          />
        </div>
      )}
    </>
  );
};

export default Table;
