import { Delete, Edit } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  addProjectTrigger,
  deleteProject,
  editProjectTrigger,
} from "actions/projectActions";
import { ImageEditor } from "components";
import { IMAGE_TYPE } from "config";
import { DEFAULT_IMAGES } from "config";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { ProjectRow } from "./ProjectRow";

function Projects({
  profile,
  projects,
  IsSearchPublicProfile,
  isEditView,
  permissions,
  PublicProfile,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [showFullText, setShowFullText] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const cellStyle = {
    border: "1px solid rgba(217, 217, 217, 1)",
    borderRadius: "10px",
    overflow: "hidden",
    textalign: "center",
    padding:"10px"
  };

  const TabelCellData = [
    { title: "Photo" },
    { title: "Projects" },
    { title: "Client" },
    { title: "Work Done" },
  ];

  // const toggleFullText = () => {
  //   setShowFullText(!showFullText);
  // };
  const handleAddProject = () => {
    if (permissions?.isUpdateAllowed) {
      if (permissions?.isOwner) {
        dispatch(addProjectTrigger);
        navigate("/add-project");
      } else {
      }
    } else {
      navigate("/subscribe-plan");
    }
  };
  const handleEditProject = (projectData) => {
    dispatch(editProjectTrigger(projectData));
    navigate("/add-project");
  };
  const handleDeleteProject = (projectName, projectId) => {
    
    dispatch(deleteProject(profile?.supplierId, projectName, projectId));
  };
  return (
    <Grid
      sx={{
        marginLeft: "26px",
        marginX: "46px",
        paddingBottom: "35px",
        paddingX: IsSearchPublicProfile ? "26px" : "",
      }}
    >
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 600,
          marginBottom: "10px",
        }}
      >
        Projects
      </Typography>
      <Grid style={{ marginBottom: "36px" }}>
        {permissions &&
          !permissions.isViewOnly &&
          permissions.isFreeUpdateAllowed && (
            <Typography
              color="secondary"
              size="small"
              disabled={!(permissions && permissions.isUpdateAllowed)}
              sx={{ fontSize: "15px", cursor: "pointer" }}
              onClick={handleAddProject}
            >
              <Link style={{ textDecoration: "underline", color: "#2864BE",cursor:"pointer" }}>
                {" "}
                Click Here{" "}
              </Link>
              <span style={{ color: "black" }}> to upload Project</span>
            </Typography>
          )}
      </Grid>
      {projects?.length > 0 && (
              <TableContainer
              component={Paper}
              sx={{
                border: "1px solid rgba(217, 217, 217, 1)",
                borderRadius: "16px ",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    {TabelCellData.map((tabel) => (
                      <TableCell
                        sx={{
                          textAlign: "center",
                          border: "1px solid rgba(217, 217, 217, 1)",
                          overflow: "hidden",
                          paddingY: "6px",
                        }}
                      >
                        {tabel.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody style={cellStyle}>
                  {projects?.map((data, index) => (
                    <ProjectRow                   
                    profile={profile}
                    index={index}
                    data={data}
                    isEditView={isEditView}
                    permissions={permissions}
                    cellStyle={cellStyle}
                    handleEditProject={handleEditProject}
                    handleDeleteProject={handleDeleteProject}
                  />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
              )}
      
    </Grid>
  );
}

export default Projects;
