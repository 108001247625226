import React from 'react';
import Navbar from './BuyerComponents/Navbar';
import Hero from './BuyerComponents/Hero';
import Logos from './BuyerComponents/Logos';
import EasySteps from './BuyerComponents/EasySteps';
import BenefitsOfNeevay from './BuyerComponents/BenefitsOfNeevay';
import FindNextContractor from './BuyerComponents/FindNextContractor';
import Testimonials from './BuyerComponents/Testimonials';
import RFQmngt from './BuyerComponents/RFQmngt';
import LatestBlog from './BuyerComponents/LatestBlog';
import Footer from './BuyerComponents/Footer';
import './BuyerComponents/custom.css';
import { useRef } from 'react';
import LatestBlogForMobile from './BuyerComponents/LatestBlogsForMobile';
import { CompanySlider } from 'views/Home 3.0/components';
import CompanyLogoSlider from './BuyerComponents/CompanyLogoSlider';
import Cards from './BuyerComponents/Cards';

const Homepage = () => {
    const homeRef = useRef(null);
    const scrollToHome = () => {
        homeRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    return (
        <div className='Homepage-scrollbar-hidden max-w-[1280px] mx-auto' style={{ fontFamily: "trade-gothic-lt" }}>
            <Navbar />
            <div className="lg:h-[677px] xl:h-[721px] md:h-[587px] h-[636px] w-full bg-cover bg-center lg:bg-top bg-no-repeat bg-[url('https://storagereponeevaydevcdn.blob.core.windows.net/business/homepage_4_background_cropped.jpg')] ">
                <div className='h-full bg-gradient-to-r from-[#24242D] from-[-44%] to-transparent'>
                    <Hero ref={homeRef} />
                </div>
            </div>
            {/* <Logos /> */}
            {/* <CompanySlider/> */}
            <CompanyLogoSlider />
            <div className='hidden md:block'>
                {/* <Cards /> */}
            </div>
            <FindNextContractor scrollToHome={scrollToHome} />
            <EasySteps scrollToHome={scrollToHome} />
            <BenefitsOfNeevay scrollToHome={scrollToHome} />
            <Testimonials />
            <RFQmngt />
            <div className='hidden md:block'>
                {/* <LatestBlog /> */}
            </div>
            <div className='md:hidden'>
                {/* <LatestBlogForMobile /> */}
            </div>
            <Footer />
        </div>
    );
};

export default Homepage;
