import Loading from "components/Loading";
import StaticPopup from "components/StaticPopup";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import rfqService from "services/rfqService";
import { hideContactDetails } from "actions/businessActions";

const ListComponent = ({ title, items }) => (
  <div className="mb-[30px]">
    <h3 className="text-xl font-bold mb-3">{title}</h3>
    <ul className="grid grid-cols-2 gap-y-[10px] gap-x-6 list-disc pl-4 mb-1 dark:text-gray-400">
      {items?.map((item, index) => (
        <li key={index}>
          <p className="truncate text-sm" title={item}>
            {item}
          </p>
        </li>
      ))}
    </ul>
  </div>
);

const BusinessInfoDrawer = ({
  openBusinessInfoDrawer,
  values,
  handleOpenBusinessInfoDrawerClose,
}) => {
  const highlights = values?.hightlights;
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [viewMore, setViewMore] = useState(true);
  const [openContactInfo, setOpenContactInfo] = useState(false);
  const [openContactInfoLoading, setOpenContactInfoLoading] = useState(false);
  const [contactInfo, setContactInfo] = useState([]);
  const descriptionRef = useRef(null);

  const userProfile = auth.user;
  const userId = userProfile?.userId;
  const entityId = userProfile?.userEntity?.entityId;

  const handleCloseContactInfoPopup = () => {
    setOpenContactInfo(false);
  };

  const handleClickOpenContactInfoPopup = async () => {
    const supplierDetails = {
      supplierId: values?.supplierId,
      contact: values?.contact,
      supplierUrl: values.url,
    };
    // console.log("this is dispatchg", supplierDetails);
    dispatch(hideContactDetails(userId, supplierDetails, entityId));
    const usersContactDataPromises = values?.owners?.map((userId) =>
      rfqService.getUserContacts({ userId }).catch((error) => {
        // Handle error for a specific user
        console.error("Error fetching user details:", error);
        // Return a placeholder object for the user with an error flag
        return { error: true, message: error.message };
      })
    );
    // Wait for all user data promises to resolve or reject
    const usersContactData = await Promise.all(usersContactDataPromises);

    // Extract user details from each usersContactData object
    const contacts = usersContactData.map((contact) => {
      if (contact.error) {
        // Handle error for a specific user
        console.error("Error fetching user details:", contact.message);
        return {}; // Return null or handle the error as needed
      }
      // Extract user details
      return {
        mobile: contact.user?.mobile,
        email: contact.user?.email,
        name: contact.user?.name,
      };
    });
    // console.log("this is a conts contacts", contacts);
    setContactInfo(
      contacts?.filter((contact) => Object.keys(contact).length !== 0)
    );
    setOpenContactInfo(true);
    setOpenContactInfoLoading(false);
  };

  const handleHeightOfDescription = () => {
    if (descriptionRef?.current) {
      // Check if description height exceeds 94px after component is mounted
      if (descriptionRef?.current?.scrollHeight > 99) {
        setExpanded(true);
        setViewMore(false);
      }
    }
  };

  const getInitials = (input) => {
    // Split the input string into an array of words
    const words = input?.split(" ");

    // Initialize an empty string to store the initials
    let initials = "";

    // Iterate through each word
    words?.forEach((word) => {
      // Append the first character of each word (converted to uppercase) to the initials string
      initials += word[0]?.toUpperCase();
    });

    // Return the initials
    return initials;
  };

  useEffect(() => {
    setExpanded(false);
    setOpenContactInfoLoading(false);
    handleHeightOfDescription();
  }, [values?.description]);

  const toggleExpanded = () => {
    setViewMore(!viewMore);
  };
  return (
    <div
      className={`fixed inset-0 bg-slate-400 bg-opacity-55 z-30 flex justify-end ${
        openBusinessInfoDrawer ? "translate-x-0" : "translate-x-full"
      }`}
      onClick={handleOpenBusinessInfoDrawerClose}
    >
      <div
        className={`fixed flex top-0 right-0 h-full w-96 text-black transition-transform duration-500 transform ${
          openBusinessInfoDrawer ? "translate-x-0" : "translate-x-full"
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="text-gray-400 h-7 bg-white hover:bg-gray-200  hover:text-gray-900 p-1 shadow-xl">
          <button
            onClick={handleOpenBusinessInfoDrawerClose}
            type="button"
            className="bg-transparent  rounded-lg text-sm inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-toggle="defaultModal"
          >
            <svg
              aria-hidden="true"
              className=" w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        {/* {openBusinessInfoDrawer && ( */}
        <div
          className=" px-5 py-[30px] shadow-xl max-h-xs z-10 min-w-[329px] overflow-y-auto overflow-x-hidden bg-white"
          tabIndex="-1"
          aria-hidden="true"
        >
          <div>
            <h3 className="font-semibold text-2xl mb-[14px] leading-6 tracking-normal">
              {values?.businessName}
            </h3>
            <p className="text-[#868686] text-sm font-normal">{highlights}</p>

            <div className=" flex flex-col gap-2 my-[22px]">
              <button
                onClick={() => {
                  setOpenContactInfoLoading(true);
                  handleClickOpenContactInfoPopup();
                }}
              >
                <div className="bg-blue-700 cursor-pointer hover:bg-blue-800 text-center text-white text-sm font-bold h-[36px] w-full rounded flex items-center ">
                  <p className="m-auto font-bold text-sm">
                    {openContactInfoLoading ? (
                      <Loading />
                    ) : (
                      " Show Contact Info"
                    )}
                  </p>
                </div>
              </button>

              <a href={"/company/" + values?.url} target="_blank">
                <div className="border-blue-700 border cursor-pointer hover:border-blue-800 text-center text-gray-600 hover:text-gray-700 text-sm font-bold h-[36px] w-full rounded flex items-center ">
                  <p className="m-auto font-bold text-sm">
                    View Detailed Profile
                  </p>
                </div>
              </a>
            </div>
          </div>

          {(values?.establishYear || values?.annualTurnOver) && (
            <div className="mb-[30px]">
              <h3 className="text-xl font-bold mb-3">Summary</h3>
              {values?.establishYear && (
                <p className="mb-[10px] text-sm">
                  Age of business :{" "}
                  {new Date().getFullYear() - values?.establishYear > 0 &&
                    `${new Date().getFullYear() - values?.establishYear} Years`}
                </p>
              )}
              {values?.annualTurnOver && (
                <p className="text-sm">
                  Turnover :{values?.annualTurnOver ?? "not disclosed"}.
                </p>
              )}
              {/* <p className=">b-1">Avg size of project : 2000 Units</p>
                  <p>80% repeat customers</p> */}
            </div>
          )}

          {values?.country?.length >= 1 ? (
            <ListComponent title="Areas Served" items={[values?.country]} />
          ) : (
            (values?.selectedcities?.length >= 1 ||
              values?.state?.length >= 1) && (
              <div className="mb-[30px]">
                <h3 className="text-xl font-bold mb-3">Areas Served</h3>
                <div className="pl-1 flex flex-col gap-4">
                  {values?.state?.length >= 1 && (
                    <div>
                      <h5 className="font-semibold text-gray-600 mb-1">
                        States
                      </h5>
                      <ul className="grid grid-cols-2 gap-y-[10px] gap-x-6 list-disc pl-4 mb-1 dark:text-gray-400">
                        {values?.state?.map((item, index) => (
                          <li key={index}>
                            <p className="truncate text-sm" title={item}>
                              {item}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {values?.selectedcities?.length >= 1 && (
                    <div>
                      <h5 className="font-semibold text-gray-600 mb-1">
                        Cities
                      </h5>
                      <ul className="grid grid-cols-2 gap-y-[10px] gap-x-6 list-disc pl-4 mb-1 dark:text-gray-400">
                        {values?.selectedcities?.map((item, index) => (
                          <li key={index}>
                            <p className="truncate text-sm" title={item}>
                              {item}
                            </p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            )
          )}

          {values?.serviceOffered && (
            <ListComponent title="Services" items={values?.serviceOffered} />
          )}
          {values?.projectNames && (
            <ListComponent
              title="Latest Projects"
              items={values?.projectNames}
            />
          )}
          {values?.isoCertificates && (
            <ListComponent
              title="Certifications"
              items={values?.isoCertificates}
            />
          )}
          <div>
            <h3 className="text-xl font-bold mb-3">Description</h3>
            <div
              ref={descriptionRef}
              className={`overflow-hidden ${viewMore ? "h-auto" : "h-[97px]"}`}
            >
              <p className="text-[#5C5C5C] text-sm font-normal overflow-y-auto">
                {ReactHtmlParser(values?.description)}
              </p>
            </div>
            {expanded && (
              <button
                className="text-blue-500 font-bold"
                onClick={() => toggleExpanded()}
              >
                {viewMore ? "Read less" : "Read more"}
              </button>
            )}
          </div>
        </div>
        {/* )} */}
      </div>
      <StaticPopup
        openModal={openContactInfo}
        handleClose={handleCloseContactInfoPopup}
        header={"Contact Info"}
        size={"sm"}
        headerLogo={
          <svg
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17 2.25C17.4125 2.25 17.75 2.5875 17.75 3V21C17.75 21.4125 17.4125 21.75 17 21.75H3.5C3.0875 21.75 2.75 21.4125 2.75 21V3C2.75 2.5875 3.0875 2.25 3.5 2.25H17ZM3.5 0C1.84531 0 0.5 1.34531 0.5 3V21C0.5 22.6547 1.84531 24 3.5 24H17C18.6547 24 20 22.6547 20 21V3C20 1.34531 18.6547 0 17 0H3.5ZM10.25 12C11.0456 12 11.8087 11.6839 12.3713 11.1213C12.9339 10.5587 13.25 9.79565 13.25 9C13.25 8.20435 12.9339 7.44129 12.3713 6.87868C11.8087 6.31607 11.0456 6 10.25 6C9.45435 6 8.69129 6.31607 8.12868 6.87868C7.56607 7.44129 7.25 8.20435 7.25 9C7.25 9.79565 7.56607 10.5587 8.12868 11.1213C8.69129 11.6839 9.45435 12 10.25 12ZM8.75 13.5C6.67812 13.5 5 15.1781 5 17.25C5 17.6625 5.3375 18 5.75 18H14.75C15.1625 18 15.5 17.6625 15.5 17.25C15.5 15.1781 13.8219 13.5 11.75 13.5H8.75ZM23 3.75C23 3.3375 22.6625 3 22.25 3C21.8375 3 21.5 3.3375 21.5 3.75V6.75C21.5 7.1625 21.8375 7.5 22.25 7.5C22.6625 7.5 23 7.1625 23 6.75V3.75ZM22.25 9C21.8375 9 21.5 9.3375 21.5 9.75V12.75C21.5 13.1625 21.8375 13.5 22.25 13.5C22.6625 13.5 23 13.1625 23 12.75V9.75C23 9.3375 22.6625 9 22.25 9ZM23 15.75C23 15.3375 22.6625 15 22.25 15C21.8375 15 21.5 15.3375 21.5 15.75V18.75C21.5 19.1625 21.8375 19.5 22.25 19.5C22.6625 19.5 23 19.1625 23 18.75V15.75Z"
              fill="#5B6882"
            />
          </svg>
        }
      >
        <div>
          <div className="bg-[#F2F4F7] py-[6px] px-2 mb-3">
            <p className="font-bold text-[#3F4B64]">Business Contact Details</p>
          </div>
          <div className="flex gap-4 p-2 pt-0 items-center">
            <svg
              width="30"
              height="27"
              viewBox="0 0 30 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="1.25"
                y="6.25"
                width="27.5"
                height="19.5"
                rx="2.75"
                stroke="#3F4B64"
                stroke-width="2.5"
              />
              <path
                d="M4 6.25H26C27.5188 6.25 28.75 7.48122 28.75 9V15.75H1.25V9C1.25 7.48122 2.48122 6.25 4 6.25Z"
                stroke="#3F4B64"
                stroke-width="2.5"
              />
              <path
                d="M11 16H19V19C19 19.5523 18.5523 20 18 20H12C11.4477 20 11 19.5523 11 19V16Z"
                fill="#3F4B64"
              />
              <mask id="path-4-inside-1_101_6" fill="white">
                <path d="M8 2C8 0.89543 8.89543 0 10 0H20C21.1046 0 22 0.895431 22 2V7H8V2Z" />
              </mask>
              <path
                d="M8 2C8 0.89543 8.89543 0 10 0H20C21.1046 0 22 0.895431 22 2V7H8V2Z"
                stroke="#3F4B64"
                stroke-width="5"
                mask="url(#path-4-inside-1_101_6)"
              />
            </svg>

            <div className="flex flex-col gap-[2px] text-sm text-gray-500">
              <p className="font-semibold text-sm text-[#1C2436]">
                {values?.businessName}
              </p>
              {values?.businessEmail && <p>{values?.businessEmail}</p>}
              {values?.businessMobileNumber && (
                <p>{values?.businessMobileNumber}</p>
              )}
            </div>
          </div>
        </div>
        {contactInfo.length >= 1 && (
          <div>
            <div className="bg-[#F2F4F7] py-[6px] px-2 mb-3">
              <p className="font-bold text-[#3F4B64]">Team Contact Details</p>
            </div>
            {contactInfo?.map((contact) => (
              <div className="flex gap-4 p-2 pt-0 items-center">
                <div className="h-9 w-9 rounded-full flex items-center justify-center bg-[#3F4B64] text-white font-bold text-sm">
                  {getInitials(contact?.name)}
                </div>
                <div className="flex flex-col gap-[2px] text-sm text-gray-500">
                  <p className="font-semibold text-sm text-[#1C2436]">
                    {contact?.name}
                  </p>
                  <p>{contact?.email}</p>
                  <p>{contact?.mobile}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </StaticPopup>
    </div>
  );
};

export default BusinessInfoDrawer;
