import React from "react";

const PaginationSection = ({ totalPages, currentPage, handlePageChange }) => {
  const displayPageNumbers = () => {
    // Initialize array with first page
    let pages = [1];
    // Check if total pages are greater than 5 for pagination logic
    if (totalPages > 5) {
      // Handle case when current page is beyond the initial pages
      if (currentPage > 3) {
        if (currentPage >= totalPages - 2) {
          // Display ellipsis and last few pages
          pages.push("...", totalPages - 2, totalPages - 1, totalPages);
        } else {
          // Display first , current and last few page
          pages.push("...", currentPage, "...", totalPages);
        }
      } else {
        // Display first few pages, ellipsis, and last page
        pages.push(2, 3, "...", totalPages);
      }
    } else {
      // Display all pages if total pages are 5 or fewer
      for (let index = 2; index <= totalPages; index++) {
        pages.push(index);
      }
    }
    // Return the array of page numbers
    return pages;
  };
  return (
    <div>
      <nav
        className="flex flex-col items-start justify-end p-4 space-y-3 md:flex-row md:items-center md:space-y-0"
        aria-label="Table navigation"
      >
        <ul className="inline-flex items-stretch -space-x-px">
          <li>
            <a
              href={currentPage !== 1 && "#"}
              className={`flex items-center justify-center h-full py-1.5 px-3 ml-0 bg-white rounded-l-lg border border-gray-300 ${
                currentPage === 1
                  ? " text-gray-200 bg-gray-200"
                  : " text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }`}
              onClick={() =>
                currentPage !== 1 && handlePageChange(currentPage - 1)
              }
            >
              <span className="sr-only">Previous</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </li>
          {displayPageNumbers().map((page, index) =>
            page == currentPage ? (
              <li>
                <a
                  href="#"
                  aria-current="page"
                  className="z-10 flex items-center justify-center px-3 py-2 text-sm leading-tight border text-primary-600 bg-primary-50 border-primary-300 hover:bg-primary-100 hover:text-primary-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                >
                  {page}
                </a>
              </li>
            ) : (
              <li>
                <a
                  href={page !== "..." && "#"}
                  className="flex items-center justify-center px-3 py-2 text-sm leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  onClick={() => page !== "..." && handlePageChange(page)}
                >
                  {page}
                </a>
              </li>
            )
          )}

          <li>
            <a
              href={currentPage !== totalPages && "#"}
              className={`flex items-center justify-center h-full py-1.5 px-3 leading-tight bg-white rounded-r-lg border border-gray-300 ${
                currentPage === totalPages
                  ? " bg-gray-200 text-gray-300"
                  : " text-gray-500 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }`}
              onClick={() =>
                currentPage !== totalPages && handlePageChange(currentPage + 1)
              }
            >
              <span className="sr-only">Next</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default PaginationSection;
