import React from "react";

const OverviewDetails = ({ description, selectedFilesBase }) => {
  return (
    <div className="flex flex-col w-full justify-start">
      <h2 className="text-xl text-gray-900 font-bold mb-2">RFQ Details</h2>
      <div className="max-h-72 overflow-x-auto">
        <p className="text-gray-500 text-base">
          {" "}
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </p>
      </div>
      <hr className="my-4" />
      <div className="flex gap-x-1">
        {selectedFilesBase?.map((file) => {
          return (
            <a
              href={file.Content}
              target="_blank"
              rel="noopener noreferrer"
              key={file.Name}
              className="border border-gray-300 px-3 py-1 rounded-full flex"
            >
              <p
                title={file.name}
                className="text-sm overflow-hidden whitespace-nowrap w-36 truncate"
              >
                {file.Name}
              </p>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default OverviewDetails;
