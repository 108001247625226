import React from "react";

const CheckboxFilter = ({
  addedFilters,
  handleInputChange,
  title,
  field,
  options,
}) => (
  <div className="mt-1 bg-white">
    {options?.map((value, index) => (
      <label
        key={index}
        className="flex cursor-pointer items-center hover:bg-blue-100 space-x-2 p-2"
      >
        <input
          type="checkbox"
          className="w-4 h-4 bg-gray-100 border-gray-300 rounded text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          checked={addedFilters[field]?.includes(value)}
          onChange={() => handleInputChange(field, value, true)}
        />
        <span className="text-sm">{value}</span>
      </label>
    ))}
  </div>
);

const RadioFilter = ({
  addedFilters,
  handleInputChange,
  title,
  field,
  options,
  showOptions,
}) => (
  <div className="mt-1 bg-white">
    {options.map((value, index) => (
      <label
        key={index}
        className="flex cursor-pointer hover:bg-blue-100  items-center space-x-2 p-2"
      >
        <input
          type="radio"
          name={title + "radios"}
          checked={addedFilters[field] == value}
          onChange={() => handleInputChange(field, value, false)}
          className="w-4 h-4 bg-gray-100 border-gray-300  text-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
        <span className="text-sm">{value}</span>
      </label>
    ))}
  </div>
);

const SearchCheckboxFilter = ({
  addedFilters,
  handleInputChange,
  title,
  type,
  options,
}) => (
  <div className="mt-1 bg-white">
    <input
      type="email"
      id="helper-text"
      aria-describedby="helper-text-explanation"
      className="bg-gray-50 border cursor-pointer border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      placeholder="search here"
    />
  </div>
);

const RangeFilter = ({
  addedFilters,
  handleInputChange,
  title,
  type,
  options,
}) => (
  <div className="mt-1 bg-white">
    <input
      type="range"
      // min={options[0]}
      // max={options[options.length - 1]}
      // step="any"
      // value={value}
      // onChange={handleChange}
      // className="slider-thumb"
    />
    <div className="flex justify-between mt-2">
      {options.map((option, index) => (
        <span key={index}>{option}</span>
      ))}
    </div>
  </div>
);

const TagsFilter = ({}) => {
  return (
    <>
      <input
        type="email"
        id="helper-text"
        aria-describedby="helper-text-explanation"
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="search here"
      />
    </>
  );
};

export {
  CheckboxFilter,
  RadioFilter,
  SearchCheckboxFilter,
  RangeFilter,
  TagsFilter,
};
