import React, { useEffect, useState } from "react";
import SlidingCompanyInfo from "./SlidingCompanyInfo";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompanyTags,
  fetchCompanyDirectoryVendors,
  filterCompanyDirectory,
  removeCompanyTags,
  searchCompanyDirectoryVendor,
} from "actions/companyDirectoryActions";
import TableComponent from "./tableComponent";
import ApplyTagsPopUp from "./ApplyTagsPopUp";
import { fetchTag } from "actions/tagsActions";
import { addVendorsToRfq } from "actions/rfqActions";
import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import VendorForm from "views/UserProfile/Requirements/vendorForm";
import ErrorPopup from "components/ErrorPopup";
import StaticPopup from "components/StaticPopup";
import AddVendorForm from "./AddVendorForm";

const TableSection = ({ isSliderView = false, rfqId, handleSliderClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const vendorsPayload = useSelector((state) => state.companyDirectory);
  const companyDirectory = useSelector((state) => state.companyDirectory);
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  const [isOpen, setIsOpen] = useState(false);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(
    companyDirectory?.searchKey ?? ""
  );
  const [openAddVendorPopup, setOpenAddVendorPopup] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [invalidEmailsDetails, setInvalidEmailsDetails] = useState([]);
  const [emailNotAvailableDetails, setEmailNotAvailableDetails] = useState([]);
  const [openEmailError, setOpenEmailError] = useState(false);
  const [showError, setShowError] = useState({ status: false, message: "" });

  // Handle search input

  const totalCount = vendorsPayload?.totalCount;
  const appliedFilters = companyDirectory?.appliedFilters;
  const searchKey = companyDirectory?.searchKey;
  const pageNum = companyDirectory?.currentPage;
  const user = auth?.user;
  const userId = user?.userId;
  const userName = user?.name;
  const userEmail = user?.email;
  const entityId = user?.userEntity?.entityId;

  const handleCloseEmailError = () => {
    setInvalidEmailsDetails([]);
    setEmailNotAvailableDetails([]);
    setOpenEmailError(false);
  };

  const createErrorMessage = () => {
    const errorMessage = "k";
  };

  const validateEmail = (email) => {
    // Trim the email to remove any leading or trailing spaces
    const trimmedEmail = email.trim();

    // Regular expression to validate the email format
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Test the trimmed email against the regular expression
    return emailPattern.test(trimmedEmail);
  };
  const handleOpenAddVendorPopup = () => {
    setOpenAddVendorPopup(true);
  };

  const handleCloseAddVendorPopup = () => {
    setOpenAddVendorPopup(false);
    setSnackbarOpen(false);
  };

  const handleAddTagsToVendors = async (selectedTags, type) => {
    const payload = {
      entityId: entityId,
      vendorIds: selectedRows?.map((vendor) => vendor?.id),
      userId: userId,
    };
    setTagsLoading(true);

    if (type === "add") {
      payload.tags = selectedTags;
      const result = await dispatch(addCompanyTags(payload));
      handleResult(result, appliedFilters);
    } else if (type === "remove") {
      payload.tagsToRemove = selectedTags;
      const result = await dispatch(removeCompanyTags(payload));

      //when some one remove that tags we ensure this tag is removed from filter also
      const filteredTags = appliedFilters?.tags
        ? appliedFilters?.tags?.filter((tag) => !selectedTags.includes(tag))
        : [];
      const filterData = appliedFilters?.tags
        ? { ...appliedFilters, tags: filteredTags }
        : appliedFilters;

      handleResult(result, filterData);
    }
  };

  const handleResult = (result, filterData) => {
    setTagsLoading(false);
    if (result?.error) {
      setShowError({ status: true, message: result.message });
    } else {
      setSelectedRows([]);
      setShowError({ status: false, message: "" });
      setIsOpen(false);
      // console.log("this is a filterData", filterData);
      dispatch(
        filterCompanyDirectory({
          filterData,
          searchKey,
          pageNum,
          userId,
          entityId,
        })
      );
    }
  };

  const handleSearchChange = (e) => {
    const searchString = e.target.value;
    setSearchValue(searchString);
    if (searchString === "") {
      dispatch(fetchCompanyDirectoryVendors({ entityId }));
      setSelectedRows([]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      dispatch(
        searchCompanyDirectoryVendor({ searchKey: searchValue, entityId })
      );
      setSelectedRows([]);
      e.preventDefault();
    }
  };
  const handleOpen = () => {
    setIsOpen(true);
    dispatch(fetchTag());
  };
  const handleAddVendorsToRfq = () => {
    const invalidEmail = [];
    const emailNotAvailable = [];
    const suppliers = selectedRows?.map((row) => {
      var emailExists = false;
      const contactDetails = row?.contactDetails.map((contact) => {
        // Create a new object to avoid modifying the original one
        if (contact.email) {
          if (!validateEmail(contact.email)) {
            invalidEmail.push({
              businessName: row?.Company,
              supplierId: row?.supplierId?.toString() || row?.id,
              email: contact.email,
            });
            emailExists = true;
          } else {
            emailExists = true;
          }
        }
        return {
          ...contact,
          name: contact.hasOwnProperty("name") ? contact.name : "User",
        };
      });
      if (!emailExists) {
        emailNotAvailable.push({
          businessName: row?.Company,
          supplierId: row?.supplierId?.toString() || row?.id,
        });
      }
      const supplierObj = row?.onNeevay
        ? {
            supplierId: row?.supplierId?.toString() || row?.id,
            businessName: row?.Company,
            isNeevayVendor: row?.onNeevay,
            isPremium: row?.isPremium,
            contactDetails: contactDetails,
          }
        : {
            supplierId: row?.id || row?.supplierId?.toString() || null,
            businessName: row?.Company,
            isNeevayVendor: row?.onNeevay,
            contactDetails: contactDetails,
          };
      return { ...supplierObj, addedBy: { name: userName, userId: userId } };
    });
    if (invalidEmail?.length === 0 && emailNotAvailable?.length === 0) {
      dispatch(
        addVendorsToRfq({
          rfqId,
          suppliers,
          requesterName: userName,
          requestedBy: userEmail,
        })
      );
      handleSliderClose();
      setSelectedRows([]);
      navigate(`/rfqs/${rfqId}/Vendors`);
    } else {
      setInvalidEmailsDetails(invalidEmail);
      setEmailNotAvailableDetails(emailNotAvailable);
      setOpenEmailError(true);
    }
  };
  useEffect(() => {
    dispatch(fetchCompanyDirectoryVendors({ entityId }));
  }, []);
  useEffect(() => {
    setSearchValue(companyDirectory?.searchKey ?? "");
  }, [companyDirectory]);
  // <section className="h-10 sticky top-0 z-20 bg-gray-100 flex items-center"></section>
  return (
    <>
      <div className="h-full flex flex-col gap-4">
        <section className="h-10 flex items-center">
          <div className=" mx-auto w-full">
            <div className="relative dark:bg-gray-800 sm:rounded-lg">
              <div className="flex flex-col items-center justify-between  space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                <div className="w-full md:w-1/2">
                  <div className="flex items-center">
                    <label htmlFor="simple-search" className="sr-only">
                      Search
                    </label>
                    <div className="relative w-full">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5 text-gray-500 dark:text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <input
                        type="text"
                        id="simple-search"
                        className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                        placeholder="Search"
                        value={searchValue}
                        onChange={handleSearchChange}
                        onKeyDown={handleKeyDown}
                        required=""
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-center" style={{ gap: "42px" }}>
                  {isSliderView ? (
                    <button
                      disabled={!selectedRows?.length >= 1}
                      onClick={handleAddVendorsToRfq}
                      className={`rounded-lg p-2 
                      ${
                        selectedRows?.length >= 1
                          ? "bg-primary-700 hover:bg-primary-800 text-white"
                          : "bg-gray-300 text-gray-100"
                      }`}
                    >
                      {/* Add New Vendor */}
                      Invite Vendors
                    </button>
                  ) : (
                    <div className="flex gap-2">
                      <button
                        className={`p-2 rounded-lg font-bold ${
                          selectedRows?.length >= 1
                            ? "text-black"
                            : "text-gray-600"
                        }`}
                        onClick={handleOpen}
                        disabled={!selectedRows?.length >= 1}
                      >
                        Apply Tags
                      </button>
                      <button
                        className="p-2 text-white bg-blue-700 rounded-lg font-medium hover:bg-blue-600"
                        onClick={handleOpenAddVendorPopup}
                      >
                        Add Vendor
                      </button>

                      {/* <Button
                        disabled={!selectedRows?.length >= 1}
                        onClick={handleOpen}
                        variant={
                          selectedRows?.length >= 1 ? "primary" : "disabled"
                        }
                      >
                        Apply Tags
                      </Button> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <p className="font-inter text-sm leading-4 tracking-normal text-left text-gray-600">
          {pageNum ? (
            <>
              {`Showing ${(pageNum - 1) * 60 + 1} - ${
                (pageNum - 1) * 60 + companyDirectory?.vendors?.length
              } of ${companyDirectory?.totalCount} ${
                companyDirectory?.vendors?.length > 1 ? "vendors" : "vendor"
              } found `}
              {companyDirectory?.searchKey ? (
                <span>
                  for{" "}
                  <span className="text-[#fd6600] font-bold">
                    "{companyDirectory?.searchKey}"
                  </span>
                </span>
              ) : (
                ""
              )}
            </>
          ) : null}
        </p>
        <div
          className="flex-grow overflow-x-auto overflow-y-auto"
          style={{ scrollbarWidth: "none", "-ms-overflow-style": "none" }}
        >
          <TableComponent
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            searchValue={searchValue}
          />
        </div>
      </div>

      <div>
        {isOpen && (
          <ApplyTagsPopUp
            tagsLoading={tagsLoading}
            setIsOpen={setIsOpen}
            handleAddTagsToVendors={handleAddTagsToVendors}
            showError={showError}
          />
        )}
      </div>

      {/* <SlidingCompanyInfo isOpen={isOpen} setIsOpen={setIsOpen} /> */}
      {/* <VendorForm
        open={openAddVendorPopup}
        onClose={handleCloseAddVendorPopup}
        userProfile={userProfile}
        onVendorAdded={() => setSnackbarOpen(true)}
      /> */}
      <StaticPopup
        openModal={openAddVendorPopup}
        handleClose={() => setOpenAddVendorPopup(false)}
        header={"Add Vendor"}
        size={"sm"}
        width={"3xl"}
      >
        <AddVendorForm
          setOpenAddVendorPopup={setOpenAddVendorPopup}
          user={user}
          setSnackbarOpen={setSnackbarOpen}
        />
      </StaticPopup>
      <ErrorPopup
        openModal={openEmailError}
        handleClosePopup={() => handleCloseEmailError(false)}
        size={"xl"}
        warningText={
          <p className="flex flex-col text-center">
            {
              <p className="mb-2 mt-[-8px] underline">
                Please note the following regarding vendors:
              </p>
            }
            {invalidEmailsDetails?.length >= 1 && (
              <span className="flex flex-col gap-1 mb-2">
                {invalidEmailsDetails.map((details) => {
                  return (
                    <p className="">
                      <span className="text-gray-800">
                        {details?.businessName}
                      </span>
                      : Invalid email address - <i>{details?.email}</i>
                    </p>
                  );
                })}
              </span>
            )}
            {emailNotAvailableDetails?.length >= 1 && (
              <span className="flex flex-col gap-1 mb-2">
                {emailNotAvailableDetails?.map((data) => {
                  return (
                    <p className="">
                      <span className="text-gray-800">
                        {data?.businessName}{" "}
                      </span>
                      {`: No email address provided.`}
                    </p>
                  );
                })}
              </span>
            )}
            <p>
              Kindly deselect these vendors and update the information
              accordingly. Thank you.
            </p>
          </p>
        }
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          elevation={6}
          variant="outlined"
          icon={false}
          onClose={() => setSnackbarOpen(false)}
          sx={{ bgcolor: "gray", color: "white" }}
        >
          {"Vendor added successfully"}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default TableSection;
