import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useIsMounted from "hooks/useIsMounted";

import {
  fetchBusinessById,
  fetchBusinessByUserId,
} from "actions/businessActions";
import { getBusinessPermissions } from "utils/accessControl.js";

import DashboardLayout from "layouts/Dashboard";
import Page from "views/UserProfile/components/Page";
import { Helmet } from "react-helmet";
import BusinessProfile from "../components/BusinessProfile";
import NewMyBusinesspage from "./NewMyBusinesspage";

function Business() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });
  const dispatch = useDispatch();
  const [profile, setProfile] = useState(null);
  const [projects, setProjects] = useState([]);
  const isMounted = useIsMounted();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;
  const userId = userProfile?.userId;

  const business = useSelector((state) => state.business);

  console.log("business",business )

  if (!userProfile) {
    navigate("/register");
  } else if (userProfile?.userId > 0 && userProfile?.name) {
  } else {
    navigate("/register/user");
  }

  useEffect(() => {
    // dispatch(fetchBusinessById(supplierId));
    dispatch(fetchBusinessByUserId(userId));
  }, [isMounted]);

  useEffect(() => {
    setProfile(business.profile);
    setProjects(business.projects);
  }, [business]);
  const isPublicProfile = false; // This is set to ensure edit feature is enabled

  const permissions = getBusinessPermissions(
    isPublicProfile,
    userProfile?.userId,
    business?.profile
  );
  return (
    <DashboardLayout path="/my-business ">
      <Helmet>
        <title>{`Neevay - ${business?.profile?.businessName}`} </title>
      </Helmet>
      <Page isPublicProfile={isPublicProfile}>
        {/* <BusinessProfile profile={profile} projects={projects} isPublicProfile={isPublicProfile} permissions={permissions} /> */}
        <NewMyBusinesspage
          profile={profile}
          projects={projects}
          isPublicProfile={isPublicProfile}
          permissions={permissions}
        />
      </Page>
    </DashboardLayout>
  );
}

export default Business;
