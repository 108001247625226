import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { useLocation, useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";

import Container from "components/Container";

import {
  Topbar,
  Sidebar,
  Footer,
  FooterMobile,
  CopyRight,
} from "layouts/Main/components";

import { getUrl } from "actions/businessActions";
import pages from "../navigation";
import FooterNew from "views/Home/components/FooterNew";
import { FloatingButton, GlobalNavbar } from "views/Home 3.0/components";

const Dashboard = ({
  path,
  children,
  colorInvert = false,
  bgcolor = "transparent",
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentLocation = useLocation();

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  const auth = useSelector((state) => state.auth);
  const userProfile = auth.user;

  const [validUser, setValidUser] = useState([]);
  useEffect(() => {
    // this is for is user not exit page auto reload and go sign
    if (!userProfile) {
      try {
        dispatch(getUrl(currentLocation.pathname));
      } catch (error) {
        // console.log(error);
      }
      navigate("/register");
    } else if (userProfile?.userId > 0 && userProfile?.name) {
    } else {
      navigate("/register/user");
    }

    // if (userProfile === null || userProfile === undefined) {
    //   // navigate("/signin-mobile");
    //   try {
    //     dispatch(getUrl(currentLocation.pathname));
    //   } catch (error) {
    //     // console.log(error);
    //   }
    //   if (!userProfile) {
    //     navigate("/signin-mobile");
    //   } // after login the page goes to the redirect previous page.
    // }
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box height={"100vh"} >
        <AppBar
          position={"sticky"}
          sx={{
            top: 0,
            // backgroundColor: trigger ? theme.palette.background.paper : bgcolor,
            backgroundColor: "#262728",
          }}
          elevation={trigger ? 1 : 0}
        >
          {/* <Container paddingY={1}> */}
          <GlobalNavbar />
          {/* <Topbar
          onSidebarOpen={handleSidebarOpen}
          pages={pages}
          // colorInvert={trigger ? false : colorInvert}
        /> */}
          {/* </Container> */}
        </AppBar>
        {/*}  <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      /> */}
        <main>
          {/* <Box height={{ xs: 58, sm: 66, md: 71 }} /> */}
          <Box
          // display="flex"
          // flex="1 1 auto"
          // overflow="hidden"
          //  paddingLeft={{ md: "256px" }}
          >
            {/* <Box display="flex" flex="1 1 auto" overflow="hidden">
            <Box flex="1 1 auto" height="100%" overflow="auto"> */}
            {children}
            {/* <Divider />
            </Box>
          </Box> */}
          </Box>
        </main>
        {/* <Container paddingY={4}> */}
        {/* {isSm ? <Footer /> : <FooterMobile />} */}
        {/* {isSm ? <FooterNew /> : <FooterMobile />} */}
        {/* <FooterNew /> */}
        {/* </Container> */}

        {/* <CopyRight /> */}
        <Box style={{ position: 'fixed', bottom: 0, width: '100%', textAlign: 'center' }}>
          <FloatingButton />
        </Box>
      </Box>
    </>
  );
};

Dashboard.propTypes = {
  children: PropTypes.node,
  colorInvert: PropTypes.bool,
  bgcolor: PropTypes.string,
};

export default Dashboard;
