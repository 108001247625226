import React from "react";
import GlobalSearchBar from "./GlobalSearchBar";
import SearchNew from "./SearchNew";

const NeevayNetworkHeader = () => {
  return (
    <div className="flex items-center justify-between w-full p-4 bg-white shadow-md">
      <div className="min-w-52">
        <h1 className="text-2xl font-bold pl-2">Neevay Marketplace</h1>
      </div>
      {/* <GlobalSearchBar /> */}
      <SearchNew />
      <div className="w-52"> </div>
    </div>
  );
};

export default NeevayNetworkHeader;
