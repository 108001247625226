import React, { useState } from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Box,
  FormHelperText,
  Button,
  Autocomplete,
  Typography,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import axios from "utils/axios";
import { updateSupplierStepper } from "actions/businessActions";
import { TYPES } from "config";

function BusinessRegStep1({ ...rest }) {
  const dispatch = useDispatch();
  const mobileNumberRegex = /^[0-9]{10}$/; // Change this regex according to your requirements

  const [businessExistMsg, setBusinessExistMsg] = useState(false);

  const business = useSelector((state) => state.business);
  const supplierId = business.profile?.supplierId;
  console.log("business 1...", business);

  return (
    <Grid sx={{ maxWidth: "100vw" }}>
      <Formik
        const
        initialValues={{
          businessEmail: "",
          businessContactNumber: "",
          whatsappNumber: "",
          businessCategory: [],
          supplierType: "",
          stepperCountValue: 1,
        }}
        validationSchema={Yup.object().shape({
          businessEmail: Yup.string()
            .email("Please enter a valid email address")
            .required("Business email is required"),

          businessContactNumber: Yup.string()
            .matches(mobileNumberRegex, "Please enter a valid number")
            .required("Business contact number is required"),

          whatsappNumber: Yup.string()
            .matches(mobileNumberRegex, "Invalid number")
            .required("Business Whatsapp number is required"),

          businessCategory: Yup.array()
            .of(Yup.string().required("Business Category is required"))
            .min(1, "At least one Business Category is required")
            .required("Business Category is required"),
          supplierType: Yup.string().when("businessCategory", {
            is: "Supplier",
            then: Yup.string().required("Supplier Type is required"),
            otherwise: Yup.string().notRequired(),
          }),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            // Make the API call using axios
            values.supplierId = supplierId;
            let payload = values;
            dispatch(updateSupplierStepper(values));

            // Handle successful response if needed
            // console.log('API call successful:', response.data);
          } catch (error) {
            // Handle errors
            const message =
              error.response?.data?.message || "Something went wrong";
            setStatus({ success: false });
            setErrors({ submit: message });
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldError,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} {...rest}>
            <Grid container flexDirection="column" gap={2}>
              <Grid>
                <Typography
                  style={{
                    color: " #5E5873",
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "17.4px",
                    letterSpacing: "0.14px",
                    marginBottom: "5px",
                  }}
                >
                  Business Email
                </Typography>
                <TextField
                  size="small"
                  name="businessEmail"
                  id="businessEmail"
                  type="businessEmail"
                  value={values.businessEmail}
                  variant="outlined"
                  fullWidth
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.businessEmail && errors.businessEmail)}
                  helperText={touched.businessEmail && errors.businessEmail}
                />
              </Grid>

              <Grid
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Grid xs={5.8}>
                  <Typography
                    style={{
                      color: " #5E5873",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "17.4px",
                      letterSpacing: "0.14px",
                      marginBottom: "5px",
                    }}
                  >
                    Business Contact No.
                  </Typography>
                  <TextField
                    size="small"
                    name="businessContactNumber"
                    id="businessContactNumber"
                    type="businessContactNumber"
                    value={values.businessContactNumber}
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(
                      touched.businessContactNumber &&
                        errors.businessContactNumber
                    )}
                    helperText={
                      touched.businessContactNumber &&
                      errors.businessContactNumber
                    }
                  />
                </Grid>
                <Grid xs={5.8}>
                  <Typography
                    style={{
                      color: " #5E5873",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "17.4px",
                      letterSpacing: "0.14px",
                      marginBottom: "5px",
                    }}
                  >
                    Business Number (WhatsApp)
                  </Typography>
                  <TextField
                    size="small"
                    name="whatsappNumber"
                    id="whatsappNumber"
                    type="whatsappNumber"
                    value={values.whatsappNumber}
                    variant="outlined"
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(
                      touched.whatsappNumber && errors.whatsappNumber
                    )}
                    helperText={touched.whatsappNumber && errors.whatsappNumber}
                  />
                </Grid>
              </Grid>
              <Grid
                xs={12}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Grid xs={12}>
                  <Typography
                    style={{
                      color: " #5E5873",
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      fontWeight: "400",
                      lineHeight: "17.4px",
                      letterSpacing: "0.14px",
                      marginBottom: "5px",
                    }}
                  >
                    Company Type
                  </Typography>

                  <Autocomplete
                    multiple
                    size="small"
                    id="demo-simple-select"
                    clearIcon={null} // Set clearIcon to null to remove the clear icon
                    options={TYPES}
                    getOptionLabel={(option) => option}
                    value={values.businessCategory}
                    onChange={(event, newValue) => {
                      handleChange({
                        target: {
                          name: "businessCategory",
                          value: newValue,
                        },
                      });
                    }}
                    onBlur={handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select"
                        error={Boolean(
                          touched.businessCategory && errors.businessCategory
                        )}
                        helperText={
                          touched.businessCategory && errors.businessCategory
                        }
                      />
                    )}
                  />
                </Grid>

                {values.businessCategory === "Supplier" && (
                  <Grid xs={5.8}>
                    <Typography
                      style={{
                        color: " #5E5873",
                        fontFamily: "Roboto",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "17.4px",
                        letterSpacing: "0.14px",
                        marginBottom: "5px",
                      }}
                    >
                      Supplier Type
                    </Typography>
                    <Autocomplete
                      size="small"
                      id="demo-simple-select"
                      clearIcon={null} // Set clearIcon to null to remove the clear icon
                      options={["Manufacturer", "Trader"]}
                      getOptionLabel={(option) => option}
                      value={values.supplierType}
                      onChange={(event, newValue) => {
                        handleChange({
                          target: { name: "supplierType", value: newValue },
                        });
                      }}
                      onBlur={handleBlur}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select"
                          error={Boolean(
                            touched.supplierType && errors.supplierType
                          )}
                          helperText={
                            touched.supplierType && errors.supplierType
                          }
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>

              {/* Add other form fields using Field component here */}

              <Button
                fullWidth
                size="small"
                type="submit"
                variant="contained"
                sx={{
                  borderRadius: "5px",
                  height: "38px",
                  fontSize: "14px",
                  fontWeight: 600,
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "black",
                    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.7)",
                    transition: "background-color 0.3s, box-shadow 0.3s",
                  },
                }}
                disabled={isSubmitting}
              >
                Next{" "}
              </Button>
            </Grid>
          </form>
        )}
      </Formik>
    </Grid>
  );
}

export default BusinessRegStep1;
