import React from "react";

const OverviewContacts = ({ newCcEmails, creator }) => {
  const getAvatar = (name) => {
    const nameArray = name?.split(" ");
    const initials =
      nameArray?.length === 1
        ? nameArray[0]?.charAt(0).toUpperCase()
        : nameArray[0]?.charAt(0) + nameArray[1]?.charAt(0);
    return (
      <div className="flex items-center gap-x-2">
        <div className="flex items-center justify-center w-6 h-6 bg-black rounded-full">
          <span style={{ fontSize: "10px" }} className="text-white font-bold">
            {initials}
          </span>
        </div>
        <b>{name}</b>
      </div>
    );
  };
  return (
    <div className="flex flex-col w-full justify-start">
      <h2 className="text-xl text-gray-900 font-bold mb-2">RFQ Contacts</h2>
      <h4 className="text-base mb-2 text-gray-600">Creator</h4>
      {getAvatar(creator)}
      {newCcEmails.length > 0 && (
        <h4 className="text-base mb-2 mt-5 text-gray-600">CC List</h4>
      )}
      {newCcEmails.map((email) => (
        <b className="w-full truncate" key={email}>
          {email}
        </b>
      ))}
    </div>
  );
};

export default OverviewContacts;
