import { Modal } from "flowbite-react";
import React from "react";

const TagsPopUp = ({ header, tags, setTagsPopUp }) => {
  return (
    // <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
    //   <div className="bg-white p-4 rounded-lg min-w-min-sm max-w-max-md">
    //     <h2 className="text-lg font-semibold mb-4">{header}</h2>
    //     {/* Pop-up content */}
    //     <div className="flex flex-wrap w-60 gap-1">
    //       {tags?.map((tag, index) => (
    //         <React.Fragment key={index}>
    //           <p>{tag}</p>
    //           {/* Content to add after each tag */}
    //           {index !== tags.length - 1 && <p>,</p>}
    //         </React.Fragment>
    //       ))}
    //     </div>
    //     <div className="mt-4 flex justify-end">
    //       <button
    //         onClick={() => setTagsPopUp(false)}
    //         className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
    //       >
    //         Close
    //       </button>
    //     </div>
    //   </div>
    // </div>
    <Modal
      style={{ zIndex: "50" }}
      dismissible
      show={true}
      onClose={() => setTagsPopUp(false)}
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Body>
        <div className="flex flex-wrap gap-3">
          {tags?.map((tag, index) => (
            <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
              {tag}
            </span>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => setTagsPopUp(false)}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default TagsPopUp;
