import { Tooltip } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const Drawer = ({ values, handleOpenBusinessInfoDrawerOpen }) => {
  const businessName = values?.businessName?.join();
  const navigate = useNavigate();

  return (
    <div className="flex gap-1">
      <p
        className=" text-blue-600 font-medium truncate w-52 text-left cursor-pointer"
        title={businessName}
        onClick={() => handleOpenBusinessInfoDrawerOpen(values)}
      >
        {/* <a href={"/company/" + values?.url} target="_blank"> */}
        {businessName}
        {/* </a> */}
      </p>
      {values.subscriptionType === "PREMIUM" && (
        <Tooltip
          title="Vendor has been verified for their services & contact details"
          placement="top"
          arrow
          sx={{ width: "100px" }}
        >
          <img
            src="https://storagereponeevaydevcdn.blob.core.windows.net/business/Verified_image_nn.png"
            alt="verified"
            className={`h-[18px] w-[18px]`}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default Drawer;
