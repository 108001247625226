class SendRfqInfo {
  constructor({ entityId, selectedFilesBase, values }) {
    this.name = values.rfqName;
    this.estimatedValue = values.estimatedValue;
    this.rfqType = values.requirementType;
    this.validityDate = values.dueDate;
    this.newCcEmails = values.emailCopyRecipients;
    this.projectName = values.projectName;
    this.projectId = values.projectId;
    this.location = values.projectLocation;
    this.creditPeriod = values.creditPeriod;
    this.description = values.rfqDetails;
    this.indentId = values.indentId;
    this.requesterName = values.requesterName;
    this.requesterMobile = values.requesterMobile;
    this.userBusinessName = values.userBusinessName;
    this.requestedBy = values.requestedBy;
    this.requesterId = values.requesterId;
    this.isEntityUser = values.isEntityUser;
    this.workStartDate = values.workStartDate;
    this.selectedFilesBase = selectedFilesBase;
    this.entityId = entityId;
  }
}
class SendEditRfqInfo {
  constructor({ selectedFilesBase, values }) {
    this.rfqId = values?.rfqId;
    this.name = values.rfqName;
    this.estimatedValue = values.estimatedValue;
    this.rfqType = values.requirementType;
    this.validityDate = values.dueDate;
    this.newCcEmails = values.emailCopyRecipients;
    this.projectName = values.projectName;
    this.projectId = values.projectId;
    this.location = values.projectLocation;
    this.creditPeriod = values.creditPeriod;
    this.description = values.rfqDetails;
    this.indentId = values.indentId;
    this.selectedFilesBase = selectedFilesBase;
  }
}
export default { SendRfqInfo };
export { SendRfqInfo, SendEditRfqInfo };
