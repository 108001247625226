import React, { useEffect, useState } from "react";
import BreadCrumbs from "./BreadCrumbs";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import GeneralInformation from "./GeneralInformation";
import RfqDetailsSection from "./RfqDetailsSection";
import { editRfq, fetchRfqProjects } from "actions/rfqActions";
import Header from "../../../components/Header";
import SidePanel from "layouts/SidePanel";

const validationSchema = Yup.object({
  rfqName: Yup.string().required("RFQ Name is required"),
  // estimatedValue: Yup.string().required("Estimated Value is required"),
  requirementType: Yup.string().required("Requirement Type is required"),
  // dueDate: Yup.date().required("Due Date is required"),
  emailCopyRecipients: Yup.array()
    .of(Yup.string().email("Invalid email format"))
    .required("Email Copy Recipients is required"),
  projectName: Yup.string().required("Project Name is required"),
  // projectLocation: Yup.string().required("Project Location is required"),
  // creditPeriod: Yup.string().required("Credit Period is required"),
  rfqDetails: Yup.string().required("RFQ Details is required"),
});

const EditRfqs = ({ toggleTab, data }) => {
  const dispatch = useDispatch();
  const projectData = useSelector((state) => state.rfqProjects);
  const auth = useSelector((state) => state.auth);

  const projects = projectData?.projects ?? [];
  const [selectedFilesBase, setSelectedFilesBase] = useState(
    data.selectedFilesBase ?? []
  );
  const [rfqFormValues, setRfqFormValues] = useState({
    rfqId: data?.rfqId,
    rfqName: data?.name,
    estimatedValue: data?.estimatedValue,
    requirementType: data?.rfqType,
    dueDate: data?.validityDate
      ? new Date(data?.validityDate).toLocaleDateString("en-CA") ??
        new Date().toLocaleDateString("en-CA")
      : "",
    emailCopyRecipients: [...data?.newCcEmails],
    projectName: data?.projectId ? data?.projectId : data?.projectName,
    projectLocation: data?.location,
    creditPeriod: data?.creditPeriod,
    rfqDetails: data?.description,
    indentId: data?.indentId ?? "",
  });

  const user = auth?.user;
  const entityId = user?.userEntity?.entityId;

  const updateRfqFormValues = (updatedValues) => {
    setRfqFormValues(updatedValues);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    // handle form submission here
    // Find the selected project
    const selectedProject = projects.find(
      (project) => project._id === values.projectName
    );
    const formValue = {
      ...values,
      projectName: selectedProject.name, // Replace projectName with selected project's name
      projectId: selectedProject._id,
    };

    dispatch(editRfq({ values: formValue, selectedFilesBase }));
    setSubmitting(false);
    toggleTab("Overview");
  };
  useEffect(() => {
    dispatch(fetchRfqProjects(entityId));
  }, []);
  return (
    <SidePanel>
      <Header title="Edit RFQ" />
      <div className="pt-20 p-6 flex flex-col gap-y-6 w-full min-h-svh bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF] ">
        <BreadCrumbs
          paths={[
            { title: "RFQs", onClick: () => toggleTab("Overview") },
            { title: "Edit RFQs", onClick: () => {} },
          ]}
        />
        <Formik
          enableReinitialize
          initialValues={rfqFormValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form className="flex flex-col gap-y-4">
            <GeneralInformation projects={projects} />
            <div className="rounded-lg shadow-md bg-white mb-20">
              <RfqDetailsSection
                selectedFilesBase={selectedFilesBase}
                setSelectedFilesBase={setSelectedFilesBase}
                fieldName="rfqDetails"
                updateFormValues={updateRfqFormValues}
              />
            </div>

            <div className="p-6 flex justify-end items-center gap-4 rounded-lg shadow-md bg-white w-full fixed bottom-0 left-0">
              <button
                className="px-4 py-2"
                onClick={() => toggleTab("Overview")}
                type="button"
              >
                Cancel
              </button>
              <button
                className="bg-blue-700 hover:bg-blue-800 text-white rounded-lg px-4 py-2"
                type="submit"
              >
                Save
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </SidePanel>
  );
};

export default EditRfqs;
