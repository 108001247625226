const filterData = [
  {
    key: "tags",
    title: "Tags",
    type: "search checkbox",
  },
  {
    key: "category",
    title: "Category",
    type: "checkboxes",
    options: [
      "Supplier",
      "Contractor",
      "Consultant",
      "EPC Contractor",
      "Real Estate Developer",
      "Transporter",
      "Labour Contractor",
    ],
  },
  {
    key: "gstTurnOverRange",
    title: "GST Turnover",
    type: "radio",
    options: [
      "Less than 40 Lacs",
      "Greater than 40 Lacs",
      "Greater than 1.5 Crores",
      "Greater than 5 Crores",
      "Greater than 25 Crores",
      "Greater than 100 Crores",
      "Greater than 500 Crores",
    ],
  },
  {
    key: "gstStatus",
    title: "GST Status",
    type: "radio",
    options: ["ACTIVE", "INACTIVE", "CANCELLED"],
    // options: ["Active", "Inactive", "Cancelled"],
  },
  { key: "location", title: "Locations", type: "search radio" },
  // {
  //   key: "contractsAwarded",
  //   title: "Previously Awarded",
  //   type: "range",
  //   options: ["0", "10", "25", "50"],
  // },
];

export default filterData;
