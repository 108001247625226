import React from "react";
import { Field, ErrorMessage } from "formik";

const ButtonGroup = ({ name, options }) => {
  return (
    <div role="group" className="mb-4 col-span-2">
      <label className="block text-sm font-medium text-gray-700" id={name}>
        {/* {name.charAt(0).toUpperCase() + name.slice(1)}<span className="text-red-500">*</span> */}
        Requirement Type<span className="text-red-500">*</span>
      </label>
      <div className="flex">
        {options.map((option, index) => (
          <Field type="radio" name={name} value={option} key={index}>
            {({ field }) => (
              <button
                {...field}
                type="button"
                className={`px-4 py-2 border border-gray-300 flex items-center gap-x-2 rounded-none ${
                  index === 0 ? "rounded-l-md" : ""
                } ${index === options.length - 1 ? "rounded-r-md" : ""}`}
                style={{
                  backgroundColor: "#F9FAFB",
                  borderColor: "#D1D5DB",
                }}
                onClick={() =>
                  field.onChange({ target: { value: option, name } })
                }
              >
                {field.checked ? (
                  <div
                    className="p-2 rounded-full border-solid border-gray-300 bg-blue-700"
                    style={{ borderWidth: "0.5px" }}
                  />
                ) : (
                  <div
                    className="p-2 rounded-full border-solid border-gray-300"
                    style={{ borderWidth: "0.5px" }}
                  />
                )}
                {option}
              </button>
            )}
          </Field>
        ))}
      </div>
      <ErrorMessage
        name={name}
        component="div"
        className="text-red-500 text-xs"
      />
    </div>
  );
};

export default ButtonGroup;
