import {
  COMPANY_DIR_ADD_TAGS_SUCCESS,
  COMPANY_DIR_ADD_VENDORS_SUCCESS,
  COMPANY_DIR_CLEAR_FILTER,
  COMPANY_DIR_EDIT_VENDORS_SUCCESS,
  COMPANY_DIR_FETCH_VENDORS,
  COMPANY_DIR_FILTER_VENDOR,
  COMPANY_DIR_FILTER_VENDOR_FAILURE,
  COMPANY_DIR_FILTER_VENDOR_SUCCESS,
  COMPANY_DIR_SEARCH_VENDORS,
  COMPANY_DIR_SEARCH_VENDORS_FAILURE,
  COMPANY_DIR_SEARCH_VENDORS_SUCCESS,
} from "actions/companyDirectoryActions";
import produce from "immer";

const initialState = {
  error: false,
  message: "",
  searchKey: "",
  appliedFilters: {},
  vendors: [],
  totalCount: null,
  currentPage: null,
  totalPages: null,
  applyTagsCount: 0, //use this field for to update the tags so filter section trigger and they update the tags value
  loading: false,
};

const companyDirectory = (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_DIR_SEARCH_VENDORS:
    case COMPANY_DIR_FETCH_VENDORS:
    case COMPANY_DIR_FILTER_VENDOR: {
      const payload = action?.payload;
      return produce(state, (draft) => {
        draft.searchKey = payload?.searchKey || "";
        draft.appliedFilters = payload?.filterData || {};
        draft.vendors = [];
        draft.totalCount = null;
        draft.currentPage = null;
        draft.totalPages = null;
        draft.loading = true;
      });
    }
    case COMPANY_DIR_SEARCH_VENDORS_SUCCESS:
    case COMPANY_DIR_FILTER_VENDOR_SUCCESS: {
      const vendor = action?.payload?.vendors;
      return produce(state, (draft) => {
        draft.vendors = vendor?.vendors;
        draft.totalCount = vendor?.totalCount;
        draft.currentPage = vendor?.currentPage;
        draft.totalPages = vendor?.totalPages;
        draft.loading = false;
      });
    }
    case COMPANY_DIR_ADD_VENDORS_SUCCESS: {
      console.log("Adding vendor", action);
      return produce(state, (draft) => {
        draft.vendors = [action?.payload, ...state?.vendors];
      });
    }
    case COMPANY_DIR_EDIT_VENDORS_SUCCESS: {
      console.log("edit vendor", action);
      const newVendor = action.payload;
      const filteredVendors = state?.vendors?.map((vendor) => {
        if (vendor?.vendorId === newVendor.vendorId) return newVendor;
        return vendor;
      });
      return produce(state, (draft) => {
        draft.vendors = filteredVendors;
      });
    }
    case COMPANY_DIR_SEARCH_VENDORS_FAILURE:
    case COMPANY_DIR_FILTER_VENDOR_FAILURE: {
      return produce(state, (draft) => {
        draft.vendors = [];
        draft.totalCount = null;
        draft.currentPage = null;
        draft.totalPages = null;
        draft.loading = false;
      });
    }
    case COMPANY_DIR_CLEAR_FILTER: {
      return produce(state, (draft) => {
        draft.appliedFilters = {};
      });
    }
    case COMPANY_DIR_ADD_TAGS_SUCCESS: {
      return produce(state, (draft) => {
        draft.applyTagsCount = draft.applyTagsCount + 1;
        draft.vendors = state?.vendors?.map(
          (obj) =>
            action?.payload?.find(
              (replace) => replace?.vendorId === obj?.vendorId
            ) || obj
        );
      });
    }
    default: {
      return state;
    }
  }
};

export default companyDirectory;
