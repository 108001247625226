import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, Modal, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { dateFormatter } from "utils/formatUtils";
import ReactHtmlParser from "react-html-parser";
import { Grade } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { sendAckLead } from "actions/leadsAction";
import { useDispatch, useSelector } from "react-redux";
import VerifiedIcon from "@mui/icons-material/Verified";
import { display } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import {
  CREATE_CRM_LEAD_URL,
  GET_CRM_LEAD_BY_PHONE_NUMBER,
} from "config/services";
import { updateCrmId } from "actions/authActions";
import { addLeadActivity } from "actions/crmAction";
import axios from "utils/axios";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const LeadsDataView = ({ rfq, userProfile }) => {
  const business = useSelector((state) => state.business);
  const supplierId = business?.profile?.supplierId;
  const viewById = userProfile?.userId;
  const viewByName = userProfile?.name;
  const acknowledgeDate = new Date();
  const dispatch = useDispatch();
  const navigte = useNavigate();
  const [renderPage, setRenderPage] = useState(false); // WARNING : dont use for another perpose: this is useState use for the remove bug of double useeffect run
  const [showRequsterData, setShowRequsterData] = useState(false);
  const typographyRef = useRef(null);

  const attachmentsData = rfq?.selectedFilesBase ? rfq?.selectedFilesBase.map((item) => {
    const lastIndex = item.Name.lastIndexOf('.'); // this give use string after last . so we find what type of attechment is this
    const path = lastIndex !== -1 ? item.Name.substring(lastIndex + 1) : null; // we get the type of file
    return { ...item, Path: path };
  }) : [];

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const isSm = useMediaQuery(theme.breakpoints.up("sm"), {
    defaultMatches: true,
  });
  const startDate = dateFormatter(rfq.workStartDate);
  const endDate = dateFormatter(rfq.workEndDate);

  const userCrmLeadId = userProfile?.crmLeadId;
  const [crmLeadIdOfUser, setCrmLeadIdOfUser] = useState(userCrmLeadId);


  // const isSupplierSubscribed = business.profile?.subscriptionType === "PREMIUM"  // we change the flow so we coomit this condition
  // give isSupplierSubscribed value is true for now  
  const isSupplierSubscribed = true
  const isPremium = business.profile?.subscriptionType === "PREMIUM"

  // useEffect(() => {
  //   if (!userCrmLeadId || userCrmLeadId == null) {
  //     const urlForCrmByMobile = GET_CRM_LEAD_BY_PHONE_NUMBER.concat(userProfile?.mobile);
  //     axios
  //       .get(urlForCrmByMobile)
  //       .then((responseD) => {
  //         setCrmLeadIdOfUser(responseD.data[0].ProspectID);
  //         const crmLeadId1 = responseD.data[0].ProspectID;
  //         dispatch(updateCrmId(userProfile?.userId, crmLeadId1));
  //       })
  //       .catch((error) => {
  //         // console.log("ERROR-LEAD CREATED",error)
  //         const payload = [
  //           {
  //             Attribute: "EmailAddress",
  //             Value: userProfile?.email,
  //           },
  //           {
  //             Attribute: "FirstName",
  //             Value: userProfile?.name,
  //           },
  //           {
  //             Attribute: "Phone",
  //             Value: userProfile?.mobile,
  //           },
  //         ];

  //         axios.post(CREATE_CRM_LEAD_URL, payload).then((crmResponse) => {
  //           //   console.log(":CREATE_CRM_LEAD: Response",crmResponse)
  //           setCrmLeadIdOfUser(crmResponse?.data.Message.Id);
  //           const crmLeadId2 = crmResponse?.data.Message.Id;
  //           dispatch(updateCrmId(userProfile?.userId, crmLeadId2));
  //         });
  //       });
  //   }
  // }, [])

  const activityData = {
    RelatedProspectId: crmLeadIdOfUser,
    ActivityEvent: 216,
    ActivityNote: "Requirement Acknowledgement",
    Fields: [
      {
        SchemaName: "mx_Custom_1",
        Value: "ACKNOWLEDGED",
      },
      {
        SchemaName: "mx_Custom_2",
        Value: rfq.rfqId,
      },
    ],
  };

  const handleClickShowRequsterData = async () => {
    // const rfqsStatus = rfq.suppliers[0].status
    setShowRequsterData(true);
    // dispatch(sendAckLead(supplierId, rfq.rfqId, viewByName, viewById, acknowledgeDate));

    // dispatch(addLeadActivity(activityData))

  };

  // const handleClickShowAttachment = (Link)=>{

  // }

  useEffect(() => {
    const rfqSuppliers = rfq?.suppliers;
    const rfqsStatus = rfqSuppliers.find(
      (supplier) => supplier?.supplierId == supplierId
    );
    //this below code is creat for auto accept lead when user come on page 1st time
    if (rfqsStatus?.status != "ACKNOWLEDGED" && isSupplierSubscribed) {
      if (renderPage) {
        handleClickShowRequsterData()
      } else {
        setRenderPage(true)
      }
    }
    // console.log("thius is for only tsest", renderPage);
  }, [renderPage]);

  const buttonsData = [ // This data is created because onClick triggers only one function, which navigates. If you change onClick, then modify this data accordingly.
    { heading: "View all Leads", path: "/leads" },
    // { heading: "Close Lead", path: "/user-dashboard" },
  ]
  const RequirementDetailsData = [
    { heading: "Supply by :", value: startDate ? startDate : "NA" },
    { heading: "Project Location :", value: rfq.location ? rfq.location : "NA" },
    { heading: "Credit Period :", value: rfq.creditPeriod ? rfq.creditPeriod + " days" : "NA" },
  ]
  const RequesterDetailsData = [
    { heading: "Email :", value: rfq.requestedBy ? rfq.requestedBy : "NA" },
    { heading: "Contact :", value: rfq?.requesterMobile ? rfq?.requesterMobile : "NA" },

  ]
  const SiteEngineerData = [
    { heading: "Name :", value: rfq.siteEngineerName ? rfq.siteEngineerName : "NA" },
    { heading: "Contact :", value: rfq.siteEngineerContact ? rfq.siteEngineerContact : "NA" },
  ]
  const CloseLeadFunction = (path) => {
    // window.location.reload();
    navigte(path)
  };
  const [openUpgradeWarning, setOpenUpgradeWarning] = React.useState(false);
  const [thankYouPopUp, setThankYouPopUp] = useState(false);


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMd ? 355 : 400,
    bgcolor: 'background.paper',
    border: 'none',
    outline: "none",
    boxShadow: 24,
    borderRadius: "15px",
    p: isMd ? 3 : 4,
  };
  const clickedUpgradeOption = () => {
    setOpenUpgradeWarning(false)
    setThankYouPopUp(true)
    axios.post("/api/auth/demo/request", {
      name: userProfile?.name,
      number: userProfile?.mobile,
      interestShown: "subscription request",
    });
    setTimeout(() => { setThankYouPopUp(false) }, [5000])
  }
  const handleClose = () => {
    setOpenUpgradeWarning(false);
  };
  useEffect(() => {
    if (!isSupplierSubscribed) {
      setOpenUpgradeWarning(true);
    }
  }, [])
  return (
    <Grid
      container
      sx={{
        backgroundColor: "fff",
        padding: isSm ? "24px" : "16px",
        marginBottom: "auto",
      }}
    >
      <Grid item xs={12} >

        <Typography fontSize={"22px"}>
          Business Lead
        </Typography>

        {isSm && <Grid item xs={12} display="flex" alignItems="center" gap="18px" >

          <Typography fontSize="18px" fontWeight={500} >Business Inquiry {rfq?.userBusinessName ? "from " + rfq?.userBusinessName : ""}.</Typography>
          {buttonsData.map((data) => <Button
            variant="outlined"
            sx={{
              color: "black",
              borderRadius: "7px",
              border: "1px solid #D5D9D9",
              width: "124px",
              height: "30px",
              boxShadow: " 0px 2px 5px 0px rgba(213, 217, 217, 0.50)",
            }}
            onClick={() => { CloseLeadFunction(data.path) }}
          >
            {data.heading}
          </Button>)}
        </Grid>}

        {!isMd && <Grid display={"flex"} color={"#46AC9E"} gap={"10px"} alignItems={"center"} marginBottom="12px">
          <Typography gutterBottom variant="h5" mb={0} >
            Viewed
          </Typography>
          <VerifiedIcon fontSize='small' />
        </Grid>
        }
        <Grid container xs={12} gap="26px" border={!isSm && "1px solid #757373"} borderRadius="10px" marginBottom="32px" padding={!isSm && "10px"} >

          <Grid item
            xs={12}
            md={8.5}
            order={{ xs: 2, md: 1 }}
          >
            {isMd && <Grid display={"flex"} color={"#46AC9E"} gap={"10px"} alignItems={"center"} marginBottom="12px">
              <Typography gutterBottom variant="h5" mb={0} >
                Viewed
              </Typography>
              <VerifiedIcon fontSize='small' />
            </Grid>}
            <Grid
              padding={isSm ? "12px" : 0}
              sx={{ border: isSm && "1px solid #757373", borderRadius: "15px" }}
            >
              <Typography fontSize={"20px"}>
                Requirement Description
              </Typography>

              {isSupplierSubscribed ? <Grid xs={12} sx={{
                padding: "12px", border: "1px solid #757373", borderRadius: "5px", margin: "8px 0 24px",
              }}>
                <Typography
                  fontSize="16px"
                  style={{
                    overflow: "auto",
                    marginBottom:"10px"
                  }}
                  ref={typographyRef}
                >
                  {rfq.description ? ReactHtmlParser(rfq.description) : "N/A"}
                </Typography>
                <Grid display="flex" flexWrap="wrap" gap="12px">
                  {attachmentsData.length > 0 && attachmentsData.map((data) => <Link
                    href={data.Content}
                    color="inherit"
                    underline="none"
                    target="_blank"
                  > <Box
                    sx={{
                      backgroundColor: "#EAEAEA",
                      paddingX: "10px",
                      // height: "22px",
                      borderRadius: "8px",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px"
                    }}
                  >
                      <img src={`/assets/icons/${data.Path}.png`} height="24px" alt="png" />
                      <Grid
                        sx={{
                          maxWidth: "140px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data.Name}
                      </Grid>
                    </Box></Link>)

                  }
                </Grid>
              </Grid>
                :
                <Grid xs={12} sx={{
                  padding: "12px", border: "1px solid #757373", borderRadius: "5px", margin: "8px 0 24px",
                  position: 'relative',
                  overflow: 'hidden',
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    backdropFilter: !isSupplierSubscribed ? 'blur(4px)' : 'none',
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 1,
                  },
                }}>
                  <Typography
                    fontSize="16px"
                    style={{
                      overflow: "auto",
                    }}
                    ref={typographyRef}
                  >
                    {rfq.description ? ReactHtmlParser(rfq.description) : "N/A"}
                  </Typography>
                  <Grid display="flex" flexWrap="wrap" gap="12px">
                    {attachmentsData.length > 0 && attachmentsData.map((data) => <Link
                      href={data.Content}
                      color="inherit"
                      underline="none"
                      target="_blank"
                    > <Box
                      sx={{
                        backgroundColor: "#EAEAEA",
                        paddingX: "10px",
                        height: "32px",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px"
                      }}
                    >
                        <img src={`/assets/icons/${data.Path}.png`} height="24px" alt="png" />
                        <Grid
                          sx={{
                            maxWidth: "140px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {data.Name}
                        </Grid>
                      </Box></Link>)

                    }
                  </Grid>
                </Grid>
              }
              <Typography fontSize="20px">
                Requirement Details
              </Typography>

              <Grid xs={12} sx={{ border: "1px solid #757373", padding: "12px", display: "flex", flexDirection: "column", gap: "8px", marginTop: "12px", borderRadius: "5px" }}>
                {RequirementDetailsData.map((data) =>
                  <Typography
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {data.heading}&nbsp;&nbsp;&nbsp;
                    <Typography sx={{ fontWeight: "600" }}>
                      {data.value}
                    </Typography>
                  </Typography>)
                }
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12} md order={{ xs: 1, md: 2 }}>

            <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>Requester Details</Typography>

            <Grid sx={{
              padding: "12px", border: " 1px solid #757373", borderRadius: "10px", marginTop: "12px",
              position: 'relative',
              overflow: 'hidden',
              '&:after': {
                content: '""',
                position: 'absolute',
                top: 0,
                backdropFilter: !isSupplierSubscribed ? 'blur(4px)' : 'none',
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
              },
            }} >
              {RequesterDetailsData.map((data) => data.value !== "NA" && <Grid sx={{ marginY: "8px", display: "flex" }}>
                <Typography>
                  {data.heading}
                </Typography>
                <Typography fontWeight={600} marginLeft="5px">
                  {data.value}
                </Typography>
              </Grid>
              )}
              <Grid>

              </Grid>

            </Grid>

            {(SiteEngineerData[0].value !== "NA" || SiteEngineerData[1].value !== "NA") && <> <Typography style={{ fontSize: "18px", fontWeight: "bold", marginTop: "10px" }}>Site Engineer Details</Typography>

              <Grid sx={{
                padding: "12px", border: " 1px solid #757373", borderRadius: "10px",
                marginTop: "12px",
                position: 'relative',
                overflow: 'hidden',
                '&:after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  backdropFilter: !isSupplierSubscribed ? 'blur(4px)' : 'none',
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 1,
                },
              }} >
                {SiteEngineerData.map((data) => data.value !== "NA" &&
                  <Grid sx={{ marginY: "8px", display: "flex" }}>
                    <Typography >
                      {data.heading}
                    </Typography>
                    <Typography fontWeight={600} marginLeft="5px" >
                      {data.value}
                    </Typography>
                  </Grid>
                )}
                <Grid>

                </Grid>

              </Grid>
            </>}

            <Typography sx={{
              color: "#3963AB",
              fontSize: "14px",
              marginTop: "10px"
            }}>
              Call the buyer directly at above contact details to share and discuss your quotation.
            </Typography>

          </Grid>

          {!isSm && <Grid item xs={12} order={{ xs: 3, }}>
            <Grid xs={12} sx={{ display: "flex", justifyContent: "space-between", padding: "8px" }} onClick={() => { navigte("/leads") }}>
              <Typography sx={{ textDecoration: "underline", color: "#3963AB", }}>
                View All Leads
              </Typography>
              <KeyboardArrowRightIcon />
            </Grid>
            {/* <Grid xs={12} sx={{ display: "flex", justifyContent: "space-between", padding: "8px" }} onClick={() => { navigte("/user-dashboard") }}>
              <Typography sx={{ textDecoration: "underline", color: "#3963AB", }}>
                Close
              </Typography>
              <KeyboardArrowRightIcon />
            </Grid> */}
          </Grid>}
        </Grid>

      </Grid>

      <Dialog
        open={openUpgradeWarning}
        onClose={handleClose}
      >
        <Button onClick={handleClose} style={{ position: 'absolute', top: 0, right: 0, padding: 0, color: "black", fontWeight: 500, minWidth: "28px" }}>
          X
        </Button>
        <DialogContent>
          <Typography sx={{ paddingTop: "6px" }}>
            Upgrade your plan to view the lead
          </Typography>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} sx={{ color: "gray" }}>close</Button> */}
          <Grid onClick={clickedUpgradeOption} sx={{ background: "#4CF0C0", color: "black", padding: "4px", cursor: "pointer", borderRadius: "12px" }}>
            Book a Call
          </Grid>

        </DialogActions>
      </Dialog>

      <Modal
        open={thankYouPopUp}
        onClose={() => setThankYouPopUp(false)}
      >
        <Box sx={style}>
          <Typography sx={{ fontSize: "24px", fontWeight: 600, textAlign: "center" }}>Thank You !</Typography>
          <Typography>We have received your request and we will get back to you shortly.</Typography>
          <Typography>For more info call us at <span style={{ fontWeight: 400, color: "#3963AB", textDecoration: "underline" }}>9503339814</span>.</Typography>
        </Box>
      </Modal>
      {!isPremium && <Typography sx={{ color: "#3963AB", fontSize: isSm ? "16px" : "12px" }}>
        Note : This free trial period to view leads will end on 30 September 2024. Kindly upgrade to premium.
      </Typography>}
    </Grid>
  );
};

export default LeadsDataView;
