const fields = [
  {
    key: "businessName",
    title: "Business Name",
    mappedField: "businessName",
    component: "Drawer",
  },
  // {
  //   key: "businessEmail",
  //   title: "Business Email",
  //   mappedField: "businessEmail",
  //   component: "Text",
  // },
  // {
  //   key: "businessMobileNumber",
  //   title: "Business Number",
  //   mappedField: "businessMobileNumber",
  //   component: "Text",
  // },
  {
    key: "serviceOffered",
    title: "Service Offered",
    mappedField: "serviceOffered",
    component: "ServicePopup",
  },
  {
    key: "domains",
    title: "Sector",
    mappedField: "domains",
    component: "ViewMore",
  },
  {
    key: "projectNames",
    title: "Projects",
    mappedField: "projectNames",
    component: "ViewMore",
  },
  {
    key: "annualTurnOver",
    title: "GST Turnover",
    mappedField: "annualTurnOver",
    component: "Text",
  },
  {
    key: "gstCertified",
    title: "GST Status",
    mappedField: "gstCertified",
    component: "Text",
  },
  {
    key: "gstNumber",
    title: "GST Number",
    mappedField: "gstNumber",
    component: "Text",
  },
  // {
  //   key: "businessType",
  //   title: "Business Type",
  //   mappedField: "businessType",
  //   component: "Text",
  // },
];

export default fields;
