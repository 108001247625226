import React from "react";
import { WhyWeHere } from "./components/WhyWeHere";
import { NeevayToday } from "./components/NeevayToday";
import { OurLeadership } from "./components/OurLeadership";
import { OurTeam } from "./components/OurTeam";
import { FloatingButton, GlobalNavbar } from "views/Home 3.0/components";
import FooterNew from "views/Home/components/FooterNew";
import { OurBackers } from "./components/OurBackers";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

export const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     <Helmet>
        <title>Neevay - About Us</title>
      </Helmet>
      <GlobalNavbar />
      <WhyWeHere />
      <NeevayToday />
      <OurLeadership />
      <OurBackers/>
      {/* <OurTeam /> */}
      <FooterNew />
      <FloatingButton />
    </>
  );
};
