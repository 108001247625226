import BreadCrumbs from "views/Rfqs/Components/BreadCrumbs";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Toast, Tooltip } from "flowbite-react";
import Overview from "views/Rfqs/Components/rfqsDetails/Overview";
import Vendors from "views/Rfqs/Components/rfqsDetails/Vendors";
import Button from "../../components/Button";
import EditRfqs from "./Components/EditRfqs";
import Popup from "../../components/Popup";
import Select from "../../components/Select";
import { useDispatch, useSelector } from "react-redux";
import {
  ChangeStatus,
  closeRfq,
  closeWithoutSelectingVendor,
  deleteSingleRfq,
  fetchRfq,
} from "actions/rfqActions";
import SelectVendorsSlider from "./Components/SelectVendorsSlider";
import ResendRfq from "./Components/rfqsDetails/ResendRfq";
import SidePanel from "layouts/SidePanel";
import ResendBulkRfq from "./Components/rfqsDetails/ResendBulkRfq";
import * as Yup from "yup";
import { Snackbar } from "@mui/material";
import WarningPopup from "components/WarningPopup";
import Loading from "components/Loading";

const RfqsDetails = () => {
  const { rfqId, selectedTab } = useParams();
  const dispatch = useDispatch();
  const rfqData = useSelector((state) => state.rfq);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(selectedTab ?? "Overview");
  const [openSlider, setOpenSlider] = useState(false);
  const [openAddVendorDropBox, setOpenAddVendorDropBox] = useState(false);
  const [isRfqClosing, setIsRfqClosing] = useState(false);
  const [sliderName, setSliderName] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [rfqClosingOptions, setRfqClosingOptions] = useState({});
  const [rfqClosingErrors, setRfqClosingErrors] = useState({});
  const [rfqError, setRfqError] = useState({});
  const [isBulkReinvite, setIsBulkReinvite] = useState({});
  const [hasInvitedAll, setHasInvitedAll] = useState(false);
  const [rfqClosingStep, setRfqClosingStep] = useState(1);
  const [closeMessage, setCloseMessage] = useState("");
  const [closeSnackbar, setCloseSnackbar] = useState(false);
  const [closeWithoutVendor, setCloseWithoutVendor] = useState(false);
  const [rfqDeleteWaringPopup, setRfqDeleteWaringPopup] = useState(false);
  const [rfqDeleteRfqId, setRfqDeleteRfqId] = useState(null);
  const statuses = ["Close", "Cancel", "Hold", "Open"];
  const [selectedStatus, setSelectedStatus] = useState("");
  const [changeStatusDropdownIsOpen, setChangeStatusDropdownIsOpen] =
    useState(false);
  const [changeStatus, setChangeStatus] = useState({
    currentState: "",
    changeStatus: "",
    alert: false,
  });
  const [transitionStyle, setTransitionStyle] = useState({
    position: "fixed",
    top: "80px",
    left: "-100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    transition: "left 1s ease-out",
  });

  const tabs = ["Overview", "Vendors"];
  const user = auth?.user;
  const userId = user?.userId;
  const name = user?.name;
  const entityId = user?.userEntity?.entityId;
  const entityName = user?.userEntity?.entityName;
  const directoryName = user?.userEntity?.directoryName;
  const teamUserIds = user?.teamMembers?.map((member) => member.userId);
  const loading = rfqData?.loading;
  const data = rfqData?.rfq;
  const toggleTab = (tab) => {
    setActiveTab(tab);
    setIsBulkReinvite({});
    setHasInvitedAll(false);
  };

  const toggleDropdown = () =>
    setChangeStatusDropdownIsOpen(!changeStatusDropdownIsOpen);

  const handleStatusChange = async (status) => {
    const changeStatus = status === "Cancel" ? "CANCELED" : status;
    setSelectedStatus(status);
    const payload = {
      rfqId,
      entityId,
      statusToChange: changeStatus.toUpperCase(),
      actionBy: {
        userId,
        name,
      },
    };
    const response = await dispatch(ChangeStatus({ payload }));
    setChangeStatus({
      currentState: selectedStatus === "Canceled" ? "cancel" : selectedStatus,
      changeStatus: status,
      alert: true,
    });
    setTimeout(() => {
      setChangeStatus({ currentState: "", changeStatus: "", alert: false });
    }, 6000);
    setChangeStatusDropdownIsOpen(false);
  };

  const toggleAddVendorBtn = () => {
    setOpenAddVendorDropBox(!openAddVendorDropBox);
  };

  const formatStatus = (status) => {
    if (status) {
      return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    }
    return "";
  };

  const openResendRfq = (supplier) => {
    setSelectedSupplier(supplier);
    toggleTab("resendRfq");
  };

  const openRfqDeletePopup = (rfqId) => {
    setRfqDeleteWaringPopup(true);
    setRfqDeleteRfqId(rfqId);
  };
  const closeRfqDeletePopup = () => {
    setRfqDeleteWaringPopup(false);
    setRfqDeleteRfqId(null);
  };
  const handleRfqDelete = async () => {
    const response = await dispatch(
      deleteSingleRfq({
        rfqId: rfqDeleteRfqId,
        userId: data?.requesterId,
        actionBy: { userId: user?.userId, name: user?.name },
      })
    );
    closeRfqDeletePopup();
  };

  const addBulkReinvite = (value, supplierId) => {
    setIsBulkReinvite((prev) => {
      return { ...prev, [supplierId]: value };
    });
    setHasInvitedAll(false);
  };

  const inviteReinviteAll = (value, supplierList) => {
    const newBulkReinvite = {};
    supplierList.forEach((supplier) => {
      newBulkReinvite[supplier] = value;
    });
    setIsBulkReinvite(newBulkReinvite);
    setHasInvitedAll(value);
  };

  const handleRfqClosingOptions = (key, field, value, index) => {
    setRfqClosingOptions((prevOptions) => {
      const newOptions = { ...prevOptions };

      if (field === "remark") {
        newOptions[key] = {
          ...newOptions[key],
          [field]: value,
        };
      } else {
        // Create a deep copy of closingDetails array
        const newClosingDetails = [...newOptions[key]["closingDetails"]];
        // Create a copy of the specific object to be updated
        newClosingDetails[index] = {
          ...newClosingDetails[index],
          [field]: value,
        };

        newOptions[key] = {
          ...newOptions[key],
          closingDetails: newClosingDetails,
        };
      }
      return newOptions;
    });

    if (rfqClosingErrors[key]) {
      setRfqClosingErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[key];
        return newErrors;
      });
    }
  };

  const handleOpenEditAwardees = () => {
    setChangeStatusDropdownIsOpen(false);
    const isEveryoneInvited = data?.suppliers.every(
      (supplier) => supplier?.state === "INVITED"
    );
    const selectedAwardeeSuppliers = data?.suppliers
      .filter((item) => item.closingDetails.length >= 1)
      .map((item) => ({
        businessName: item.businessName,
        isNeevayVendor: item.isNeevayVendor,
        supplierId: item.supplierId,
        awardedBy: name,
        userId: userId,
        date: new Date().toLocaleDateString("en-CA"),
        rank: item?.quotationDetails?.rank,
        closingDetails: item?.closingDetails?.map((closingDetail) => {
          return {
            ...closingDetail,
            poDate: new Date(closingDetail?.poDate).toLocaleDateString("en-CA"),
          };
        }),
        remark: item?.closingDetails[0]?.remark,
      }));
    if (selectedAwardeeSuppliers?.length >= 1) {
      setRfqClosingOptions(() =>
        selectedAwardeeSuppliers.reduce((acc, current) => {
          acc[current.supplierId] = current;
          return acc;
        }, {})
      );
    }
    if (!isEveryoneInvited) {
      setCloseSnackbar(true);
      setCloseMessage("Please invite all vendors before closing the RFQ");
      return;
    }
    const isAllQuotationsReceived = data?.suppliers.every(
      (supplier) => supplier?.quotationDetails
    );
    if (!isAllQuotationsReceived) {
      setCloseSnackbar(true);
      setCloseMessage("Please wait for all quotations to be received");
      return;
    }
    setIsRfqClosing(true);
  };

  const errorField = (msg) => (
    <span className="text-red-500 text-sm">{msg}</span>
  );
  useEffect(() => {
    if (rfqData?.error) {
      setRfqError({ error: true, message: rfqData.errorMassage });
      setTimeout(() => {
        setRfqError({ error: false, message: "" });
      }, 6000);
    }

    setSelectedStatus(formatStatus(rfqData?.rfq?.status));
  }, [rfqData]);
  useEffect(() => {
    if (changeStatus?.alert) {
      setTransitionStyle((prevStyle) => ({
        ...prevStyle,
        left: "50%",
        transform: "translateX(-50%)",
      }));
    }
  }, [changeStatus?.alert]);

  const renderTabContent = () => {
    switch (activeTab) {
      case "Overview":
        return (
          <Overview
            data={data}
            hasAwarded={data?.suppliers.some(
              (supplier) => supplier?.closingDetails?.length >= 1
            )}
            handleOpenEditAwardees={handleOpenEditAwardees}
          />
        );
      case "Vendors":
        return (
          <Vendors
            loading={loading}
            vendorData={data}
            openResendRfq={openResendRfq}
            rfqStatus={data?.status}
            reInviteFunctions={{
              inviteReinviteAll,
              isBulkReinvite,
              addBulkReinvite,
              hasInvitedAll,
            }}
          />
        );
      case "Correspondence":
        return <></>;
      default:
        return <Overview />;
    }
  };

  const getTabButtons = () => {
    if (activeTab === "Vendors") {
      return (
        <>
          {/* <button className="bg-gray-200 flex justify-between items-center gap-3 text-gray-900 px-4 py-2 rounded-md hover:bg-gray-600">
            Invite
            <svg
              width="13"
              height="6"
              viewBox="0 0 13 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.5 0L12.1292 5.25L0.870835 5.25L6.5 0Z"
                fill="#111928"
              />
            </svg>
          </button> */}
          <div className="relative">
            {(data?.status === "OPEN" || data?.status === "DRAFT") && (
              <div className="flex">
                <Button
                  variant={
                    Object.values(isBulkReinvite).some((val) => val)
                      ? "primary"
                      : "disabled"
                  }
                  className="mr-2"
                  onClick={() => {
                    const selectedSupplierIds = Object.keys(
                      isBulkReinvite
                    ).filter((supplierId) => isBulkReinvite[supplierId]);
                    const supplier = data?.suppliers.filter((supplier) =>
                      selectedSupplierIds.includes(supplier.supplierId)
                    );
                    setSelectedSupplier(supplier);
                    toggleTab("resendRfq");
                  }}
                  title={
                    Object.values(isBulkReinvite).some((val) => val)
                      ? ""
                      : "Select at least one vendor to reinvite"
                  }
                >
                  Reinvite
                </Button>
                <button
                  className="bg-primary-700 text-white px-4 py-2 rounded-md hover:bg-primary-800 flex justify-between items-center gap-3"
                  onClick={toggleAddVendorBtn}
                >
                  <p className=" text-white text-xl">+</p>
                  {data?.suppliers?.length >= 1
                    ? ` Add More Vendors`
                    : "Add Vendor"}
                </button>
              </div>
            )}
            {/* // up dropdown of company directory and neevay network -----------------------start5 from here */}
            {openAddVendorDropBox && (
              <div className="absolute z-20 rounded-lg py-2 top-[110%] right-0 w-52 bg-white border  border-gray-500">
                <div
                  className="py-2 pl-3 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleSliderOpen("CD")}
                >
                  <p className="text-left font-medium text-gray-900">
                    {/* {directoryName} Directory{" "} */}
                    Internal Vendors
                  </p>
                </div>
                <div
                  className="py-2 pl-3 flex items-center hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleSliderOpen("NN")}
                >
                  <div style={{ maxWidth: "250px", marginRight: "8px" }}>
                    <p className="font-medium text-gray-900">Neevay Marketplace </p>
                  </div>

                  <div
                    style={{
                      borderRadius: "3px",
                      border: "0.5px solid #039400",
                      background: "#E1FFDE",
                      padding: "0 4px", // You can adjust the padding as needed
                    }}
                    className="flex items-center"
                  >
                    <span className="text-black font-inter font-semibold text-xs ">
                      N
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="5"
                      viewBox="0 0 5 5"
                      fill="none"
                    >
                      <circle cx="2.5" cy="2.5" r="2.5" fill="#039400" />
                    </svg>
                  </div>
                </div>
              </div>
            )}
            {/* // up dropdown of company directory and neevay network -----------------------end from here */}
          </div>
        </>
      );
    }
    if (activeTab === "Overview") {
      return (
        <>
          {data?.status === "OPEN" || data?.status === "DRAFT" ? (
            <>
              <button
                type="button"
                className="p-2 rounded-md border border-blue-700 text-blue-700 shadow-sm px-4 py-2 bg-white text-sm font-medium hover:bg-gray-50 focus:outline-none"
                onClick={() => toggleTab("editRfq")}
              >
                Edit
              </button>

              <div className="relative inline-block text-left">
                <div>
                  <button
                    type="button"
                    className="flex justify-center items-center gap-2 w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-blue-700 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none"
                    id="menu-button"
                    aria-expanded={changeStatusDropdownIsOpen}
                    aria-haspopup="true"
                    onClick={toggleDropdown}
                  >
                    Change RFQ Status
                    <div
                      className={changeStatusDropdownIsOpen ? "" : "rotate-180"}
                    >
                      <svg
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_4468_3373)">
                          <path
                            d="M4.98894 3C5.22762 3.00005 5.4565 3.0877 5.62524 3.24367L9.22524 6.57019C9.3112 6.6469 9.37976 6.73867 9.42693 6.84013C9.4741 6.94159 9.49893 7.05072 9.49997 7.16114C9.501 7.27157 9.47823 7.38108 9.43298 7.48328C9.38773 7.58548 9.3209 7.67834 9.2364 7.75642C9.15189 7.8345 9.05141 7.89626 8.9408 7.93807C8.83019 7.97989 8.71168 8.00093 8.59218 7.99997C8.47268 7.99901 8.35458 7.97607 8.24478 7.93248C8.13497 7.8889 8.03566 7.82554 7.95264 7.74611L4.98894 5.00755L2.02524 7.74611C1.8555 7.8976 1.62816 7.98142 1.39218 7.97953C1.1562 7.97763 0.930473 7.89017 0.763606 7.73598C0.596738 7.58179 0.502085 7.37321 0.500035 7.15516C0.497984 6.93711 0.588699 6.72704 0.752641 6.57019L4.35264 3.24367C4.52139 3.0877 4.75027 3.00005 4.98894 3Z"
                            fill="#FD6600"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4468_3373">
                            <rect
                              width="10"
                              height="10"
                              fill="white"
                              transform="matrix(-1 0 0 -1 10 10.5)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </button>
                </div>

                {changeStatusDropdownIsOpen && (
                  <div
                    className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                  >
                    <div className="py-1" role="none">
                      {statuses.map((status) => (
                        <button
                          key={status}
                          className={`block px-4 py-2 text-sm w-full text-left ${
                            selectedStatus === status
                              ? "bg-blue-100 text-gray-900"
                              : "text-gray-700"
                          } hover:bg-blue-700 hover:text-white`}
                          role="menuitem"
                          onClick={() => {
                            if (status != "Close") {
                              handleStatusChange(status);
                            } else {
                              handleOpenEditAwardees();
                            }
                          }}
                        >
                          {status}
                        </button>
                      ))}
                    </div>
                    {rfqError?.error && (
                      <p className="text-red-600 text-sm">
                        {rfqError?.message}
                      </p>
                    )}
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="flex gap-4">
              {/* {data?.status === "CLOSED" && (
                <Button
                  variant="outline"
                  className="w-20"
                  onClick={() => handleOpenEditAwardees()}
                >
                  Edit
                </Button>
              )} */}
              <Button
                variant="primary"
                onClick={() => handleStatusChange("Open")}
              >
                {loading ? <Loading /> : "Reopen"}
              </Button>
            </div>
          )}
          {data?.status != "DELETED" &&
            data?.status != "CLOSED" &&
            (data?.requesterId == userId ||
              teamUserIds.includes(data?.requesterId)) && (
              <Tooltip content="Delete RFQ">
                <button
                  className="ml-2"
                  onClick={() => openRfqDeletePopup(data?.rfqId)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-p79yt4"
                    viewBox="0 0 24 24"
                    focusable="false"
                    width="28px"
                    height="28px"
                    aria-hidden="true"
                  >
                    <path
                      fill="#4B5563"
                      d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"
                    />
                  </svg>
                </button>
              </Tooltip>
            )}
        </>
      );
    }
  };

  const handleSliderOpen = (title) => {
    setOpenAddVendorDropBox(false);
    setOpenSlider(true);
    setSliderName(title);
  };

  const handleSliderClose = () => {
    setOpenSlider(false);
  };

  const checkDuplicatePoNumbers = (arrayOfObjects) => {
    const poNumbers = new Set();
    const duplicatePoNumbers = [];

    for (const obj of arrayOfObjects) {
      for (const detail of obj.closingDetails) {
        if (poNumbers.has(detail.poNumber)) {
          if (!duplicatePoNumbers.includes(detail.poNumber)) {
            duplicatePoNumbers.push(detail.poNumber);
          }
        } else {
          poNumbers.add(detail.poNumber);
        }
      }
    }

    return duplicatePoNumbers;
  };

  useEffect(() => {
    dispatch(fetchRfq({ entityId, rfqId }));
  }, []);

  if (!data || Object.keys(data).length === 0) {
    //Update with condition when rfq of given id is not found
    return loading ? (
      <div className="flex justify-center items-center h-[50vh]">
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-10 w-10"></div>
      </div>
    ) : (
      <div className="flex flex-col gap-y-4 items-center justify-center h-screen w-screen">
        <h1 className="text-2xl text-center">
          Selected RFQ not found. <br /> Please go back to the RFQs page
        </h1>
        <Button>
          <a href="/rfqs">Go to RFQs</a>
        </Button>
      </div>
    );
  }
  if (activeTab === "resendRfq") {
    if (Array.isArray(selectedSupplier)) {
      const mailToEmails = {};
      const ccEmails = [];
      selectedSupplier.forEach((supplier) => {
        if (supplier?.noteDescriptions.length === 0) {
          const mails = supplier.contactDetails.map((contact) => contact.email);
          mailToEmails[supplier.supplierId] = {
            mails,
            businessName: supplier.businessName,
          };
        } else {
          const latestNote =
            supplier?.noteDescriptions[supplier?.noteDescriptions.length - 1];
          mailToEmails[supplier.supplierId] = {
            mails: latestNote.emails,
            businessName: supplier.businessName,
          };
          ccEmails.push(latestNote.ccEmails);
        }
      });
      return (
        <ResendBulkRfq
          rfqId={rfqId}
          loading={loading}
          toggleTab={toggleTab}
          vendorName={data.name}
          mailTo={Object.keys(mailToEmails).length > 0 ? mailToEmails : {}}
          // ccMails={ccEmails.length > 0 ? ccEmails : data.newCcEmails}
          ccMails={data.newCcEmails}
        />
      );
    }
    const latestNote =
      selectedSupplier?.noteDescriptions[
        selectedSupplier?.noteDescriptions.length - 1
      ];
    return (
      <ResendRfq
        rfqId={rfqId}
        selectedSupplier={selectedSupplier}
        loading={loading}
        toggleTab={toggleTab}
        vendorName={data.name}
        mailTo={
          latestNote?.emails ??
          selectedSupplier.contactDetails.map((contact) => contact.email)
        }
        ccMails={latestNote?.ccEmails ?? data.newCcEmails}
        conversations={selectedSupplier?.noteDescriptions ?? []}
      />
    );
  }

  if (activeTab === "editRfq") {
    return <EditRfqs toggleTab={toggleTab} data={data} />;
  }

  return (
    <SidePanel>
      <div className="bg-gradient-to-r from-[#FFF0E4] to-[#E4E7FF] min-h-screen">
        <div className="bg-white sticky top-0 p-4 shadow-xl z-10">
          <h1 className="text-2xl font-bold">{`RFQ ( ${rfqId} ) `}</h1>
        </div>
        {changeStatus?.alert && (
          <div style={transitionStyle}>
            <div
              id="toast-default"
              className="flex gap-4 items-center max-w-xs p-4 text-black bg-gray-300 rounded-lg shadow "
              role="alert"
            >
              <div className="text-sm font-normal">
                {" "}
                {`Change RFQ status ${changeStatus?.currentState.toLowerCase()} to ${changeStatus?.changeStatus.toLowerCase()}`}
              </div>
              <button
                type="button"
                className="bg-gray-300 text-black hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-400 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                data-dismiss-target="#toast-default"
                aria-label="Close"
                onClick={() =>
                  setChangeStatus({
                    currentState: "",
                    changeStatus: "",
                    alert: false,
                  })
                }
              >
                <span className="sr-only">Close</span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
        <div className="bg-white p-4 pb-0 px-9">
          <BreadCrumbs
            paths={[
              {
                title: "RFQs",
                onClick: () => {
                  navigate("/rfqs");
                },
              },
              {
                title: data?.name,
                onClick: () => {
                  navigate(`/rfqs/${rfqId}`);
                },
              },
            ]}
          />
          <div className="flex justify-between">
            <div className="flex mt-4">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  onClick={() => toggleTab(tab)}
                  className={`mr-6 py-2 ${
                    activeTab === tab
                      ? " text-blue-700 border-b-2 font-medium border-blue-700"
                      : "bg-white text-gray-700"
                  }`}
                >
                  {tab}
                </button>
              ))}
            </div>
            <div className="flex items-center gap-3 mb-4">
              {getTabButtons()}
            </div>
          </div>
        </div>
        {data?.rfqId >= 1 && (
          <div className="mt-5 px-6">{renderTabContent()}</div>
        )}
        <Popup
          heading="Close RFQ"
          open={isRfqClosing}
          primaryButton={{
            text: rfqClosingStep === 1 ? "Next" : "Close RFQ",
            variant:
              Object.keys(rfqClosingOptions).length === 0
                ? "disabled"
                : "primary",
            tooltip: "No vendor is selected yet",
          }}
          secondaryButton={
            rfqClosingStep === 2
              ? {
                  text: "Back",
                  onClick: () => setRfqClosingStep(1),
                }
              : {
                  text: "Close without selecting vendor",
                  onClick: () => {
                    setCloseWithoutVendor(true);
                  },
                }
          }
          alignRight
          handleSubmit={async () => {
            if (rfqClosingStep === 1) {
              setRfqClosingStep(2);
              return;
            }
            const rfqClosingSchema = Yup.object().shape(
              Object.keys(rfqClosingOptions).reduce((shape, supplierId) => {
                shape[supplierId] = Yup.object().shape({
                  closingDetails: Yup.array()
                    .of(
                      Yup.object().shape({
                        amount: Yup.number()
                          .typeError("Enter only numbers") // Custom error message for non-numeric input
                          .required("Amount is required"),
                        poNumber: Yup.string().required(
                          "PO Number is required"
                        ),
                        poDate: Yup.string().required(
                          "PO Create Date is required"
                        ),
                      })
                    )
                    .min(1, "Add at least one PODetail"),
                  remark: Yup.string().when("rank", {
                    is: "L1",
                    then: Yup.string(),
                    otherwise: Yup.string().required(
                      "Reason of selection is required"
                    ),
                  }),
                });
                return shape;
              }, {})
            );
            try {
              await rfqClosingSchema.validate(rfqClosingOptions, {
                abortEarly: false,
              });
              //TODO: Update payload once you get the api structure
              const orders = Object.values(rfqClosingOptions);
              const poNumbers = new Set();
              const duplicatePoNumbers = checkDuplicatePoNumbers(orders);
              // for (let order of orders) {
              if (duplicatePoNumbers?.length >= 1) {
                setRfqError({
                  error: true,
                  message: `Duplicate PO Number "${duplicatePoNumbers.join(
                    ", "
                  )}" found`,
                });
                setTimeout(() => {
                  setRfqError({ error: false, message: "" });
                }, 8000);
                return;
              }
              //   poNumbers.add(order.poNumber);
              // }
              const closingDetails = Object.values(rfqClosingOptions);
              const payload = {
                rfqId: data.rfqId,
                entityId: entityId,
                supplierClosingDetails: closingDetails.map((data) => {
                  return {
                    supplierId: data.supplierId,
                    closingDetails: data.closingDetails.map((obj) => {
                      return { ...obj, remark: data?.remark ?? "" };
                    }),
                  };
                }),
              };
              const response = await dispatch(closeRfq({ payload }));
              setRfqClosingOptions({});
              setRfqClosingErrors({});
              setRfqClosingStep(1);
              setIsRfqClosing(false);
              setChangeStatus({
                currentState: "Open",
                changeStatus: "Close",
                alert: true,
              });
              setTimeout(() => {
                setChangeStatus({
                  currentState: "",
                  changeStatus: "",
                  alert: false,
                });
              }, 6000);
            } catch (error) {
              let errors = {};
              // error.inner.forEach((err) => {
              //   const [supplierId, field] = err.path.split(".");
              //   if (!errors[supplierId]) {
              //     errors[supplierId] = {};
              //   }
              //   errors[supplierId][field] = err.message;
              // });
              error.inner.forEach((err) => {
                let pathParts = err.path.split(".");
                let currentErrorObject = errors;
                errors = { ...errors };
                if (pathParts?.length === 2) {
                  errors = {
                    ...errors,
                    [pathParts[0]]: errors[pathParts[0]]
                      ? {
                          ...errors[pathParts[0]],
                          [pathParts[1]]: err?.message,
                        }
                      : { [pathParts[1]]: err?.message },
                  };
                }
                if (pathParts?.length === 3) {
                  errors = {
                    ...errors,
                    [pathParts[0]]: errors?.[pathParts[0]]
                      ? {
                          ...errors[pathParts[0]],
                          [pathParts[1]]: errors?.[pathParts[0]]?.[pathParts[1]]
                            ? {
                                ...errors[pathParts[0]][pathParts[1]],
                                [pathParts[2]]: err?.message,
                              }
                            : { [pathParts[2]]: err?.message },
                        }
                      : {
                          [pathParts[1]]: errors?.[pathParts[0]]?.[pathParts[1]]
                            ? {
                                ...errors[pathParts[0]][pathParts[1]],
                                [pathParts[2]]: err?.message,
                              }
                            : { [pathParts[2]]: err?.message },
                        },
                  };
                }
              });
              setRfqClosingErrors(errors);
            }
          }}
          onClose={() => {
            setRfqClosingStep(1);
            setRfqClosingOptions({});
            setRfqClosingErrors({});
            setIsRfqClosing(false);
          }}
          loading={loading}
        >
          {rfqClosingStep === 1 ? (
            <div className="flex flex-col">
              <span className="text-sm text-gray-900 font-medium mb-1">
                Awardee Vendor
              </span>
              <div className="w-[70%]">
                {data.suppliers.filter(
                  (s) =>
                    s?.quotationDetails?.alignedToTerms &&
                    s?.quotationDetails?.received
                ).length ? (
                  <Select
                    options={data.suppliers
                      .filter(
                        (s) =>
                          s?.quotationDetails?.alignedToTerms &&
                          s?.quotationDetails?.received
                      )
                      .map((supplier, i) => ({
                        key: supplier.supplierId || 0,
                        value: {
                          businessName: supplier.businessName,
                          isNeevayVendor: supplier.isNeevayVendor,
                          supplierId: supplier.supplierId,
                          awardedBy: name,
                          userId: userId,
                          date: new Date().toLocaleDateString("en-CA"),
                          rank: supplier?.quotationDetails?.rank,
                          closingDetails: [
                            {
                              amount: supplier?.quotationDetails?.amount,
                              poNumber: "",
                              poDate: "",
                            },
                          ],
                          remark: "",
                        },
                      }))}
                    onChange={(key, value) => {
                      setRfqClosingOptions((prevOptions) => {
                        const newOptions = { ...prevOptions };
                        if (key in newOptions) {
                          delete newOptions[key];
                        } else {
                          newOptions[key] = value;
                        }
                        return newOptions;
                      });
                    }}
                    className="mb-4"
                    isMultiselect
                    hasCheckbox
                    selectedValues={Object.values(rfqClosingOptions).map(
                      (val) => val?.businessName
                    )}
                    selectedKeys={Object.keys(rfqClosingOptions)}
                    placeholder="Select Vendors"
                  />
                ) : (
                  <div className="text-gray-500">No vendors are ranked yet</div>
                )}
              </div>
            </div>
          ) : (
            Object.keys(
              Object.fromEntries(
                Object.entries(rfqClosingOptions).sort(([, a], [, b]) =>
                  a.rank.localeCompare(b.rank)
                )
              )
            ).map((key) => (
              <div key={key} className="mb-10">
                <b className="text-base">
                  {rfqClosingOptions[key].businessName} -{" "}
                  {rfqClosingOptions[key].rank}
                </b>

                {rfqClosingOptions[key]?.closingDetails?.map((data, index) => (
                  <div className="flex flex-col p-4 m-2 gap-2 border max-w-[350px]">
                    <div className="flex justify-between">
                      <p className="font-medium text-xl">
                        Purchase Order {index + 1}
                      </p>
                      {index != 0 && (
                        <button
                          className="ml-2"
                          type="button"
                          onClick={() =>
                            setRfqClosingOptions((prevState) => ({
                              ...prevState,
                              [key]: {
                                ...rfqClosingOptions[key],
                                closingDetails: rfqClosingOptions[
                                  key
                                ]?.closingDetails?.filter(
                                  (data, i) => i != index
                                ),
                              },
                            }))
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-p79yt4"
                            viewBox="0 0 24 24"
                            focusable="false"
                            width="22px"
                            height="22px"
                            aria-hidden="true"
                          >
                            <path
                              fill="#4B5563"
                              d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                    <div className="flex flex-col">
                      <b>PO Create Date</b>
                      <input
                        type="date"
                        name="poDate"
                        onChange={(e) =>
                          handleRfqClosingOptions(
                            key,
                            e.target.name,
                            e.target.value,
                            index
                          )
                        }
                        defaultValue={
                          rfqClosingOptions[key]?.closingDetails[index]?.poDate
                        }
                        className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-primary-500"
                      />
                      {rfqClosingErrors[key]?.[`closingDetails[${index}]`]
                        ?.poDate &&
                        errorField(
                          rfqClosingErrors[key]?.[`closingDetails[${index}]`]
                            ?.poDate
                        )}
                    </div>
                    <div className="flex flex-col">
                      <b>PO Number</b>
                      <input
                        type="text"
                        name="poNumber"
                        onChange={(e) =>
                          handleRfqClosingOptions(
                            key,
                            e.target.name,
                            e.target.value,
                            index
                          )
                        }
                        defaultValue={
                          rfqClosingOptions[key]?.closingDetails[index]
                            ?.poNumber
                        }
                        className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-primary-500"
                      />
                      {rfqClosingErrors[key]?.[`closingDetails[${index}]`]
                        ?.poNumber &&
                        errorField(
                          rfqClosingErrors[key]?.[`closingDetails[${index}]`]
                            ?.poNumber
                        )}
                    </div>
                    <div className="flex flex-col">
                      <b>
                        Amount
                        <span className="text-sm italic font-normal ml-2">
                          {"(without GST)"}
                        </span>
                      </b>{" "}
                      <input
                        type="text"
                        name="amount"
                        defaultValue={
                          rfqClosingOptions[key]?.closingDetails[index]?.amount
                        }
                        className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-primary-500"
                        onChange={(e) =>
                          handleRfqClosingOptions(
                            key,
                            e.target.name,
                            e.target.value,
                            index
                          )
                        }
                      />
                      {rfqClosingErrors[key]?.[`closingDetails[${index}]`]
                        ?.amount &&
                        errorField(
                          rfqClosingErrors[key]?.[`closingDetails[${index}]`]
                            ?.amount
                        )}
                    </div>
                  </div>
                ))}

                <div className="m-2">
                  <button
                    type="button"
                    className="p-2 px-3 border bg-slate-200 hover:bg-slate-300 font-medium rounded-lg"
                    onClick={() =>
                      setRfqClosingOptions((prevState) => ({
                        ...prevState,
                        [key]: {
                          ...rfqClosingOptions[key],
                          closingDetails: [
                            ...rfqClosingOptions[key]?.closingDetails,
                            { amount: null, poNumber: "", poDate: "" },
                          ],
                        },
                      }))
                    }
                  >
                    + Add PO
                  </button>
                </div>

                {/* <div className="flex gap-x-4 mt-6 w-full"></div> */}

                {rfqClosingOptions[key].rank !== "L1" && (
                  <div className="mt-6">
                    <b>Reason to select {rfqClosingOptions[key]?.rank}</b>
                    <textarea
                      name="remark"
                      defaultValue={rfqClosingOptions[key]?.remark}
                      className="w-full mt-2 border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-primary-500"
                      onChange={(e) =>
                        handleRfqClosingOptions(
                          key,
                          e.target.name,
                          e.target.value
                        )
                      }
                    />
                    {rfqClosingErrors[key]?.remark &&
                      errorField(rfqClosingErrors[key]?.remark)}
                  </div>
                )}
              </div>
            ))
          )}
          {rfqError?.error && (
            <p className="text-red-600 text-sm">{rfqError?.message}</p>
          )}
        </Popup>
        <Popup
          heading="Close without vendor"
          open={closeWithoutVendor}
          primaryButton={{
            text: "Yes",
          }}
          secondaryButton={{
            text: "No",
          }}
          handleSubmit={() => {
            dispatch(closeWithoutSelectingVendor(data?.rfqId));
            setIsRfqClosing(false);
            setCloseWithoutVendor(false);
            setRfqClosingOptions([]);
          }}
          onClose={() => setCloseWithoutVendor(false)}
        >
          <p className="text-xl">
            Are you sure you want to close rfq without selecting vendor
          </p>
        </Popup>
        <Snackbar
          open={closeSnackbar}
          autoHideDuration={6000}
          onClose={() => setCloseSnackbar(false)}
          message={closeMessage}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
        <SelectVendorsSlider
          rfqId={rfqId}
          sliderName={sliderName}
          openSlider={openSlider}
          setOpenSlider={setOpenSlider}
          handleSliderClose={handleSliderClose}
        />
      </div>
      <WarningPopup
        openModal={rfqDeleteWaringPopup}
        handleClosePopup={closeRfqDeletePopup}
        handleSubmit={handleRfqDelete}
        warningText="Are you sure you want to delete this RFQ?"
        loading={loading}
      />
    </SidePanel>
  );
};

export default RfqsDetails;
