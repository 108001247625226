import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import favouritesReducer from "./favouritesReducer";
import ordersReducer from "./ordersReducer";
import requirementsReducer from "./requirementsReducer";
import leadsReducer from "./leadsReducer";
import searchReducer from "./searchReducer";
import businessReducer from "./businessReducer";
import membersReducer from "./membersReducer";
import notificationsReducer from "./notificationsReducer";
import orderReducer from "./orderReducer";
import crmReducer from "./crmReducer";
import tagsReducer from "./tagsReducer";
import regionsReducer from "./regionsReducer";
import repeatRfqReducer from "./repeatRfqReducer";
import searchBusinessReducer from "./searchBusinessReducer";
import companyDirectory from "./companyDirectoryReducer";
import rfqs from "./rfqsReducer";
import rfq from "./rfqReducer";
import projects from "./rfqProjectReducer";
import ccMemberListReducer from "./ccMemberList";
const rootReducer = combineReducers({
  auth: authReducer,
  favourite: favouritesReducer,
  orders: ordersReducer,
  invoiceDetails: ordersReducer,
  requirement: requirementsReducer,
  lead: leadsReducer,
  search: searchReducer,
  business: businessReducer,
  member: membersReducer,
  form: formReducer,
  notification: notificationsReducer,
  order: orderReducer,
  crm: crmReducer,
  tags: tagsReducer,
  regions: regionsReducer,
  repeatFixedRfq: repeatRfqReducer,
  searchBusiness: searchBusinessReducer,
  companyDirectory: companyDirectory,
  rfqs: rfqs,
  rfq: rfq,
  rfqProjects: projects,
  ccMemberList: ccMemberListReducer,
});

export default rootReducer;
