import React from "react";
import OverviewInfo from "./OverviewInfo";
import OverviewContacts from "./OverviewContacts";
import OverviewDetails from "./OverviewDetails";
import OverviewAwardee from "./OverviewAwardee";

const Overview = ({ data, hasAwarded, handleOpenEditAwardees }) => {
  return (
    <div className="flex gap-x-6 w-full pb-5">
      <div className="flex flex-col w-3/4 gap-y-3">
        <div className="col-span-3 row-span-2 bg-white rounded-lg shadow-md flex items-center justify-center py-4 px-12">
          <OverviewInfo data={data} />
        </div>
        <div className="col-span-3 row-span-2 bg-white rounded-lg shadow-md flex items-center justify-center p-8">
          <OverviewDetails
            description={data?.description}
            selectedFilesBase={data?.selectedFilesBase}
          />
        </div>
      </div>
      <div className="flex flex-col w-1/4 gap-y-3">
        {hasAwarded && (
          <div className="bg-white rounded-lg shadow-md flex items-center justify-center px-8 py-5 max-h-[calc(100vh-280px)] overflow-auto">
            <OverviewAwardee
              awardeeDetails={data?.suppliers.filter(
                (supplier) => supplier?.closingDetails?.length >= 1
              )}
              data={data}
              handleOpenEditAwardees={handleOpenEditAwardees}
            />
          </div>
        )}
        <div className=" bg-white rounded-lg shadow-md flex items-center justify-center px-8 py-5">
          <OverviewContacts
            newCcEmails={data?.newCcEmails}
            creator={data?.requesterName}
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
