import React, { useEffect, useState } from "react";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { TextField, Autocomplete } from "@mui/material";
import ButtonGroup from "./ButtonGroup";
import EmailTextField from "./EmailTextfield";
import { useDispatch, useSelector } from "react-redux";
import { CcMemberList } from "actions/rfqActions";
import StaticPopup from "components/StaticPopup";
import CreateNewProjectForm from "views/Project/components/CreateNewProjectForm";

const GeneralInformation = ({ projects }) => {
  const sortedProjects = projects.slice().sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0;
  });

  const { values, setFieldValue } = useFormikContext();
  const [showCreateProjectForm, setShowCreateProjectForm] = useState(false);
  const [currentValues, setCurrentValues] = useState(values);

  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const user = auth?.user;
  const entityId = user?.userEntity?.entityId;

  useEffect(() => {
    dispatch(CcMemberList(entityId));
  }, [dispatch, entityId]);

  const handleProjectCreation = () => {
    setShowCreateProjectForm(false);

    // Restore the form values after the project is created
    Object.keys(currentValues).forEach((key) => {
      setFieldValue(key, currentValues[key]);
    });
  };

  const handleOpenCreateProjectForm = () => {
    setCurrentValues(values); // Store the current form values
    setShowCreateProjectForm(true);
  };

  return (
    <div className="w-full">
      <div className="rounded-lg overflow-hidden shadow-md bg-white">
        <div className="p-6 grid grid-cols-3 gap-4">
          <h2 className="text-xl text-gray-900 font-bold mb-8 col-span-3">
            General Information
          </h2>

          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="rfqName"
            >
              RFQ Name<span className="text-red-500">*</span>
            </label>
            <Field
              type="text"
              id="rfqName"
              name="rfqName"
              className="shadow-sm block w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-sky-500 focus:ring-width-1"
              placeholder="Enter RFQ name"
            />
            <ErrorMessage
              name="rfqName"
              component="div"
              className="text-red-500 text-xs"
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="indentId"
            >
              Indent Number
            </label>
            <Field
              type="text"
              id="indentId"
              name="indentId"
              className="shadow-sm block w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-sky-500 focus:ring-width-1"
              placeholder="Enter Indent Number"
            />
            <ErrorMessage
              name="indentId"
              component="div"
              className="text-red-500 text-xs"
            />
          </div>

          <div className="mb-4 row-span-2">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="emailCopyRecipients"
            >
              CC Recipients
            </label>
            <div className="bg-gray-100 relative">
              <Field
                component={EmailTextField}
                name="emailCopyRecipients"
                id="emailCopyRecipients"
              />
            </div>
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="dueDate"
            >
              Due Date
            </label>
            <Field
              type="date"
              id="dueDate"
              name="dueDate"
              className="shadow-sm block w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-sky-500 focus:ring-width-1"
            />
            <ErrorMessage
              name="dueDate"
              component="div"
              className="text-red-500 text-xs"
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="creditPeriod"
            >
              Credit Period
            </label>
            <Field
              type="text"
              id="creditPeriod"
              name="creditPeriod"
              className="shadow-sm block w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-sky-500 focus:ring-width-1"
              placeholder="Enter Credit Period"
            />
            <ErrorMessage
              name="creditPeriod"
              component="div"
              className="text-red-500 text-xs"
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-medium text-gray-700"
              htmlFor="projectName"
            >
              Project Name<span className="text-red-500">*</span>
            </label>

            <Autocomplete
              id="projectName"
              name="projectName"
              size="small"
              options={sortedProjects}
              getOptionLabel={(project) =>
                `${project.name} - ${project.location.city} - ${project.location.state}`
              }
              value={
                sortedProjects.find(
                  (project) => project._id === values.projectName
                ) || null
              }
              onChange={(event, newValue) => {
                setFieldValue("projectName", newValue ? newValue._id : "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  className="shadow-sm block w-full px-4 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-sky-500 focus:ring-width-1"
                />
              )}
              noOptionsText={
                <span
                  style={{
                    fontSize: "14px",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenCreateProjectForm}
                >
                  Projects not found{" "}
                  <span
                    style={{
                      color: "blue",
                      textDecoration: "underline",
                      fontStyle: "italic",
                    }}
                  >
                    Click to add new project
                  </span>
                </span>
              }
              renderOption={(props, option) => (
                <li {...props}>{option.name}</li>
              )}
            />

            <ErrorMessage
              name="projectName"
              component="div"
              className="text-red-500 text-xs"
            />
          </div>

          <ButtonGroup
            name="requirementType"
            options={[
              "Labour Only",
              "Material Only",
              "Contractor with Material",
            ]}
          />
        </div>
      </div>

      {/* StaticPopup for adding a new project */}
      <StaticPopup
        openModal={showCreateProjectForm}
        handleClose={() => setShowCreateProjectForm(false)}
        header={"Add Project"}
        size={"sm"}
        width={"3xl"}
        bodyStyle={"pb-0 pt-2"}
      >
        {showCreateProjectForm && (
          <CreateNewProjectForm
            setShowCreateProjectForm={setShowCreateProjectForm}
            onProjectCreated={handleProjectCreation} // Pass the callback to restore form values
          />
        )}
      </StaticPopup>
    </div>
  );
};

export default GeneralInformation;
