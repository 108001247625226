import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuItemsList from "./MenuItemsList";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "flowbite-react";
import { signout } from "actions/authActions";
import WarningPopup from "components/WarningPopup";

const SidePanel = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const [openSlider, setOpenSlider] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [openLogoutSlider, setOpenLogoutSlider] = useState(false);
  const [openLogoutPopup, setOpenLogoutPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const currentPage = location.pathname;
  const basePath = "/" + currentPage.split("/")[1]; // "/rfqs/789" convert into /rfqs so btn click is on focus
  const menuItems = MenuItemsList;

  const user = auth?.user;
  const entityId = user?.userEntity?.entityId;
  const entityName = user?.userEntity?.entityName;
  const directoryName = user?.userEntity?.directoryName;

  function getInitials(name) {
    // Split the name into words
    const words = name?.split(" ");

    // Extract the first letter of each word
    const initials = words?.map((word) => word?.charAt(0)?.toUpperCase());

    // Join the initials together
    return initials?.join("");
  }

  const handleClickOnProfile = () => {
    setOpenLogoutSlider(!openLogoutSlider);
  };

  const handleCloseWarning = () => {
    navigate("/");
  };
  const handleChangeSliderValue = () => {
    setOpenSlider(!openSlider);
  };
  const handleCreateRegularRfq = () => {
    navigate("/rfqs/create");
    setOpenSlider(false);
  };
  const handleCreateRepeatRfq = () => {
    navigate("/repeated-orders/create");
    setOpenSlider(false);
  };
  const handleLogout = async () => {
    setOpenLogoutSlider(false);
    setLoading(true);
    try {
      await dispatch(signout());
      navigate("/");
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (entityId) {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 1028);
      };

      window.addEventListener("resize", handleResize);
      handleResize(); // Check initial screen size

      return () => window.removeEventListener("resize", handleResize);
    } else {
      navigate("/");
    }
  }, []);

  const handleClosePopup = () => {
    navigate("/");
  };
  return (
    <div className="flex h-screen bg-gray-100">
      {isSmallScreen ? (
        <div
          onClick={handleClosePopup}
          className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
        >
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">
              This page is not optimized for small screens
            </h2>
            <p className="text-sm">
              Please view this page on a larger device for the best experience.
            </p>
            <button
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              onClick={handleClosePopup}
            >
              Close
            </button>
          </div>
        </div>
      ) : (
        <>
          <aside
            id="sidebar-double"
            className="flex z-40 h-full transition-transform -translate-x-full sm:translate-x-0 relative"
            aria-label="Sidebar"
          >
            <div className="overflow-y-auto z-30 py-5  min-w-16 h-full bg-gray-800 border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700 flex flex-col justify-between">
              <div>
                {/* <Link to={"/"}> */}
                <img
                  src="https://storagereponeevaydevcdn.blob.core.windows.net/business/NEEVAY_logo_new_smallscreen.svg"
                  className="pl-2 mb-4 h-14 m-auto"
                  alt="company Logo"
                />
                {/* </Link> */}
                <ul className="space-y-2">
                  <div className="w-full flex justify-center  p-2 ">
                    <button
                      onClick={handleChangeSliderValue}
                      className="w-10 h-10 bg-blue-600 hover:bg-blue-700 flex items-center justify-center rounded-xl"
                    >
                      <span className="text-white font-medium text-2xl">+</span>
                    </button>
                  </div>
                  {/* Existing menu items... */}
                  {/* New Menu Item */}

                  {menuItems.map((item, index) => (
                    <li key={index}>
                      <Link
                        to={item.to}
                        className={`flex max-w-[84px] items-center flex-col p-2  transition duration-75  hover:text-gray-100  hover:bg-gray-600 ${
                          basePath === item.to
                            ? "text-gray-100 bg-gray-600"
                            : "text-gray-400"
                        } `}
                      >
                        {item.icon}
                        {item.to === "/company-directory" ? (
                          <p className="text-center text-xs">
                            {/* {directoryName} Directory */}
                            Internal Vendors
                          </p>
                        ) : (
                          <p className="text-center text-xs">{item.text}</p>
                        )}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              {/* New Menu Item */}

              <div
                onMouseLeave={() => setOpenLogoutSlider(false)}
                className="w-full"
              >
                <div
                  className="flex w-full relative items-center justify-center p-2 text-gray-400 mt-3 transition duration-75 dark:text-gray-600 hover:text-gray-900 dark:hover:text-white hover:bg-gray-600 dark:hover:bg-gray-700"
                  onMouseEnter={handleClickOnProfile}
                >
                  <Tooltip content="Log out">
                    <div
                      onClick={() => setOpenLogoutPopup(true)}
                      className="bg-blue-700 text-white  cursor-pointer h-10 w-10 rounded-full flex items-center justify-center"
                    >
                      <p>{getInitials(user?.name)}</p>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
            {openSlider && (
              <div className="absolute left-full top-0 w-60 p-7 bg-white h-screen shadow-lg">
                <h5 class="text-2xl font-semibold mb-3">Shortcuts</h5>
                <div className="flex flex-col">
                  <button
                    onClick={handleCreateRegularRfq}
                    className="flex justify-between py-5 border-b"
                  >
                    <p>Create Regular RFQ</p>
                    <svg
                      class="w-3 h-4"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                    >
                      <path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z"></path>
                    </svg>
                  </button>

                  <button
                    onClick={handleCreateRepeatRfq}
                    className="flex justify-between py-5 border-b"
                  >
                    <p>Create Repeat Orders</p>
                    <svg
                      class="w-3 h-4"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                    >
                      <path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            )}
          </aside>
          {entityId && <div className="flex-1 overflow-auto">{children}</div>}
          <WarningPopup
            openModal={openLogoutPopup}
            handleClosePopup={() => setOpenLogoutPopup(false)}
            handleSubmit={handleLogout}
            warningText="Are you sure you want to log out?"
            loading={loading}
          />
        </>
      )}
    </div>
  );
};

export default SidePanel;
